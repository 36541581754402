<template>
  <div class="magazines">
    <!-- Scene -->
    <div class="scene" ref="scene" :class="classes" @scroll="onScroll" @click="toggle">
      <transition name="fades">
        <div class="bg theme theme-black" v-show="theme === 'black'" />
      </transition>
      <transition name="fades">
        <div class="bg theme theme-darkgray" v-show="theme === 'darkgray'" />
      </transition>
      <transition name="fades">
        <div class="bg theme theme-gray" v-show="theme === 'gray'" />
      </transition>
      <transition name="fades">
        <div class="bg theme theme-slate" v-show="theme === 'slate'" />
      </transition>
      <div class="render" ref="render"></div>
      <div class="scroller" :style="style"></div>
    </div>

    <!-- Single magazine -->
    <magazine
      v-if="selected"
      v-bind="activeMagazine"
      :visible="isZoomed"
      @scrollY="onMagazineScroll"
      @zoomOut="zoomOut"
      @zoomIn="zoomIn"
      @panstart="onPanstart"
      @panmove="onPanmove"
    />
  </div>
</template>

<script>
import gsap from "gsap";
import { interpolateNumber } from "d3-interpolate";

import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Mesh,
  AmbientLight,
  DirectionalLight,
  Vector3,
  Box3,
} from "three";

import { mapMutations } from "vuex";

import map from "@/funcs/map";
import MagazineMesh from "./MagazineMesh";

import Magazine from "./Magazine";

import data from "@/assets/data/magazines";

export default {
  components: {
    Magazine,
  },
  props: {
    size: {
      type: Number,
      default: 4,
    },
    gap: {
      type: Number,
      default: 1,
    },
    z: {
      type: Number,
      default: 4.5,
    },
  },
  data() {
    return {
      data,
      activeCubeIndex: undefined,
      viewport: {
        vw: window.innerWidth,
        vh: window.innerHeight,
      },
      tween: {
        scroll: 0,
        zoom: 0,
        zoomScroll: 0,
      },
      selected: false,
      isZoomed: false,
      isZoomingOut: false,
      savedRotation: null,
      animation: {},
    };
  },
  methods: {
    draw() {
      this.raf = requestAnimationFrame(this.draw);

      if (this.selected) {
        const magazine = this.magazines[this.activeCubeIndex];
        const cube = magazine.cube;

        if (!this.isZoomingOut) {
          cube.rotation.y -= (Math.PI / 360) * 1;
          if (cube.rotation.y <= Math.PI * -2) {
            cube.rotation.y = 0;
            magazine.updateBackCover();
          }
        } else {
          cube.rotation.y = this.rotation(this.tween.zoom);
        }

        this.camera.position.y = this.y1(this.tween.zoom);
        this.camera.position.z = this.zoom(this.tween.zoom);

        /* Non active magazines */

        this.magazines.map((magazine, n) => {
          if (n === this.activeCubeIndex) return;
          magazine.cube.material.map((material) => {
            material.opacity = this.opacity(this.tween.zoom);
          });
        });
      } else {
        this.camera.position.y = this.y(this.tween.scroll);
      }

      this.renderer.render(this.scene, this.camera);
    },
    toggle() {
      if (this.isZoomed) {
        this.zoomOut();
      } else {
        this.zoomIn();
      }
    },
    zoomIn(duration = 0.7, index = this.scrollerCubeIndex) {
      // if (this.isZoomed) {
      //   this.zoomOut();
      //   return false;
      // }

      this.activeCubeIndex = index;
      this.selected = true;
      this.isZoomed = true;
      this.isZoomingOut = false;

      this.$router.push({
        name: "Magazines",
        params: { id: this.activeCubeIndex },
      });

      if (this.zoomAnimation) this.zoomAnimation.kill();

      this.zoomAnimation = gsap.to(this.tween, {
        zoom: 1,
        duration,
        ease: "power4.out",
        onComplete: () => {},
      });
    },
    zoomOut() {
      if (!this.savedRotation) {
        this.savedRotation = this.magazines[this.activeCubeIndex].cube.rotation.y;
      }
      this.isZoomed = false;
      this.isZoomingOut = true;

      if (this.zoomAnimation) this.zoomAnimation.kill();

      this.$router.push({
        name: "Magazines",
        params: { id: null },
      });

      this.zoomAnimation = gsap.to(this.tween, {
        y: 0,
        zoom: 0,
        duration: 0.8,
        ease: "back.out(1)",
        onComplete: () => {
          this.selected = false;
          this.isZoomingOut = false;
          this.isZoomed = false;
          this.savedRotation = null;
          this.tween.zoomScroll = 0;
        },
      });
    },
    getCameraY(index) {
      return (this.size + this.gap) * -index - this.size / 3;
    },
    addControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.screenSpacePanning = true;
      this.controls.minDistance = 1.4;
      this.controls.maxDistance = 6;
      //this.controls.target.set( 0, 0, 0 )
      this.controls.enableZoom = false;
      this.controls.enableDamping = true;
      this.controls.autoRotate = false;

      this.controls.addEventListener("change", () => {
        this.ambient.position.copy(this.camera.position);
        this.light.position.copy(this.camera.position);
      });
    },
    onResize() {
      const viewport = {
        vw: window.innerWidth,
        vh: window.innerHeight,
      };

      this.viewport = viewport;

      this.renderer.setSize(viewport.vw, viewport.vh);
      this.camera.aspect = viewport.vw / viewport.vh;
      this.camera.updateProjectionMatrix();
    },
    onScroll() {
      if (this.isZoomed) return;
      const scroll = this.$refs.scene.scrollTop / (this.scrollerHeight - this.viewport.vh);

      this.animation = gsap.to(this.tween, {
        scroll,
        duration: 0.4,
      });
    },
    onMagazineScroll(zoomScroll) {
      this.tween.zoomScroll = zoomScroll;
      // this.animation = gsap.to(this.tween, {
      //   zoomScroll,
      //   duration: 0.1,
      // });
    },
    onPanstart() {
      this.savedRotation = this.magazines[this.activeCubeIndex].cube.rotation.y;
      this.isZoomed = false;
      this.isZoomingOut = true;
    },
    onPanmove(t) {
      gsap.to(this.tween, {
        y: 1 - t,
        zoom: 1 - t,
      });
    },
  },
  computed: {
    scrollerHeight() {
      return this.viewport.vh * this.data.length;
    },
    scrollerCubeIndex() {
      return Math.round((this.data.length - 1) * this.tween.scroll);
    },
    activeMagazine() {
      return this.data[this.scrollerCubeIndex];
    },
    theme() {
      if (!this.activeMagazine) return;
      return this.activeMagazine.theme;
    },
    style() {
      return {
        height: this.scrollerHeight + "px",
      };
    },
    classes() {
      return {
        zoomed: this.isZoomed,
      };
    },
    y() {
      return interpolateNumber(0, this.getCameraY(this.data.length - 1));
    },
    y1() {
      const y0 = this.y(this.tween.scroll);
      const y1 = this.getCameraY(this.activeCubeIndex) + this.size * 0.33;
      const y2 = this.getCameraY(this.activeCubeIndex) + this.size * -1.15;

      const i = interpolateNumber(y1, y2);
      const y3 = i(this.tween.zoomScroll);

      return interpolateNumber(y0, y3);
    },
    zoom() {
      return interpolateNumber(this.z, 3);
    },
    rotation() {
      const r1 = this.savedRotation < -Math.PI ? Math.PI * -2 : 0;
      return interpolateNumber(r1, this.savedRotation);
    },
    opacity() {
      return interpolateNumber(1, 0);
    },
  },
  watch: {
    "$route.params.id": {
      handler(value) {
        if (this.$route.name !== "StoreMagazines") return;

        if (value === "") {
          if (this.selected) this.zoomOut();
        } else {
          if (!this.selected) this.zoomIn();
        }
      },
    },
  },
  created() {
    this.magazines = [];
    window.addEventListener("resize", this.onResize, false);
  },
  mounted() {
    /*
    Scene
    */
    this.scene = new Scene();

    /*
    Camera
    */
    this.camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);

    this.camera.position.z = this.z;

    /*
    Renderer
    */
    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      // powerPreference: "high-performance",
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.$refs.render.appendChild(this.renderer.domElement);

    /*
    Lights
    */
    this.ambient = new AmbientLight(0x222222); // soft white ambient
    this.light = new DirectionalLight(0xffffff);
    this.light.position.set(0, 0, 5);

    this.scene.add(this.ambient);
    this.scene.add(this.light);

    /*
    Magazine
    */

    this.data.forEach((mag, n) => {
      const getUrl = (name, ext = "png") =>
        require(`@/assets/img/magazine/${mag.slug}/${name}.${ext}`);

      const urls = ["spine", "spine", "top", "bottom", "front", "back"].map((side) => {
        const ext = side === "front" && mag.video ? "mp4" : "png";
        return getUrl(side, ext);
      });

      let backCovers;

      if (mag.content) {
        backCovers = mag.content
          .map((c) => c.artworks)
          .flat()
          .filter((c) => typeof c !== "undefined")
          .sort(() => 0.5 - Math.random());
      } else {
        backCovers = ["back", "back_1", "back_2", "back_3"].map((side) => getUrl(side));
      }

      const magazine = new MagazineMesh(urls, this.size, backCovers);

      magazine.cube.position.y = this.getCameraY(n);

      this.magazines.push(magazine);
      this.scene.add(magazine.cube);
    });

    /*
    Draw
    */
    this.draw();

    /* 
    Check if on single magazine 
    */

    const params = this.$route.params;
    if (params && params.id !== "") {
      // Navigate to magazine
      this.$nextTick(() => {
        const index = Math.round(params.id);

        this.$refs.scene.scrollTo(0, index * this.viewport.vh);
        this.tween.scroll =
          this.$refs.scene.scrollTop / (this.scrollerHeight - this.viewport.vh);

        this.$nextTick(() => {
          this.zoomIn(0, index);
        });
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize, false);
    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }
    this.renderer.forceContextLoss();
    this.renderer.context = null;
    this.renderer.domElement = null;
    this.renderer = null;
  },
};
</script>

<style lang="scss" scoped>
.magazines {
  .scene {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    position: absolute;
    top: 0;
    background-color: var(--color-bg-primary);

    &.zoomed {
      overflow: hidden;
      pointer-events: none;
    }

    .render,
    .bg {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
}
</style>
