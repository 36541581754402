<template>
  <div class="thumb-label" :style="style" :class="{ symbol: symbol }">
    <template v-if="!symbol">
      {{ value }}
    </template>
    <template v-else>
      <sf-symbol :ligatures="['large']" :display="value" />
    </template>
  </div>
</template>

<script>
import gsap from "gsap";

import map from "./utils/map";

import SfSymbol from "@/components/DS/SfSymbol";

export default {
  components: {
    SfSymbol,
  },
  props: {
    t: Number,
    value: [Number, String, Array],
    visible: Boolean,
    symbol: Boolean,
  },
  data() {
    return {
      gutter: undefined,
      width: 0,
      parent: {
        width: 0,
      },
      tween: {
        x: null,
      },
    };
  },
  methods: {
    updateBounds(initial) {
      const bounds = this.$el.getBoundingClientRect();
      if (initial) {
        this.gutter = bounds.left * 2;
        this.parent = this.$el.parentElement.getBoundingClientRect();
      }
      this.width = bounds.width;
    },
  },
  computed: {
    max() {
      return this.parent.width - this.width;
    },
    x() {
      return map(this.t, 0, 1, 0, this.max);
    },
    style() {
      if (typeof this.gutter === "undefined") return false;
      return {
        transform: `translateX(${this.tween.x}px)`,
      };
    },
  },
  watch: {
    value() {
      this.$nextTick(this.updateBounds);
    },
    x() {
      this.animation = gsap.to(this.tween, 1, {
        x: this.x,
        ease: "back.out(1.7)",
      });
    },
  },
  created() {},
  mounted() {
    this.updateBounds(true);

    this.animation = gsap.set(this.tween, {
      x: this.x,
    });
  },
};
</script>

<style lang="scss" scoped>
.thumb-label {
  $height: 40rem;
  $padding: 16rem;
  border-radius: $height;
  height: $height;
  padding-left: $padding;
  padding-right: $padding;
  color: var(--color-fg-inverse);
  background-color: var(--color-fill-primary);
  display: grid;
  place-items: center;
  position: absolute;
  left: 0;
  top: 0rem - $height - 12rem;

  &.symbol {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: var(--color-fg-primary);
  }
}
</style>
