<template>
  <div class="filter-strip">
    <div class="input">
      <input
        data-cursor="circle"
        type="range"
        :min="min"
        :max="max"
        step="0.001"
        :value="columns"
        @input="$emit('update:columns', $event.target.value)"
      />
    </div>
    <span class="filter">Search</span>
    <span class="sort">Recent<icon name="Arrow Left" /></span>

    <main class="filters">
      <btn
        :type="n === sortIndex ? 'label' : 'secondary'"
        v-for="(sort, n) in sorts"
        :key="n"
        @click="setSort(sort, n)"
        >{{ sort.label }}</btn
      >
      <slot />
      <!--       <ul class="colors">
        <li
          v-for="(color, n) in colors"
          :key="color"
          :style="{
            backgroundColor: color,
            border: n === 0 ? '1px solid #000' : n,
            //opacity: n === 0 ? 1 : 0.3,
          }"
        />
      </ul> -->
    </main>
    <div class="filters view">
      <btn type="primary" @click="$emit('view', 'view')">View</btn>
      <btn type="secondary" @click="$emit('view', 'index')">Index</btn>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn";
import Icon from "@/components/Icon";
export default {
  components: {
    Btn,
    Icon,
  },
  props: {
    columns: {
      type: [Number, String],
      default: 8,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    setSort(sort, n) {
      this.sortIndex = n;

      this.$emit("sort", sort.group);
    },
  },
  data() {
    const colors = [];

    for (let i = 15; i >= 1; i = i - 4) {
      colors.push(`var(--base-color-${i})`);
    }

    return {
      colors,
      sortIndex: 0,
      sorts: [
        { label: "All" },
        { label: "Artists", group: "artistId" },
        { label: "Magazines" },
        { label: "Exhibitions" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-strip {
  height: var(--height-bar);
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--color-bg-primary);
  padding: 0 var(--spacing-gutter);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "left main main main sub sub sub sub . . right right";
  align-items: center;

  .input {
    //grid-column: 1 / span 1;
    grid-area: sub;
    display: flex;
    justify-content: center;
  }

  .filters {
    grid-area: main;
    display: flex;
    column-gap: 24rem;
  }

  .sort {
    grid-area: left;
    display: flex;
    column-gap: 4rem;

    .icon {
      transform: rotate(90deg);
    }
  }

  .filter {
    display: flex;
    column-gap: 8rem;
    grid-area: right;
    color: var(--color-fg-secondary);
  }

  .view {
    grid-column: 8 / span 4;
    display: None;
  }

  .colors {
    display: flex;

    li {
      height: 32rem;
      width: 32rem;
    }
  }
}
</style>
