const exports = new Map();

exports.set("LIGHTNESS", { id: 3, title: "Lightness" });
exports.set("HUE", { id: 4, title: "Hue" });
exports.set("HUE_LIGHTNESS", { id: 2, title: "Hue & Lightness" });
exports.set("HSL_WEIGHTED", { id: 1, title: "Weighted HSL" });
exports.set("RGB", { id: 0, title: "RGB" });
exports.set("LUMA", { id: 5, title: "Luma" });

export default exports;
