<template>
  <div class="carousel theme" :class="{ [`theme-${theme}`]: theme }">
    <!-- Scroller -->
    <div class="scroller" ref="scroller" @scroll="onScroll">
      <div class="slide" v-for="(slide, n) in slides" :key="n">
        <frame>
          <img v-if="slide.img" class="fill absolute" :src="slide.img" />
          <exhibition-poster v-if="slide.type === 'exhibition'" />
        </frame>
      </div>
    </div>

    <!-- Footer -->
    <footer class="grid">
      <label
        ><span class="capitilize">{{ slide.artist }}</span> &nbsp;{{
          slide.name
        }}</label
      >
      <div class="indicator">
        <div
          class="bar"
          v-for="(slide, n) in slides"
          :class="{ active: activeIndex === n }"
          :key="n"
        ></div>
      </div>
    </footer>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import ExhibitionPoster from "@/components/ExhibitionPoster";

export default {
  components: {
    Frame,
    ExhibitionPoster,
  },
  data() {
    return {
      scrollX: 0,
      activeIndex: 0,
      slides: [
        {
          artist: "Dmitri Cherniak",
          name: "Untitled",
          theme: "black",
          img: require("@/assets/img/store/hero-featured-02.png"),
        },
        {
          artist: "Lucie Rox",
          name: "Untitled",
          theme: "black",
          img: require("@/assets/img/store/hero-featured-01.png"),
        },
        // {
        //   type: "exhibition",
        // },
        {
          artist: "Bruce Gilden",
          name: "India",
          theme: "slate",
          img: require("@/assets/img/demo/img-28-large.jpg"),
        },

        {
          artist: "Ryan Carl",
          img: require("@/assets/img/demo/img-15-large.jpg"),
          name: "Block",
          theme: "slate",
        },
      ],
    };
  },
  methods: {
    onScroll() {
      const el = this.$refs.scroller;
      const coount = this.slides.length;
      const totalLength = el.offsetWidth * coount;
      this.scrollX = el.scrollLeft;
      this.activeIndex = Math.round((this.scrollX / totalLength) * coount);
    },
  },
  computed: {
    slide() {
      return this.slides[this.activeIndex];
    },
    theme() {
      return this.slide.theme;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  //min-height: 100vh;
  //padding-top: 44rem;

  .scroller {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slide {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    min-width: 100vw;
    flex: 0 0 100vw;
  }

  footer {
    width: 100%;
    padding: 0 var(--spacing-gutter);
    height: 44rem;
    bottom: 0;
    position: sticky;
    align-items: center;
    grid-template-areas: "left left left main main main main main main right right right";
    /*     background: var(--color-bg-primary); */

    label {
      grid-area: left;
    }
    .indicator {
      height: 16rem;
      display: flex;
      column-gap: 2rem;
      grid-area: main;

      .bar {
        width: 2rem;
        height: inherit;
        background-color: var(--color-fill-secondary);

        &.active {
          background-color: var(--color-fill-primary);
        }
      }
    }
  }
}
</style>
