<template>
  <!-- Router -->
  <router-view v-slot="{ Component }">
    <keep-alive include="Library,Store,Tool">
      <component :is="Component" />
    </keep-alive>
  </router-view>

  <!-- Cursor -->
  <cursor v-if="!isNative" />
  <global-settings v-if="showGlobalSettings" />
</template>

<script>
import shaders from "!!raw-loader!@/components/DitherStudio/shaders.html";
document.body.insertAdjacentHTML("beforeend", shaders);

import { mapGetters, mapActions, mapMutations } from "vuex";
import { collection, onSnapshot, getDocs } from "firebase/firestore";
import db from "@/db";

import Cursor from "@/components/Cursor";
import GlobalSettings from "@/components/GlobalSettings";

export default {
  components: {
    Cursor,
    GlobalSettings,
  },
  computed: {
    ...mapGetters(["showGlobalSettings", "uiScaleFactor", "globalTheme", "localTheme"]),
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
    }),
    isNative() {
      return this.$route.meta.native;
    },
  },
  methods: {
    ...mapActions(["getDeviceData"]),
    ...mapMutations([
      "addFile",
      "modifyFile",
      "removeFile",
      "setGlobalSettingsVisibility",
      "setUserPreferedTheme",
    ]),
    ...mapMutations({
      setArtworkUrl: "artworks/setArtworkUrl",
    }),
    setArtwork(doc) {
      const data = doc.data();

      if (data.url) {
        //console.log(doc.id, " => ", data.url);
        this.setArtworkUrl({ id: doc.id, url: data.url });
      }
    },
    onKeyDown(e) {
      if (e.key === "k") {
        this.setGlobalSettingsVisibility(!this.showGlobalSettings);
      }
    },
    onColorSchemeChange(e) {
      const theme = e.matches ? "black" : "slate";
      this.setUserPreferedTheme(theme);
    },
    onUIScaleChange() {
      document.documentElement.style.fontSize = `calc(1px * ${this.uiScaleFactor})`;
    },
    updateThemeOnBody() {
      const theme = this.globalTheme;
      const exceptions = ["Tool", "Artwork", "ToolEdit"];

      document.body.className = "";
      document.body.classList.add("theme", `theme-${theme}`);

      if (theme === "slate" && exceptions.includes(this.$route.name)) {
        document.body.classList.add("reverse");
      }

      // compute bg color
      const color = window
        .getComputedStyle(document.body, null)
        .getPropertyValue("background-color");

      document.querySelector('meta[name="theme-color"]').setAttribute("content", color);
    },
  },
  async created() {
    window.addEventListener("keydown", this.onKeyDown, false);
    document.documentElement.style.cursor = "none";

    await this.getDeviceData();

    // Get all artworks
    const docs = await getDocs(collection(db, "artworks"));
    docs.forEach((doc) => this.setArtwork(doc));

    // Subscribe to future changes
    this.unsubscribe = onSnapshot(collection(db, "artworks"), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          this.setArtwork(change.doc);
        }
      });
    });

    // Subscribe to files
    this.unsubscribeFiles = onSnapshot(collection(db, "files"), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const payload = { id: change.doc.id, payload: change.doc.data() };

        if (change.type === "added") {
          this.addFile(payload);
          //console.log("Added:", payload.id);
        }
        if (change.type === "modified") {
          this.modifyFile(payload);
          //console.log("Modified: ", payload.id);
        }
        if (change.type === "removed") {
          this.removeFile(payload.id);
        }
      });
    });

    // theme stuff
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.onColorSchemeChange);

    this.updateThemeOnBody();
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyDown, false);
    window.removeEventListener("change", this.onColorSchemeChange);
  },
  watch: {
    uiScaleFactor: "onUIScaleChange",
    globalTheme: "updateThemeOnBody",
    localTheme: "updateThemeOnBody",
  },
};
</script>

<style src="./css/reset.css" />
<style src="./css/variables.css" />
<style src="./css/typography.css" />
<style src="./css/themes.css" />
<style src="./css/grid.css" />
<style src="./css/inputs.css" />
<style src="./css/helpers.css" />
<style src="./css/transitions.css" />

<style lang="scss">
* {
  box-sizing: border-box;
  user-select: none;
  cursor: url(./assets/img/cursor.png), auto !important;
}

body {
  overscroll-behavior-x: none;
  cursor: url(./assets/img/cursor.png), auto;
  background-color: var(--color-bg-primary);
  //background-color: black;

  &::-webkit-scrollbar {
    display: none;
  }
}
#app {
  min-height: 100vh;
}

.in-isolation #app {
  * {
    opacity: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.tabs {
  display: flex;
  gap: 24rem;
  height: 100%;
  align-items: center;

  a {
    > * {
      pointer-events: none;
    }
  }
}
</style>
