<template>
  <div class="glass">
    <div class="canvas">
      <Kulachek ref="sketch" :width="width" :height="height" />
    </div>
  </div>
</template>

<script>
import glass from "./source";

import Kulachek from "@/assets/exhibitions/kulachek/Kulachek";

export default {
  components: {
    Kulachek,
  },
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  mounted() {
    this.glass = glass(this.$el, {
      width: this.width,
      height: this.height,
      texture: this.$refs.sketch.sketch.canvas,
    });
  },
  beforeUnmount() {
    this.glass.unload();
  },
};
</script>

<style lang="scss" scoped>
.glass {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  cursor: none;

  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    pointer-events: none;
  }
}
</style>
