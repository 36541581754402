<template>
  <iframe class="render-code-remote" :src="url" />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "dG3h6-7OPPKC",
    },
  },
  methods: {
    onMessage(e) {
      const data = e.data;
      if (!data) return;

      if (data.render !== "monolit") return false;
      this.$emit(e.data.event, e.data.payload);
    },
  },
  computed: {
    url() {
      return `https://monol.it/#/render/${this.id}`;
      //return `http://localhost:8080/#/render/${this.id}`;
    },
  },
  mounted() {
    window.addEventListener("message", this.onMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.onMessage);
  },
};
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
}
</style>
