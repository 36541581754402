<template>
  <div class="exhibitions">
    <exhibition-poster
      v-for="(exhibition, n) in exhibitions"
      :key="n"
      v-bind="exhibition"
      @click="$router.push({ name: 'Exhibition', params: { id: exhibition.id } })"
    />
  </div>
</template>

<script>
import ExhibitionPoster from "@/components/ExhibitionPoster";
import exhibitions from "@/assets/exhibitions";

export default {
  components: {
    ExhibitionPoster,
  },
  data() {
    return {
      exhibitions,
    };
  },
};
</script>

<style lang="scss" scoped>
.exhibitions {
  width: 100vw;
  height: 100vh;
  row-gap: var(--spacing-gutter);
  padding: var(--spacing-gutter);
  padding-top: 44rem;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  //column-gap: 200rem;
  /*   scroll-snap-type: x; */
  transform: translateZ(0px);

  /*   &::-webkit-scrollbar {
    display: none;
  } */

  &::-webkit-scrollbar {
    height: 8rem;
  }

  &::-webkit-scrollbar-track {
    background: rgba(white, 0.3);
    margin-left: 45vw;
    margin-right: 45vw;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-bg-primary);
  }

  > * {
    scroll-snap-align: center;
    position: relative;
  }
}
</style>
