export default {
  namespaced: true,
  state: {
    inIsolation: false,
    isScrolling: false,
    isolationMode: "blur",
    disabled: false,
    dragThreshold: 10,
    events: [],
  },
  mutations: {
    setIsolationMode(state, payload) {
      state.isolationMode = payload;
    },
    setScrollingState(state, bool) {
      state.isScrolling = bool;
    },
    setIsolation(state, { isolate, events }) {
      state.inIsolation = isolate;

      const opacity = isolate ? 0 : 1;
      document.documentElement.style.setProperty(
        "--opacity-in-isolation",
        opacity
      );

      if (events) {
        state.events = isolate ? events : [];
      }
    },
    setDisabled(state, payload) {
      state.disabled = payload;
    },
  },
  getters: {
    inIsolation: (state) => state.inIsolation,
    isScrolling: (state) => state.isScrolling,
    disabled: (state) => state.isScrolling || state.disabled,
    isolationMode: (state) => state.isolationMode,
    dragThreshold: (state) => state.dragThreshold,
    events: (state) => state.events,
    hasEvent: (state, getters) => (name) => getters.events.includes(name),
  },
};
