<template>
  <div class="btn" :class="classes" data-cursor="circle">
    <div ref="inner" class="inner">
      <slot />
      <!--       <icon v-if="icon" :name="icon" /> -->
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    disabled: Boolean,
    icon: String,
  },
  computed: {
    classes() {
      return {
        [this.type]: this.type,
        disabled: this.disabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  height: 32rem;
  /*   border-radius: 32rem;
  padding-left: 10rem;
  padding-right: 10rem; */
  opacity: var(--opacity-in-isolation);
  border: 1px solid transparent;

  .inner {
    display: inline-flex;
    align-items: center;
    cursor: default;
    column-gap: 4rem;
    height: inherit;
    position: relative;
    top: -2px;
    pointer-events: none;
  }

  &:not(.disabled):hover {
    opacity: 0.5;
  }

  &.primary {
    /*     font-weight: bold; */
    /*     background-color: var(--color-bg-inverse);
    color: var(--color-fg-inverse);

    &.disabled {
      color: var(--color-fg-secondary);
      background-color: var(--color-fill-tertiary);
    } */
  }

  &.danger {
    background: #ff4115;
    color: var(--base-color-canvas);
    /*     border: 1px solid;
    color: #ff4115; */
  }

  &.secondary {
    color: var(--color-fg-secondary);
    /*     border: 1px solid;

    &.disabled {
      border-color: var(--color-fill-secondary);
    } */
  }

  &.underline {
    text-decoration: underline;
    text-decoration-skip: edges;
  }

  &.circle {
    width: 16rem;
    height: 16rem;
    text-indent: -999px;
    overflow: hidden;
    background-color: var(--color-bg-inverse);
  }

  &.disabled {
    pointer-events: none;
  }
}
</style>
