<template></template>
<script>
import Timer from "@/components/DitherStudio/shared/timer"; //symbol resolved in webpack config
import Constants from "../../generated_output/app/constants.js";
import AlgorithmModel from "../../generated_output/app/algorithm-model.js";
import ColorQuantizationModes from "../../generated_output/app/color-quantization-modes.js";
import Palettes from "../color-palettes.js";
import UserSettings from "../user-settings.js";
import ColorDitherModes from "../../shared/color-dither-modes.js";
import Canvas from "../canvas.js";

import WorkerHeaders from "../../shared/worker-headers.js";
import ColorPicker from "../color-picker.js";
import WorkerUtil from "../worker-util.js";

import ColorPickerComponent from "./color-picker.vue";
import ColorInput from "./color-input.vue";

import Knobs from "@/components/Knobs/Knobs";
import KnobsGroup from "@/components/Knobs/KnobsGroup";
import KnobHeader from "@/components/Knobs/KnobHeader";
import Slider from "@/components/Knobs/Slider";

import getKnobsOptions from "../../shared/get-knobs-options.js";

//caching for optimize palette
let optimizedPalettes;

function optimizePaletteMemorizationKey(numColors, modeId) {
  return `${numColors}-${modeId}`;
}

export default {
  name: "color-dither-section",
  props: {
    isWebglEnabled: {
      type: Boolean,
      required: true,
    },
    isLivePreviewEnabled: {
      type: Boolean,
      required: true,
    },
    isColorPickerLivePreviewEnabled: {
      type: Boolean,
      required: true,
    },
    requestCanvases: {
      type: Function,
      required: true,
    },
    requestDisplayTransformedImage: {
      type: Function,
      required: true,
    },
    ditherAlgorithms: {
      type: Array,
      required: true,
    },
    ditherAlgorithm: {
      type: Number,
      default: 77,
    },
    paletteId: {
      type: Number,
      default: 0,
    },
    colorDitherMode: {
      type: Number,
      default: 0,
    },
  },
  components: {
    "color-picker": ColorPickerComponent,
    ColorInput,
    Knobs,
    KnobsGroup,
    KnobHeader,
    Slider,
  },
  created() {
    //select first non-custom palette
    //needs to be done here to initialize palettes correctly
    // this.selectedPaletteIndex = 1;
    this.numColors = this.numColorsMax;
    // const defaultPalettes = Palettes.get(this.numColorsMax);

    this.palettes = Palettes.get(this.numColorsMax);
  },
  data() {
    return {
      ditherGroups: AlgorithmModel.colorDitherGroups,
      loadedImage: null,
      colors: [],
      //colors shadow and draggedIndex are for dragging colors in palette
      colorsShadow: [],
      draggedIndex: null,
      palettes: [],
      selectedPaletteIndex: null,
      numColors: null,
      numColorsMin: 2,
      numColorsMax: Constants.colorDitherMaxColors,
      colorQuantizationModes: ColorQuantizationModes.modes,
      colorQuantizationGroups: ColorQuantizationModes.groups,
      selectedColorQuantizationModeIndex: 0,
      pendingColorQuantizations: {},
      //for color picker
      shouldShowColorPicker: false,
      colorPickerColorIndex: 0,
      hasColorPickerChangedTheColor: false,
      selectedPaletteIndexBeforeColorPickerOpened: 0,
    };
  },
  computed: {
    colorPickerSelectedColor() {
      return this.colorsShadow[this.colorPickerColorIndex];
    },
    selectedDitherAlgorithm() {
      return this.ditherAlgorithms.find((a) => a.id === this.ditherAlgorithm);
    },
    isSelectedAlgorithmWebGl() {
      return this.isWebglEnabled && this.selectedDitherAlgorithm.webGlFunc;
    },
    isImageLoaded() {
      return this.loadedImage != null;
    },
    selectedColors() {
      return this.colors.slice(0, this.numColors);
    },
    selectedColorsVec() {
      return ColorPicker.colorsToVecArray(
        this.selectedColors,
        this.numColorsMax
      );
    },
    isSelectedColorQuantizationPending() {
      if (!this.isImageLoaded) {
        return false;
      }
      const key = optimizePaletteMemorizationKey(
        this.numColors,
        this.selectedColorQuantizationModeIndex
      );
      return this.isOptimizePaletteKeyPending(key);
    },
    selectedColorQuantizationPendingMessage() {
      if (!this.isImageLoaded) {
        return "";
      }
      const key = optimizePaletteMemorizationKey(
        this.numColors,
        this.selectedColorQuantizationModeIndex
      );
      if (!this.isOptimizePaletteKeyPending(key)) {
        return "";
      }
      const percentage = this.pendingColorQuantizations[key];
      const messageBase = "Working…";
      if (percentage <= 1) {
        return messageBase;
      }
      return `${messageBase} ${percentage}%`;
    },
    currentPalette() {
      return this.palettes[this.paletteId];
    },
    knobsDitherModeOptions() {
      return Array.from(ColorDitherModes, ([name, payload]) => ({
        label: payload.title,
        value: payload.id,
      }));
    },
  },
  watch: {
    isLivePreviewEnabled(newValue) {
      if (newValue) {
        this.ditherImageWithSelectedAlgorithm();
      }
    },
    ditherAlgorithm(newIndex) {
      if (this.isLivePreviewEnabled) {
        this.ditherImageWithSelectedAlgorithm();
      }
    },
    selectedColorQuantizationModeIndex() {
      if (this.isLivePreviewEnabled) {
        this.optimizePalette();
      }
    },
    numColors(newValue, oldValue) {
      let value = newValue;
      if (value < this.numColorsMin) {
        value = this.numColorsMin;
      } else if (value > this.numColorsMax) {
        value = this.numColorsMax;
      }
      if (value !== this.numColors) {
        this.numColors = value;
      }
      if (value === oldValue) {
        return;
      }
      if (this.isLivePreviewEnabled) {
        this.ditherImageWithSelectedAlgorithm();
      }
    },
    colorsShadow: {
      deep: true,
      handler(newValue) {
        if (this.draggedIndex === null) {
          this.colors = this.colorsShadow.slice();
        }
      },
    },
    colors(newValue, oldValue) {
      //don't dither image if colors changed are not enabled
      if (
        this.isLivePreviewEnabled &&
        !ColorPicker.areColorArraysIdentical(
          newValue.slice(0, this.numColors),
          oldValue.slice(0, this.numColors)
        )
      ) {
        this.ditherImageWithSelectedAlgorithm();
      }
      //set palette to custom if a color is changed
      if (
        !this.currentPalette.isCustom &&
        !ColorPicker.areColorArraysIdentical(
          this.colors,
          this.currentPalette.colors
        )
      ) {
        this.selectedPaletteIndex = 0;
      }
    },
    currentPalette(newValue) {
      if (!this.currentPalette.isCustom) {
        this.colorsShadow = this.currentPalette.colors.slice();
      }
    },
    colorDitherMode(newValue) {
      if (this.isLivePreviewEnabled) {
        this.ditherImageWithSelectedAlgorithm();
      }
    },
  },
  methods: {
    //isNewImage is used to determine if the image is actually different,
    //or it is the same image with filters changed
    imageLoaded(loadedImage, isNewImage = false) {
      this.loadedImage = loadedImage;

      //reset optimize palette cache
      //have to do this even if not a new image, since potential permutations
      //of image filters is too much to cache each possible value
      optimizedPalettes = {};
      this.pendingColorQuantizations = {};
      if (this.isLivePreviewEnabled) {
        this.ditherImageWithSelectedAlgorithm();
      } else {
        //if live preview is not enabled, transform canvas will be blank unless we do this
        this.requestDisplayTransformedImage();
      }
    },
    ditherImageWithSelectedAlgorithm() {
      if (!this.isImageLoaded) {
        return;
      }
      if (this.isSelectedAlgorithmWebGl) {
        this.requestCanvases(
          (transformCanvas, transformCanvasWebGl, sourceWebglTexture) => {
            Timer.megapixelsPerSecond(
              this.selectedDitherAlgorithm.title + " webgl",
              this.loadedImage.width * this.loadedImage.height,
              () => {
                this.selectedDitherAlgorithm.webGlFunc(
                  transformCanvasWebGl.gl,
                  sourceWebglTexture,
                  this.loadedImage.width,
                  this.loadedImage.height,
                  this.colorDitherMode,
                  this.selectedColorsVec,
                  this.numColors
                );
              }
            );
            //have to copy to 2d context, since chrome will clear webgl context after switching tabs
            //https://stackoverflow.com/questions/44769093/how-do-i-prevent-chrome-from-disposing-of-my-webgl-drawing-context-after-swit
            transformCanvas.context.drawImage(
              transformCanvasWebGl.canvas,
              0,
              0
            );
            this.requestDisplayTransformedImage();
          }
        );
        return;
      }

      this.$emit("request-worker", (worker) => {
        worker.postMessage(
          WorkerUtil.ditherWorkerColorHeader(
            this.loadedImage.width,
            this.loadedImage.height,
            this.selectedDitherAlgorithm.id,
            this.colorDitherMode,
            this.selectedColors
          )
        );
      });
    },
    ditherWorkerMessageReceivedDispatcher(messageTypeId, messageBody) {
      switch (messageTypeId) {
        case WorkerHeaders.DITHER_COLOR:
          this.ditherWorkerMessageReceived(messageBody);
          break;
        case WorkerHeaders.OPTIMIZE_PALETTE:
          const colors = messageBody.subarray(1, messageBody.length);
          const optimizePaletteKey = optimizePaletteMemorizationKey(
            colors.length / 3,
            messageBody[0]
          );
          this.optimizePaletteMessageReceived(
            colors,
            optimizePaletteKey,
            !this.colorQuantizationModes[messageBody[0]].disableCache
          );
          break;
        case WorkerHeaders.OPTIMIZE_PALETTE_PROGRESS:
          const key = optimizePaletteMemorizationKey(
            messageBody[1],
            messageBody[0]
          );
          //check to make sure still pending and not done first, to avoid race condition
          if (this.isOptimizePaletteKeyPending(key)) {
            this.pendingColorQuantizations[key] = messageBody[2];
          }
          break;
        default:
          break;
      }
    },
    optimizePaletteMessageReceived(colors, key, shouldCache) {
      //avoids race condition where image is changed before color quantization returns
      if (!this.isOptimizePaletteKeyPending(key)) {
        return;
      }
      const colorsHexArray = ColorPicker.pixelsToHexArray(colors);
      if (shouldCache) {
        optimizedPalettes[key] = colorsHexArray;
      }
      this.pendingColorQuantizations[key] = false;
      //avoids race conditions when color quantization mode or number of colors is changed before results return
      const currentKey = optimizePaletteMemorizationKey(
        this.numColors,
        this.selectedColorQuantizationModeIndex
      );
      if (key === currentKey) {
        this.changePaletteToOptimizePaletteResult(colorsHexArray.slice());
      }
    },
    changePaletteToOptimizePaletteResult(colorsHexArrayCopy) {
      //this is so if optimize palette result has less colors than max, we keep the colors that are already in the palette
      //at the end of the palette
      this.colorsShadow = colorsHexArrayCopy.concat(
        this.colorsShadow.slice(colorsHexArrayCopy.length, this.numColorsMax)
      );
    },
    ditherWorkerMessageReceived(pixels) {
      this.requestCanvases((transformCanvas) => {
        Canvas.loadPixels(
          transformCanvas,
          this.loadedImage.width,
          this.loadedImage.height,
          pixels
        );
        this.requestDisplayTransformedImage();
      });
    },
    optimizePalette() {
      const key = optimizePaletteMemorizationKey(
        this.numColors,
        this.selectedColorQuantizationModeIndex
      );
      if (this.isOptimizePaletteKeyPending(key)) {
        return;
      }
      if (optimizedPalettes[key]) {
        this.changePaletteToOptimizePaletteResult(
          optimizedPalettes[key].slice()
        );
        return;
      }
      this.pendingColorQuantizations[key] = 0;
      this.$emit("request-worker", (worker) => {
        worker.postMessage(
          WorkerUtil.optimizePaletteHeader(
            this.numColors,
            this.selectedColorQuantizationModeIndex
          )
        );
      });
    },
    isOptimizePaletteKeyPending(key) {
      return typeof this.pendingColorQuantizations[key] === "number";
    },
  },
};
</script>
