<template>
  <div class="cursor" :style="style">
    <svg width="100%" height="100%" viewBox="0 0 24 24" ref="svg">
      <path id="path" ref="path" :d="paths.square" />
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
import MorphSVGPlugin from "@/vendor/MorphSVGPluginTrial";

gsap.registerPlugin(MorphSVGPlugin);

export default {
  data() {
    return {
      cursor: null,
      showCursor: false,
      tween: {
        t: 0,
        x: 0,
        y: 0,
      },
      from: null,
      to: null,
      target: null,
      paths: {
        square: "M4 4H20V20H4V4Z",
        circle:
          "M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z",
        triangleLeft: "M15.0001 4L7 12.0001L15.0001 20.0002V4Z",
        triangleRight: "M8.99998 4L17.0001 12.0001L8.99998 20.0002V4Z",
        plus: "M9 7V0H7V7H0V9H7V15.9999H9V9H16V7H9Z",
        minus: "M19 13H5V11H19V13Z",
        heart:
          "M12 20.6405C12.1903 20.6405 12.4622 20.5136 12.6616 20.3958C17.7644 17.1329 21 13.3353 21 9.47432C21 6.26586 18.7976 4 15.9517 4C14.1843 4 12.8248 4.97885 12 6.47432C11.1934 4.98792 9.81571 4 8.04834 4C5.20242 4 3 6.26586 3 9.47432C3 13.3353 6.23565 17.1329 11.3474 20.3958C11.5378 20.5136 11.8097 20.6405 12 20.6405Z",
        smile:
          "M11.9666 22.8618C17.9842 22.8618 22.9666 17.8687 22.9666 11.8618C22.9666 5.84417 17.9734 0.861816 11.9558 0.861816C5.94891 0.861816 0.966553 5.84417 0.966553 11.8618C0.966553 17.8687 5.95969 22.8618 11.9666 22.8618ZM8.66655 10.8481C7.96557 10.8481 7.36165 10.2118 7.36165 9.3275C7.36165 8.42162 7.96557 7.78535 8.66655 7.78535C9.37832 7.78535 10.0038 8.42162 10.0038 9.3275C10.0038 10.2118 9.37832 10.8481 8.66655 10.8481ZM15.2558 10.8481C14.5548 10.8481 13.9509 10.2118 13.9509 9.3275C13.9509 8.42162 14.5548 7.78535 15.2558 7.78535C15.9675 7.78535 16.593 8.42162 16.593 9.3275C16.593 10.2118 15.9675 10.8481 15.2558 10.8481ZM11.9666 17.4157C9.63714 17.4157 8.07342 15.8628 8.07342 15.1295C8.07342 14.8599 8.34302 14.7412 8.56949 14.8491C9.3891 15.2804 10.3381 15.7981 11.9666 15.7981C13.5842 15.7981 14.544 15.2912 15.3528 14.8491C15.5901 14.7412 15.8489 14.8599 15.8489 15.1295C15.8489 15.8628 14.2852 17.4157 11.9666 17.4157Z",
      },
    };
  },
  methods: {
    onMousemove(e) {
      if (!this.showCursor) this.showCursor = true;
      this.tween.x = e.clientX;
      this.tween.y = e.clientY;

      const identical = this.target === e.target && e.target.dataset.cursor !== this.cursor;

      if (this.target !== e.target || identical) {
        this.target = e.target;

        const tag = e.target.tagName;
        const cursor = e.target.dataset.cursor;
        const scale = e.target.dataset.cursorScale || 1;

        if (cursor) {
          this.animate(cursor, 0.5, scale);
        } else if (tag === "A") {
          this.animate("circle", 0.5, 1.5);
        } else {
          this.animate("square");
        }
      }
    },
    onMouseout(e) {
      this.showCursor = false;
    },
    animate(path, duration = 0.5, scale = 1) {
      this.cursor = path;
      gsap.to(this.$refs.path, {
        morphSVG: this.paths[path],
        duration,
        ease: "back.out(1.5)",
      });
      gsap.to(this.$refs.svg, {
        duration: duration * 1.5,
        scale,
        ease: "back.out(2)",
      });
    },
  },
  computed: {
    style() {
      return {
        transform: `translateX(${this.tween.x}px) translateY(${this.tween.y}px) translateZ(0px)`,
        opacity: this.showCursor ? 1 : 0,
      };
    },
  },
  mounted() {
    window.addEventListener("mousemove", this.onMousemove);
    document.documentElement.addEventListener("mouseleave", this.onMouseout, false);
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", this.onMousemove);
    document.documentElement.removeEventListener("mouseleave", this.onMouseout);
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  --size: 24rem;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: var(--size);
  height: var(--size);
  left: calc(var(--size) / -2);
  top: calc(var(--size) / -2);
  pointer-events: none;
  mix-blend-mode: difference;

  svg {
    fill: white;
  }
}
</style>
