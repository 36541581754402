<template>
  <div class="tool" :class="classes" @click="onClick">
    <!-- Navigation -->
    <navigation-bar subview v-show="!minimalUI" :backRoute="{ name: 'Library' }" />

    <!-- Main -->
    <main>
      <RenderScale position="center" @zoom="minimalUI = $event" ref="renderScale" v-if="ready">
        <file v-bind="payload" :blob="blobUrl" :showOriginal="showOriginal" />
        <grid-overlay :margin="payload.margin" v-if="showGridOverlay" />
      </RenderScale>
    </main>

    <!-- Parameters -->
    <aside v-show="ready && !minimalUI" ref="scroller">
      <div class="input">
        <input
          type="text"
          placeholder="Untitled"
          v-model="name"
          :style="{ opacity: inIsolation ? 0 : 1 }"
        />
        <div class="delete" @click="deleteFile" v-if="docRef">
          <icon name="Minus" />
        </div>
      </div>
      <!-- Canvas -->
      <tabs :style="{ opacity: inIsolation ? 0 : 1 }">
        <tab
          v-for="(tab, n) in tabs"
          :icon="tab.icon"
          :key="n"
          @click="activeTab = n"
          :active="n === activeTab"
          >{{ tab.label }}</tab
        >
      </tabs>

      <knobs-canvas
        v-if="activeTab === 0"
        v-model:bleed="payload.bleed"
        v-model:columnWidth="payload.columnWidth"
        v-model:ratio="payload.ratio"
        v-model:align="payload.align"
        v-model:orientation="payload.orientation"
        v-model:margin="payload.margin"
        v-model:fillColor="payload.fillColor"
        v-model:strokeColor="payload.strokeColor"
        v-model:strokeWidth="payload.strokeWidth"
      />

      <!-- Content -->
      <knobs-content
        v-if="activeTab === 1"
        :hasImage="blobUrl || payload.sourceImage ? true : false"
        :fileName="fileName"
        v-model:showOriginal="showOriginal"
        v-model:ditherType="ditherType"
        v-model:ditherAlgorithm="payload.ditherAlgorithm"
        v-model:paletteId="payload.paletteId"
        v-model:colorDitherMode="payload.colorDitherMode"
        v-model:brightness="payload.brightness"
        v-model:contrast="payload.contrast"
        v-model:contentFit="payload.contentFit"
        @uploaded="onUploaded"
      />
    </aside>

    <!-- Footer -->
    <footer v-show="!minimalUI && ready">
      <btn-display @click="updateOrCreateFile" :loading="rendering" :disabled="onDisplay" />
    </footer>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import Btn from "@/components/Btn";
import BtnDisplay from "@/components/BtnDisplay";
import Icon from "@/components/Icon";
import Loading from "@/components/Loading";
import GridOverlay from "@/components/GridOverlay";

import File from "./File";

import KnobsCanvas from "./Knobs/KnobsCanvas";
import KnobsContent from "./Knobs/KnobsContent";

import RenderScale from "@/components/Render/RenderScale";

import { mapGetters, mapMutations, mapState } from "vuex";
import timeout from "@/funcs/timeout";

import db from "@/db";
import { collection, doc, getDoc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

export default {
  name: "Tool",
  components: {
    NavigationBar,
    Tabs,
    Tab,
    Btn,
    BtnDisplay,
    Icon,
    File,
    KnobsCanvas,
    KnobsContent,
    RenderScale,
    Loading,
    GridOverlay,
  },
  data() {
    return {
      minimalUI: false,
      ready: false,
      payload: {
        bleed: false,
        ratio: "1:1",
        align: "center",
        orientation: "portrait",
        columnWidth: 8,
        margin: true,
        fillColor: "#ffffff",
        strokeColor: "#000000",
        strokeWidth: 0,
        contentFit: "cover",
        ditherAlgorithm: 77,
        paletteId: 0,
        colorDitherMode: 0,
        brightness: 100,
        contrast: 100,
        sourceImage: null,
      },
      showOriginal: false,
      ditherType: "arithmetic",
      tabs: [
        { label: "", icon: "Square" },
        { label: "", icon: "Content" },
      ],
      activeTab: 0,
      fileName: null,
      docRef: null,
      blobUrl: null,
      onDisplay: false,
      rendering: false,
    };
  },
  methods: {
    ...mapMutations(["setLocalTheme"]),
    ...mapMutations({
      disableKnobs: "knobs/setDisabled",
    }),
    onClick(e) {
      e.stopPropagation();
      if (e.target !== this.$refs.renderScale.$el) return;
      this.$router.push({ name: "Library" });
    },
    onUploaded(e) {
      this.blobUrl = e.src;
      this.fileName = e.name;
    },
    async updateOrCreateFile() {
      if (this.docRef) {
        await this.updateFile();
      } else {
        await this.createFile();
      }
    },
    async uploadImage() {
      if (!this.blobUrl) return;

      console.log("uploadImage");

      /* Get blob */
      const blob = await fetch(this.blobUrl).then((r) => r.blob());
      console.log("Blob recieved", blob);

      const storage = getStorage();
      const storageRef = ref(storage, this.id);

      // Upload
      const snapshot = await uploadBytes(storageRef, blob);
      console.log("Uploaded a blob", snapshot);

      // Get url
      const url = await getDownloadURL(storageRef);
      console.log("Blob available at ", url);

      this.payload.sourceImage = url;
      this.blobUrl = null;
    },
    async fetchFile() {
      const ref = doc(db, "files", this.id);
      this.docRef = await getDoc(ref);
      console.log("Document read with ID: ", this.docRef.id);

      if (this.docRef.exists()) {
        this.payload = this.docRef.data();
        this.$nextTick(() => {
          this.ready = true;
        });
      }
    },
    async createFile() {
      this.rendering = true;
      this.docRef = await addDoc(collection(db, "files"), this.payload);
      console.log("Document written with ID: ", this.docRef.id);

      // Replace route with edit
      this.$router.replace({
        name: "ToolEdit",
        params: { id: this.docRef.id },
      });

      // Will call upload and render functions
      await this.updateFile();
    },
    async updateFile() {
      await this.uploadImage();
      await updateDoc(doc(db, "files", this.docRef.id), this.payload);
      console.log("Document updated with ID: ", this.docRef.id);

      await this.render();
    },
    async render() {
      if (this.renderId) return;
      console.log("Render starts");
      this.renderId = Math.random();
      this.rendering = true;

      const params = new URLSearchParams({
        id: this.id,
        file: true,
        uuid: this.uuid,
        size: this.size,
      });
      const response = await fetch("https://api.monol.it/render?" + params);
      const blob = await response.blob();
      console.log("Blob recieved", blob);

      this.setPreviewImageUrl(blob);

      await timeout(2500);
      this.onDisplay = true;
      this.rendering = false;
      this.renderId = null;
    },
    async setPreviewImageUrl(blob) {
      console.log("setPreviewImageUrl");

      const storage = getStorage();
      const storageRef = ref(storage, `preview-${this.id}`);

      // Upload
      const snapshot = await uploadBytes(storageRef, blob);
      console.log("Uploaded as blob", snapshot);

      // Get url
      const previewImageUrl = await getDownloadURL(storageRef);
      console.log("Preview image: ", previewImageUrl);

      const file = doc(db, "files", this.id);

      await updateDoc(file, {
        previewImageUrl,
      });
    },
    async deleteFile() {
      const file = doc(db, "files", this.id);

      // Connected resources
      const storage = getStorage();
      const previewRef = ref(storage, `preview-${this.id}`);
      const sourceRef = ref(storage, this.id);

      // Delete the files
      await this.deleteStorageRef(previewRef);
      await this.deleteStorageRef(sourceRef);

      await deleteDoc(file);
      console.log("File deleted");

      // Go back
      this.$router.replace({ name: "Library" });
    },
    async deleteStorageRef(ref) {
      try {
        const url = await getDownloadURL(ref);
        console.log("ref deleted", url);
        return await deleteObject(ref);
      } catch (error) {
        console.log("ref not found");
      }
    },
  },
  computed: {
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
      hasEvent: "knobs/hasEvent",
    }),
    ...mapGetters(["uuid", "size"]),
    ...mapState(["globalTheme"]),
    classes() {
      return {
        "minimal-ui": this.minimalUI,
      };
    },
    id() {
      const routeId = this.$route.params.id;
      if (routeId) return routeId;

      if (this.docRef) {
        return this.docRef.id;
      }
    },
    showGridOverlay() {
      return this.hasEvent("grid");
    },
    name: {
      get() {
        return this.payload.name;
      },
      async set(name) {
        if (!this.docRef) return;
        console.log("set");
        this.payload.name = name;
        const response = await updateDoc(doc(db, "files", this.docRef.id), {
          name,
        });
        console.log("set", response);
        //
      },
    },
  },
  watch: {
    payload: {
      handler: function () {
        this.onDisplay = false;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.id) {
      this.fetchFile();
    } else {
      this.ready = true;
    }

    const isTouchDevice = () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };

    if (!isTouchDevice()) return;

    this.$refs.scroller.addEventListener(
      "scroll",
      () => {
        this.disableKnobs(true);
      },
      { passive: true }
    );

    this.$refs.scroller.addEventListener("touchend", () => {
      this.disableKnobs(false);
    });
  },
  deactivated() {
    this.setLocalTheme(null);

    this.docRef = null;
    this.blobUrl = null;
    this.fileName = null;
    this.activeTab = 0;
    this.payload = {
      bleed: false,
      ratio: "1:1",
      align: "center",
      orientation: "portrait",
      columnWidth: 8,
      margin: true,
      fillColor: "#ffffff",
      strokeColor: "#000000",
      strokeWidth: 0,
      contentFit: "cover",
      ditherAlgorithm: 77,
      paletteId: 0,
      colorDitherMode: 0,
      brightness: 100,
      contrast: 100,
      sourceImage: null,
    };
  },
  activated() {
    // done to reverse
    this.setLocalTheme(this.globalTheme);
    if (this.id) {
      this.fetchFile();
    }
  },
};
</script>

<style lang="scss" scoped>
.tool {
  width: 100vw;
  height: 100vh;
  display: grid;
  padding: var(--height-bar) var(--spacing-gutter);
  padding-bottom: 44rem;
  column-gap: var(--spacing-gutter);
  grid-template-columns: 1fr 375rem;

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 44rem;
    left: 0;
    padding: 0 var(--spacing-gutter);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 24rem;
  }

  &.minimal-ui {
    padding: 0;
    position: relative;
    z-index: 10;
    grid-template-columns: 1fr;
  }

  .input {
    position: relative;

    input {
      padding-right: 44rem + 8rem;
      width: 100%;
    }
    .delete {
      position: absolute;
      height: 100%;
      width: 44rem;
      right: 0;
      display: grid;
      place-items: center;
      top: 0;

      &:hover {
        background-color: var(--color-fill-tertiary);
      }
    }
  }

  main {
    flex: 1;
    display: flex;
    position: relative;
  }

  aside {
    display: flex;
    flex-direction: column;
    row-gap: 24rem;
    height: calc(100vh - 88rem);
    overflow-y: auto;
    overflow-x: visible;
    margin-right: calc(var(--spacing-gutter) * -1);
    margin-left: calc(var(--spacing-gutter) * -1);
    padding-left: var(--spacing-gutter);
    padding-right: var(--spacing-gutter);

    .knobs {
      //mix-blend-mode: multiply;
    }
  }
}
</style>
