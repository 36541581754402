<template>
  <div class="render" :class="{ native: native }" :style="style">
    <slot />
    <component
      v-if="componentName"
      :id="payload.id"
      :is="componentName"
      v-bind="props"
      @mounted="$emit('mounted')"
    />
  </div>
</template>

<script>
import RenderImage from "./RenderImage";
import RenderCode from "./RenderCode";
import RenderCodeRemote from "./RenderCodeRemote";

import { mapGetters } from "vuex";

export default {
  components: {
    RenderImage,
    RenderCode,
    RenderCodeRemote,
  },
  props: {
    payload: Object,
    native: Boolean,
  },
  computed: {
    ...mapGetters(["resolution"]),
    componentName() {
      if (!this.payload) return;

      const type = this.payload.type;
      if (type === "image") return "RenderImage";
      if (type === "code") {
        return this.native ? "RenderCode" : "RenderCodeRemote";
      }
    },
    props() {
      if (this.payload.type === "code" && !this.native) {
        return {
          id: this.payload.id,
        };
      }
      return this.payload.payload;
    },
    style() {
      if (this.native) return;
      return {
        width: `${this.resolution.width}px`,
        height: `${this.resolution.height}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.render {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  width: 100vw;
  height: 100vh;

  &:not(.native) {
    mix-blend-mode: multiply;
  }
}
</style>
