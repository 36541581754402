import {
  BoxGeometry,
  MeshLambertMaterial,
  MeshPhysicalMaterial,
  Mesh,
  TextureLoader,
  VideoTexture,
} from "three";

const loader = new TextureLoader();

function Magazine(urls, size = 4, backCovers = []) {
  if (!(this instanceof Magazine)) {
    return new Magazine(urls);
  }
  //this.urls = urls;
  const materials = urls.map((url, n) => {
    let map;
    if (url.includes(".mp4")) {
      const video = document.createElement("video");

      video.src = url;
      video.autoplay = true;
      video.muted = true;
      video.loop = true;
      video.load(); // must call after setting/changing source
      video.play();
      //document.body.appendChild(video);

      map = new VideoTexture(video);
    } else {
      map = loader.load(url);
    }

    return new MeshPhysicalMaterial({
      map,
      //shininess: 1,
      metalness: 0.4,
      roughness: 0.7,
      clearcoat: 0,
      clearcoatRoughness: 1,
      reflectivity: 1,
      transparent: true,
    });
  });

  this.backCover = 0;
  this.backCovers = backCovers.map((url) => loader.load(url));

  const geometry = new BoxGeometry((3 / 4) * size, size, 24 / 168);
  this.cube = new Mesh(geometry, materials);

  return this;
}

Magazine.prototype.updateBackCover = function () {
  if (this.backCover + 1 === this.backCovers.length) {
    this.backCover = 0;
  } else {
    this.backCover++;
  }

  this.cube.material[5].map = this.backCovers[this.backCover];
};

export default Magazine;
