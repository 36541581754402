<template>
  <div class="battery">
    <div class="rect">
      <div class="bar" v-for="bar in bars" :key="bar" :class="{ active: bar <= activeBars }" />
    </div>
    <span v-if="!label"> {{ percentage }}% </span>
    <span v-else>{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 100,
    },
    bars: {
      type: Number,
      default: 8,
    },
    label: String,
  },
  computed: {
    activeBars() {
      return Math.round((this.percentage / 100) * this.bars);
    },
  },
};
</script>

<style lang="scss" scoped>
.battery {
  display: flex;
  align-items: center;
  column-gap: 8rem;
  pointer-events: none;

  .rect {
    pointer-events: none;
    display: flex;
    height: 16rem;
    column-gap: 2rem;

    .bar {
      height: inherit;
      width: 2rem;
      background-color: var(--color-fill-secondary);

      &.active {
        background-color: var(--color-fill-primary);
      }
    }
  }
}
</style>
