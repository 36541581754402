import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBzweAJ38knH2jeqMtr_cHtXefIPWGv5Uk",
  authDomain: "monolit-d3ea9.firebaseapp.com",
  projectId: "monolit-d3ea9",
  storageBucket: "monolit-d3ea9.appspot.com",
});

const db = getFirestore();

export default db;
