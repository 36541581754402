// Import ThreeJS and assign it to global scope
// This way examples/ folder can use it too
const THREE = require("three");
global.THREE = THREE;

// Import extra THREE plugins
require("three/examples/jsm/controls/OrbitControls");
require("three/examples/jsm/geometries/RoundedBoxGeometry.js");
require("three/examples/jsm/loaders/GLTFLoader.js");
require("three/examples/jsm/loaders/RGBELoader.js");
require("three/examples/jsm/postprocessing/EffectComposer.js");
require("three/examples/jsm/postprocessing/RenderPass.js");
require("three/examples/jsm/postprocessing/ShaderPass.js");
require("three/examples/jsm/postprocessing/UnrealBloomPass.js");
require("three/examples/jsm/shaders/LuminosityHighPassShader.js");
require("three/examples/jsm/shaders/CopyShader.js");

const Stats = require("stats-js");
const { GUI } = require("dat.gui");

const settings = {
  animate: true,
  context: "webgl",
  resizeCanvas: false,
};

export default function (el, { width, height, texture }) {
  let raf = null;
  const enableGUI = false;

  const stats = new Stats();
  let gui;
  //document.body.appendChild(stats.dom);
  if (enableGUI) gui = new GUI();

  const options = {
    enableSwoopingCamera: false,
    enableRotation: false,
    color: 0xffffff,
    metalness: 0,
    roughness: 0.2,
    transmission: 1,
    ior: 1.37,
    reflectivity: 0.5,
    thickness: 5,
    envMapIntensity: 0, //0.1
    clearcoat: 1,
    clearcoatRoughness: 0.1,
    normalScale: 3.13,
    clearcoatNormalScale: 0,
    normalRepeat: 4,
    // attenuationTint: 0xffffff,
    // attenuationDistance: 0,
    bloomThreshold: 0.85,
    bloomStrength: 0.35,
    bloomRadius: 0.33,
  };

  // Setup
  // -----

  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  el.appendChild(renderer.domElement);
  //renderer.setClearColor(0x1f1e1c, 1);

  const camera = new THREE.PerspectiveCamera(45, 1, 0.01, 100);
  camera.position.set(0, 0, 5);

  const controls = new THREE.OrbitControls(camera, renderer.domElement);
  controls.enabled = !options.enableSwoopingCamera;

  const scene = new THREE.Scene();

  const renderPass = new THREE.RenderPass(scene, camera);
  const bloomPass = new THREE.UnrealBloomPass(
    new THREE.Vector2(width, height),
    options.bloomStrength,
    options.bloomRadius,
    options.bloomThreshold
  );

  const composer = new THREE.EffectComposer(renderer);
  composer.addPass(renderPass);
  composer.addPass(bloomPass);

  // Content
  // -------

  const textureLoader = new THREE.TextureLoader();

  const bgTexture = textureLoader.load(require("./texture.jpg"));
  const bgGeometry = new THREE.PlaneGeometry(10, (10 / width) * height);

  const map = new THREE.CanvasTexture(texture);

  const bgMaterial = new THREE.MeshBasicMaterial({ map });
  const bgMesh = new THREE.Mesh(bgGeometry, bgMaterial);
  bgMesh.position.set(0, 0, -1);
  scene.add(bgMesh);

  const hdrEquirect = new THREE.RGBELoader().load(
    require("./empty_warehouse_01_2k.hdr"),
    () => {
      hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
    }
  );

  const normalMapTexture = textureLoader.load(require("./normal.jpg"));
  normalMapTexture.wrapS = THREE.RepeatWrapping;
  normalMapTexture.wrapT = THREE.RepeatWrapping;
  normalMapTexture.repeat.set(options.normalRepeat, options.normalRepeat);

  const material = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: options.metalness,
    roughness: options.roughness,
    transmission: options.transmission,
    ior: options.ior,
    reflectivity: options.reflectivity,
    thickness: options.thickness,
    envMap: hdrEquirect,
    envMapIntensity: options.envMapIntensity,
    clearcoat: options.clearcoat,
    clearcoatRoughness: options.clearcoatRoughness,
    normalScale: new THREE.Vector2(options.normalScale),
    normalMap: normalMapTexture,
    clearcoatNormalMap: normalMapTexture,
    clearcoatNormalScale: new THREE.Vector2(options.clearcoatNormalScale),
    // attenuationTint: options.attenuationTint,
    // attenuationDistance: options.attenuationDistance,
  });

  let mesh = null;

  // Load dragon GLTF model
  //   new THREE.GLTFLoader().load(require("./dragon.glb"), (gltf) => {
  //     const dragon = gltf.scene.children.find((mesh) => mesh.name === "Dragon");
  //
  //     // Just copy the geometry from the loaded model
  //     const geometry = dragon.geometry.clone();
  //
  //     // Adjust geometry to suit our scene
  //     geometry.rotateX(Math.PI / 2);
  //     geometry.translate(0, -4, 0);
  //
  //     // Create a new mesh and place it in the scene
  //     mesh = new THREE.Mesh(geometry, material);
  //     mesh.scale.set(0.25, 0.25, 0.25);
  //     scene.add(mesh);
  //
  //     // Discard the loaded model
  //     gltf.scene.children.forEach((child) => {
  //       child.geometry.dispose();
  //       child.material.dispose();
  //     });
  //   });

  const positions = [
    [-2.85, 0.85, 0],
    // [0.85, 0.85, 0],
    [2.85, -0.85, 0],
    [-1.7, -2.5, 0],
  ];

  const length = 4.5 * (3 / 4),
    w = 4.5;

  const shape = new THREE.Shape();
  shape.moveTo(0, 0);
  shape.lineTo(0, w);
  shape.lineTo(length, w);
  shape.lineTo(length, 0);
  shape.lineTo(0, 0);

  const extrudeSettings = {
    steps: 12,
    depth: 0.02,
    bevelEnabled: true,
    bevelThickness: 0.02,
    bevelSize: 0.015,
    bevelOffset: 0.05,
    bevelSegments: 1,
  };

  const geometries = [
    // new THREE.IcosahedronGeometry(0.75, 0), // Faceted
    new THREE.IcosahedronGeometry(0.67, 24), // Sphere
    new THREE.RoundedBoxGeometry(1.12, 1.12, 1.12, 16, 0.2),
    new THREE.ExtrudeGeometry(shape, extrudeSettings),
  ];

  //   const geometry = new THREE.BoxGeometry(3, 4, 88 / 168);
  //
  //   mesh = new THREE.Mesh(geometry, material);
  //   mesh.scale.set(0.5, 0.5, 0.5);
  //   scene.add(mesh);

  const meshes = geometries.map((geometry) => new THREE.Mesh(geometry, material));

  meshes.forEach((mesh, i) => {
    scene.add(mesh);
    mesh.position.set(...positions[i]);
  });

  // GUI
  // ---
  if (enableGUI) {
    gui.add(options, "enableSwoopingCamera").onChange((val) => {
      controls.enabled = !val;
      controls.reset();
    });

    gui.add(options, "enableRotation").onChange(() => {
      if (mesh) mesh.rotation.set(0, 0, 0);
    });

    gui.addColor(options, "color").onChange((val) => {
      material.color.set(val);
    });

    gui.add(options, "roughness", 0, 1, 0.01).onChange((val) => {
      material.roughness = val;
    });

    gui.add(options, "metalness", 0, 1, 0.01).onChange((val) => {
      material.metalness = val;
    });

    gui.add(options, "transmission", 0, 1, 0.01).onChange((val) => {
      material.transmission = val;
    });

    gui.add(options, "ior", 1, 2.33, 0.01).onChange((val) => {
      material.ior = val;
    });

    gui.add(options, "reflectivity", 0, 1, 0.01).onChange((val) => {
      material.reflectivity = val;
    });

    gui.add(options, "thickness", 0, 5, 0.1).onChange((val) => {
      material.thickness = val;
    });

    gui.add(options, "envMapIntensity", 0, 3, 0.1).onChange((val) => {
      material.envMapIntensity = val;
    });

    gui.add(options, "clearcoat", 0, 1, 0.01).onChange((val) => {
      material.clearcoat = val;
    });

    gui.add(options, "clearcoatRoughness", 0, 1, 0.01).onChange((val) => {
      material.clearcoatRoughness = val;
    });

    gui.add(options, "normalScale", 0, 5, 0.01).onChange((val) => {
      material.normalScale.set(val, val);
    });

    gui.add(options, "clearcoatNormalScale", 0, 5, 0.01).onChange((val) => {
      material.clearcoatNormalScale.set(val, val);
    });

    gui.add(options, "normalRepeat", 1, 4, 1).onChange((val) => {
      normalMapTexture.repeat.set(val, val);
    });

    // gui.addColor(options, "attenuationTint").onChange((val) => {
    //   material.attenuationTint.set(val);
    // });

    // gui.add(options, "attenuationDistance", 0, 1, 0.01).onChange((val) => {
    //   material.attenuationDistance = val;
    // });

    const postprocessing = gui.addFolder("Post Processing");

    postprocessing.add(options, "bloomThreshold", 0, 1, 0.01).onChange((val) => {
      bloomPass.threshold = val;
    });

    postprocessing.add(options, "bloomStrength", 0, 5, 0.01).onChange((val) => {
      bloomPass.strength = val;
    });

    postprocessing.add(options, "bloomRadius", 0, 1, 0.01).onChange((val) => {
      bloomPass.radius = val;
    });
  }

  // Update
  // ------

  resize();

  const update = (time, deltaTime) => {
    const ROTATE_TIME = 10; // Time in seconds for a full rotation
    const xAxis = new THREE.Vector3(1, 0, 0);
    const yAxis = new THREE.Vector3(0, 1, 0);
    const rotateX = (deltaTime / ROTATE_TIME) * Math.PI * 2;
    const rotateY = (deltaTime / ROTATE_TIME) * Math.PI * 2;

    if (options.enableRotation && mesh) {
      mesh.rotateOnWorldAxis(xAxis, rotateX);
      mesh.rotateOnWorldAxis(yAxis, rotateY);
    }

    if (options.enableSwoopingCamera) {
      camera.position.x = Math.sin((time / 10) * Math.PI * 2) * 2;
      camera.position.y = Math.cos((time / 10) * Math.PI * 2) * 2;
      camera.position.z = 4;
      camera.lookAt(scene.position);
    }
  };

  function resize(_width = width, _height = height) {
    const dpr = Math.min(window.devicePixelRatio, 2); // Cap DPR scaling to 2x

    // canvas.width = _width * dpr;
    // canvas.height = _height * dpr;
    // canvas.style._width = _width + "px";
    // canvas.style._height = _height + "px";

    bloomPass.resolution.set(_width, _height);

    renderer.setPixelRatio(dpr);
    renderer.setSize(_width, _height);

    composer.setPixelRatio(dpr);
    composer.setSize(_width, _height);

    camera.aspect = _width / _height;
    camera.updateProjectionMatrix();
  }

  function render() {
    stats.begin();
    controls.update();
    //update(time, deltaTime);
    renderer.render(scene, camera);
    composer.render();
    bgMaterial.map.needsUpdate = true;
    stats.end();

    raf = requestAnimationFrame(render);
  }

  function unload() {
    mesh.geometry.dispose();
    material.dispose();
    hdrEquirect.dispose();
    controls.dispose();
    renderer.dispose();
    bloomPass.dispose();
    if (enableGUI) gui.destroy();
    document.body.removeChild(stats.dom);
    cancelAnimationFrame(raf);
  }

  render();

  // Lifecycle
  // ---------

  return {
    resize,
    render,
    unload,
  };
}
