import axios from "axios";

import { createStore } from "vuex";

import knobs from "@/components/Knobs/store";
import artworks from "./artworks";

export default createStore({
  state: {
    validThemes: [
      "auto",
      "slate",
      "slate-reverse",
      "gray",
      "darkgray",
      "black",
    ],
    globalTheme: "auto",
    localTheme: null,
    userPreferedTheme: window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "black"
      : "slate",
    uiScaleFactor: 1,
    deviceData: null,
    devicesData: null,
    devices: [
      "3b003200-0150-4b59-3537-312000000000", // MONOLIT
      "31003a00-0f47-3830-3933-303600000000", // MONOLIT Edition
    ],
    selectedDevice: "3b003200-0150-4b59-3537-312000000000",
    files: [],
    showGlobalSettings: false,
    settings: {
      grid: "bleed",
    },
  },
  mutations: {
    setGlobalTheme(state, theme) {
      state.globalTheme = state.validThemes.includes(theme) ? theme : "slate";
    },
    setLocalTheme(state, theme) {
      state.localTheme =
        !theme || state.validThemes.includes(theme) ? theme : "slate";
    },
    setUserPreferedTheme(state, theme) {
      state.userPreferedTheme = theme;
    },
    setUIScaleFactor(state, factor) {
      state.uiScaleFactor = factor;
    },
    setDeviceData(state, payload) {
      state.devicesData = payload;
      state.deviceData = payload[0];
    },
    addFile(state, { id, payload }) {
      if (state.files.find((f) => f.id === id)) return;

      state.files.push({
        type: "file",
        id,
        ...payload,
      });
    },
    modifyFile(state, { id, payload }) {
      const index = state.files.findIndex((f) => f.id === id);

      if (typeof index === "undefined") return;

      const file = state.files[index];

      state.files[index] = { ...file, ...payload };
    },
    removeFile(state, id) {
      const index = state.files.findIndex((f) => f.id === id);
      if (typeof index === "undefined") return;

      state.files.splice(index, 1);
    },
    setGlobalSettingsVisibility(state, bool) {
      state.showGlobalSettings = bool;
    },
    setGlobalSetting(state, { key, value }) {
      state.settings[key] = value;
    },
    setSelectedDevice(state, uuid) {
      state.selectedDevice = uuid;
    },
  },
  actions: {
    async getDeviceData({ dispatch, commit }) {
      /* Fetch real status from Monolit 32 */
      const response = await axios.get("https://api.monol.it");

      if (response) {
        commit("setDeviceData", response.data);
      }
    },
  },
  getters: {
    settings: (state) => state.settings,
    showGlobalSettings: (state) => state.showGlobalSettings,
    device: (state) => {
      if (!state.devicesData) return;
      return state.devicesData.find(
        (device) => device.Uuid === state.selectedDevice
      );
    },
    devicesData: (state) => state.devicesData,
    deviceData: (state) => state.deviceData,
    uuid: (state) => state.selectedDevice,
    battery: (state, getters) => {
      if (!getters.device) return 100;

      return Number(getters.device.Status.Battery);
    },
    temperature: (state, getters) => {
      if (!getters.device) return 100;

      return Number(getters.device.Status.Temperature);
    },
    wifiStrength: (state, getters) => {
      if (!getters.device) return 0;

      return Number(getters.device.Status.RSSI);
    },
    name: (state, getters) => {
      if (!getters.device) return "MONOLIT";

      return getters.device.Options.Name;
    },
    size: (state, getters) => {
      if (!getters.name) return "SMALL";
      return getters.name === "MONOLIT Edition" ? "LARGE" : "SMALL";
    },
    resolution: (state, getters) => {
      if (getters.size === "LARGE")
        return {
          width: 2160,
          height: 2880,
        };

      if (getters.size === "SMALL") {
        return {
          width: 1200,
          height: 1600,
        };
      }
    },
    uiScaleFactor: (state) => state.uiScaleFactor,
    globalTheme: (state, getters) => {
      if (getters.localTheme) return getters.localTheme;
      return state.globalTheme === "auto"
        ? state.userPreferedTheme
        : state.globalTheme;
    },
    localTheme: (state) => {
      return state.localTheme === "auto"
        ? state.userPreferedTheme
        : state.localTheme;
    },
    files: (state) => state.files,
  },
  modules: {
    knobs,
    artworks,
  },
});
