<template>
  <div class="slider-number">
    <div class="slider">
      <div class="thumb" :style="style" />
    </div>
  </div>
</template>

<script>
import hammer from "hammerjs";

import map from "../utils/map";
import clamp from "../utils/clamp";
import roundValueToStep from "../utils/roundValueToStep";

import { mapGetters } from "vuex";

export default {
  props: {
    modelValue: Number,
    step: Number,
    min: Number,
    max: Number,
    disabled: Boolean,
    active: Boolean,
  },
  data() {
    return {
      width: 0,
    };
  },
  methods: {
    formatValue(value) {
      value = roundValueToStep(value, this.step, this.min);
      value = clamp(value, this.min, this.max);
      return value;
    },
    getValue(x) {
      return map(x, 0, this.width, this.min, this.max);
    },
    getX(value) {
      return map(value, this.min, this.max, 0, this.width);
    },
    getT(value) {
      return map(value, this.min, this.max, 0, 1);
    },
    updateValue(value) {
      value = this.formatValue(value);

      this.$emit("update:modelValue", value);
      //this.$emit("t", this.getT(value));

      return value;
    },
    /* Component logic
     */
    init() {
      this.width = this.$el.getBoundingClientRect().width;

      this.updateValue(this.computedValue);

      this.manager = new hammer.Manager(this.$el);

      this.pan = new hammer.Pan({
        threshold: this.dragThreshold,
        touchAction: "compute",
        direction: hammer.DIRECTION_HORIZONTAL,
      });

      this.manager.add([this.pan]);

      this.manager.on("panstart", this.onPanStart);
      this.manager.on("panend", this.onPanEnd);
      this.manager.on("pancancel", this.onPanEnd);
      this.manager.on("panmove", this.onPanMove);
    },
    onPanStart(e) {
      if (this.disabled) return false;
      this.savedX = this.getX(this.computedValue);
    },
    onPanMove(e) {
      if (this.disabled) return false;
      const x = this.savedX + e.deltaX;
      const value = this.getValue(x);

      this.updateValue(value);
    },
    onPanEnd(e) {
      if (this.disabled) return false;
    },
  },
  computed: {
    ...mapGetters({
      dragThreshold: "knobs/dragThreshold",
    }),
    computedValue() {
      return this.formatValue(this.modelValue);
    },
    style() {
      return {
        width: this.getX(this.computedValue) + "px",
      };
    },
  },
  watch: {
    modelValue() {
      this.$emit("t", this.getT(this.modelValue));
    },
  },
  mounted() {
    setTimeout(this.init, 1);
  },
  beforeDestroy() {
    this.manager.destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "../style";

.slider-number {
  touch-action: pan-y !important;
  .slider {
    @extend .slider;
    .thumb {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      @extend .thumb;
    }
  }
}
</style>
