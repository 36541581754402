import artworks from "@/assets/artworks/test";
import artists from "@/assets/data/artists";

import { getTheme } from "@/funcs/theme";
import randomTitle from "@/funcs/randomTitle";

export default {
  namespaced: true,
  state: {
    artworks,
    artists,
    active: "CGDmBVTJnjsI",
    library: [
      "1Ve4CiW_2vfD",
      "w00FEyLoigQZ",
      "dG3h8f7OPP1W",
      "cWyy4jQZXL9E",
      "4dZAlS8GDuuu",
      "CGDmBVTJnjsI",
      "dljJJGK7fRhL",
      "BQwMLBfS22jW",
      "gNEDbGgdJpYg",
      "PkRovCeHFsR9",
      "A3DTlVuNg_wn",
      "H3KE45ylaFSu",
      "B9W93YlX4k4k",
      "dG3h6-7OPPKC",
      "h0YmpXUqCnxi",
      "y2qzL16OV51T",
      "dnopf1UeKaJL",
      "sN7LXQ8bHqJ0",
      "WncLryaqb16z",
      "6b5Rbt2Scgr2",
      "mmIh3I3ZzX4J",
      "sLBETyjvsLyv",
      "Yg5c8kU3wdKT",
      "Uetzol9G5VyM",
      "sbpAumaDFLsq",
    ],
  },
  mutations: {
    setArtworkUrl(state, { id, url }) {
      const index = state.artworks.findIndex((a) => a.id === id);

      state.artworks[index].url = url;
    },
    addToLibrary(state, id) {
      if (state.library.find((_id) => id === _id)) return;

      state.library.push(id);
    },
  },
  getters: {
    isActive: (state) => (id) => {
      return id === state.active;
    },
    getArtist: (state) => (id) => {
      return state.artists.find((artist) => artist.id === id).name;
    },
    getArtistId: (state) => (id) => {
      return state.artworks.find((a) => a.id === id).artistId;
    },
    getImageSizes: () => (src) => {
      if (!src) return;
      return {
        src: require(`@/assets/artworks/${src}.jpg`),
        s: require(`@/assets/artworks/${src}@s.jpg`),
      };
    },
    getArtwork: (state, getters) => (id) => {
      const artwork = state.artworks.find((a) => a.id === id);

      if (!artwork) return null;

      let payload = {};

      const type = artwork.type || "image";

      if (type === "series") {
        payload.artworks = getters.getSeries(artwork.id);
      } else {
        payload.image = getters.getImageSizes(artwork.src);
      }

      payload.artist = getters.getArtist(
        artwork.artistId || getters.getArtistId(artwork.seriesId)
      );

      const color = artwork.dominantColor ? artwork.dominantColor[0] : null;

      return {
        ...artwork,
        ...payload,
        type,
        theme: artwork.theme || getTheme(color),
        name: artwork.name || randomTitle(),
        active: getters.isActive(artwork.id),
      };
    },
    getRenderedArtwork: (state, getters) => (id) => {
      const getPayload = (artwork) => {
        let payload = {};
        if (artwork.type === "image") {
          payload.src = artwork.image.src;
        }
        if (artwork.type === "code") payload = artwork.code;

        return payload;
      };

      const artwork = getters.getArtwork(id);
      const payload = getPayload(artwork);

      return {
        id: artwork.id,
        type: artwork.type,
        payload,
      };
    },
    getSeries: (state, getters) => (seriesId) => {
      return state.artworks
        .filter((a) => a.seriesId === seriesId)
        .map((a) => getters.getArtwork(a.id));
    },
    getArtworks: (state, getters) => (ids) => {
      return ids.map((id) => getters.getArtwork(id));
    },
    all: (state, getters) => {
      return state.artworks.map((a) => getters.getArtwork(a.id)).filter((a) => !a.seriesId);
    },
    library: (state, getters) => {
      return state.library.map((id) => getters.getArtwork(id));
    },
  },
};
