<template>
  <div class="depth" @mousemove="onMousemove">
    <div class="container">
      <depth-map :pos="pos" :offset="4" :src="src" :depthSrc="depthSrc" />
    </div>
  </div>
</template>

<script>
import DepthMap from "@/components/DepthMap";

export default {
  components: {
    DepthMap,
  },
  data() {
    return {
      pos: { x: 0, y: 0 },
      depthSrc: require("@/assets/img/depth/woman-d.jpg"),
      src: require("@/assets/img/depth/woman.jpg"),
    };
  },
  methods: {
    onMousemove(e) {
      const x = e.clientX / window.innerWidth;
      const y = e.clientY / window.innerHeight;

      this.pos = {
        x,
        y,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.depth {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background: #c0c0c0;

  .container {
    width: 40%;
    aspect-ratio: 3/4;
    position: relative;
  }
}
</style>
