<template>
  <span class="sf-symbol" :class="classes" :style="style" v-html="display" />
</template>

<script>
export default {
  name: "SFSymbol",
  props: {
    display: {
      type: String,
      default: "􀙣",
    },
    ligatureSchema: {
      type: Object,
      default() {
        return {
          circle: 12,
          square: 13,
          fill: 14,
          small: 15,
          large: 16,
          ["lowercase-alignment"]: 17,
        };
      },
    },
    ligatures: Array,
    weightSchema: {
      type: Object,
      default() {
        return {
          ultralight: 28.93,
          thin: 112.72,
          light: 276.31,
          regular: 400,
          medium: 508,
          semibold: 590.8,
          bold: 700,
          heavy: 858.4,
          black: 1000,
        };
      },
    },
    weight: {
      type: String,
      default: "regular",
    },
    preset: String,
  },
  computed: {
    computedLigatures() {
      if (!this.ligatures) return null;

      let ligatures = [];
      this.ligatures.forEach((ligature) => {
        ligatures.push(`"ss${this.ligatureSchema[ligature]}"`);
      });

      return ligatures.join(", ");
    },
    style() {
      return {
        fontVariationSettings: `'wght' ${this.weightSchema[this.weight]}`,
        // fontSize: this.size && `${this.size / 16}rem`,
        fontFeatureSettings: this.computedLigatures,
      };
    },
    classes() {
      return {
        [`preset-${this.preset}`]: this.preset,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sf-symbol {
  font-family: "SF Symbols";

  &.preset {
    &-large {
      font-size: 24px;
    }
  }
}
</style>
