<template>
  <div
    class="exhibition-poster theme"
    :class="classes.main"
    :style="styles.main"
    @click="$emit('click', $event)"
  >
    <main class="artwork" :class="classes.artwork">
      <img :src="cover" v-if="cover" />
      <component
        v-if="code && bounds && bounds.height"
        :is="code"
        :pos="pos"
        :width="bounds.width"
        :height="bounds.height"
        v-bind="codeProps"
      />
    </main>
    <div class="content" :class="classes.bundle" v-if="overlay">
      <div class="box exhibition type-large" :class="classes.exhibition">
        <h1 class="artist capitilize">{{ artist }}</h1>
        <p>{{ subtitle }}</p>
      </div>
      <div class="box date type-large" :class="classes.date">{{ date }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    artist: {
      type: String,
      default: "Artist name",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    date: {
      type: String,
      default: "Jun 8 - Jul 9",
    },
    code: [String, Object],
    codeProps: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      default: "black",
    },
    cover: String,
    background: String,
    overlay: {
      type: Boolean,
      default: true,
    },
    invertPosition: {
      type: Boolean,
      default: false,
    },
    exhibitionRight: {
      type: Boolean,
      default: false,
    },
    dateRight: {
      type: Boolean,
      default: false,
    },
    dateBg: {
      type: String,
      default: "transparent",
    },
    artworkPosition: {
      type: String,
      default: "default",
    },
    bundle: Boolean,
    bundlePosition: {
      type: String,
      default: "top",
    },
    local: Boolean,
  },
  data() {
    return {
      bounds: {},
      pos: { x: 0, y: 0 },
    };
  },
  methods: {
    onResize() {
      this.bounds = this.$el.getBoundingClientRect();
    },
    onMousemove(e) {
      const width = this.local ? this.bounds.width : window.innerWidth;
      const height = this.local ? this.bounds.height : window.innerHeight;

      const x = (e.clientX - (this.local ? this.bounds.left : 0)) / width;
      const y = (e.clientY - (this.local ? this.bounds.top : 0)) / height;

      this.pos = {
        x,
        y,
      };
    },
  },
  computed: {
    classes() {
      return {
        main: {
          [`theme-${this.theme}`]: this.theme,
        },
        artwork: {
          [this.artworkPosition]: this.artworkPosition,
        },
        exhibition: {
          top: this.invertPosition,
          bottom: !this.invertPosition,
          right: this.exhibitionRight,
        },
        date: {
          backgroundColor: this.dateBg,
          top: !this.invertPosition,
          bottom: this.invertPosition,
          right: this.dateRight,
        },
        bundle: {
          bundle: this.bundle,
          [this.bundlePosition]: this.bundlePosition,
        },
      };
    },
    styles() {
      return {
        main: {
          backgroundColor: this.background,
          "--height-unit": `${this.bounds.height / 100}px`,
        },
      };
    },
  },
  mounted() {
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$el);
    this.onResize();

    if (this.code) {
      const el = this.local ? this.$el : window;
      el.addEventListener("mousemove", this.onMousemove);
    }
  },
  beforeUnmount() {
    this.observer.unobserve(this.$el);

    if (this.code) {
      const el = this.local ? this.$el : window;
      el.removeEventListener("mousemove", this.onMousemove);
    }
  },
  deactivated() {
    this.pos = { x: 0, y: 0 };
  },
};
</script>

<style lang="scss" scoped>
.exhibition-poster {
  aspect-ratio: 3/4;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  gap: 0px 0px;

  /*   --height: calc(100vh - 44rem - 16rem - 8rem);
  --width: calc(var(--height) * (3 / 4));
  width: var(--width);
  flex: 0 0 var(--width);
  height: var(--height); */

  --height-unit: 1vh;
  --height-base: (774 / 100) * var(--height-unit);
  --text-size: calc(44 / var(--height-base));
  --padding: calc(8 / var(--height-base));
  .type-large {
    font-family: HelveticaNowRegular, Helvetica, sans-serif;
    font-size: var(--text-size);
    line-height: 100%;
    letter-spacing: calc(var(--text-size) * (2.5 / 100) * -1);
  }

  .top {
    grid-area: 1 / 1 / 2 / 2;
  }
  .bottom {
    grid-area: 3 / 1 / 4 / 2;
  }

  .right {
    text-align: right;
  }

  .box {
    padding: var(--padding);
    position: relative;
  }

  .exhibition {
    h1 {
      height: calc((56 / 64) * var(--text-size));
    }
  }

  .content {
    display: contents;
    position: relative;

    &.bundle {
      display: flex;

      .exhibition {
        flex: 1;
      }
    }
  }

  .artwork {
    /*     background: grey; */
    position: relative;

    /*     @at-root .theme-black .artwork {
      background: #222;
    } */

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &.default {
      grid-area: 2 / 1 / 3 / 2;
    }
    &.top {
      grid-area: 1 / 1 / 3 / 2;
    }
    &.bottom {
      grid-area: 2 / 1 / 4 / 2;
    }
    &.bleed {
      grid-area: 1 / 1 / 4 / 2;
    }
  }
}
</style>
