<template>
  <div class="btn-display" data-cursor="circle">
    <!--     <transition name="fade">
      <span v-show="!loading && !disabled">Display</span>
    </transition> -->
    <div class="container">
      <div class="shape" v-for="n in 2" :key="n" :ref="setShapes"></div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

import Loading from "@/components/Loading";
export default {
  components: {
    Loading,
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      shapes: [],
    };
  },
  methods: {
    setShapes(el) {
      if (el) {
        this.shapes.push(el);
      }
    },
    load() {
      if (this.timeline) {
        this.timeline.play();
        return;
      }
      this.timeline = gsap.timeline({
        repeat: -1,
        yoyo: true,
        repeatDelay: 0,
      });

      this.timeline
        .to(
          this.shapes,
          {
            scale: 1.5,
            opacity: 1,
            duration: 0.2,
            stagger: 0.15,
            borderRadius: 0,
            y: (i) => (1 + i * 5) * -1,
          },
          0.1
        )
        .to(
          this.shapes,
          {
            scale: 1,
            opacity: 1,
            //y: 0,
            duration: 0.2,
            stagger: 0.15,
          },
          0.5
        );
    },
    unload() {
      this.timeline.pause();

      this.timeline2 = gsap.timeline({});

      this.timeline2.to(this.shapes, {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "back.out(2)",
        y: 0,
        borderRadius: 8,
      });
    },
    restart() {
      this.animation = gsap.to(this.shapes, { scale: 1, borderRadius: 0 });
    },
  },
  watch: {
    loading() {
      if (this.loading) {
        this.load();
      } else {
        this.unload();
      }
    },
    disabled() {
      if (!this.disabled) {
        this.restart();
      }
    },
  },
  mounted() {
    if (this.loading) {
      this.load();
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-display {
  display: flex;
  align-items: center;
  column-gap: 8rem;
  position: relative;

  * {
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 44rem);
    height: 44rem;
    display: block;
    transform: translate(-50%, -50%);
    //background: rgba(red, 0.5);
  }

  &:hover {
    opacity: 0.5;
  }

  .container {
    width: 16rem;
    height: 16rem;
    position: relative;

    .shape {
      background: currentcolor;
      width: 100%;
      height: 100%;
      position: absolute;
      transform-origin: 50% 100%;
    }
  }
}
</style>
