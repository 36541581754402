<template>
  <div class="frame" @click="$emit('click', $event)" :class="{transparent: transparent}">
    <div class="content">
      <slot />
      <img v-if="src" class="fill absolute" :src="src" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    transparent: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.frame {
  width: 100%;
  aspect-ratio: 3/4;
  background-color: var(--color-bg-secondary);
  position: relative;
  pointer-events: all;
  transform-origin: 0% 0%;
  /*   scroll-snap-align: start; */
  
  &.transparent {
    background-color: transparent;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    mix-blend-mode: multiply;
    
    @at-root .theme-black & {
    mix-blend-mode: screen;  
    }
  }
}
</style>
