<template>
  <div class="artwork-view" :class="classes" @click="onClick">
    <navigation-bar subview v-show="!minimalUI" />

    <transition name="fade">
      <div class="center" v-if="!ready && isCode">
        <btn-display loading />
      </div>
    </transition>

    <main class="main fade" :style="{ opacity: ready ? 1 : 0 }">
      <RenderScale :position="position" @zoom="minimalUI = $event" ref="renderScale">
        <Render :payload="payload" @mounted="ready = true" />
      </RenderScale>
    </main>

    <aside v-if="schema && initialData" v-show="!minimalUI">
      <div class="fade" :style="{ opacity: ready && initialData ? 1 : 0 }">
        <knobs-code
          ref="knobsCode"
          v-if="initialData"
          :schema="schema"
          :data="initialData"
          @changed="onKnobsChanged"
        />
      </div>
    </aside>

    <bottom-strip
      v-if="!minimalUI && ready"
      :title="data.artist"
      :subheading="name"
      style="position: fixed; bottom: 0; left: 0"
      transparent
    >
      <template v-if="isStore">
        <span v-if="rendering" style="padding-right: 16rem; height: 32rem; display: flex">
          <!--           <loading /> -->
          <btn-display loading />
        </span>

        <btn v-else type="label" @click="display">Preview</btn>
        <btn>$50.00</btn>
      </template>
      <template v-else>
        <btn-display @click="display" :loading="rendering" :disabled="onDisplay" />
      </template>
    </bottom-strip>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { debounce } from "lodash";

import NavigationBar from "@/components/NavigationBar";

import Render from "@/components/Render/Render";
import RenderScale from "@/components/Render/RenderScale";
import KnobsCode from "@/components/Render/KnobsCode";

import BottomStrip from "@/components/BottomStrip";
import Btn from "@/components/Btn";
import BtnDisplay from "@/components/BtnDisplay";
import Loading from "@/components/Loading";

import renderImageBase64 from "@/funcs/renderImageBase64";

import db from "@/db";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import timeout from "@/funcs/timeout";

export default {
  components: {
    NavigationBar,
    Render,
    RenderScale,
    KnobsCode,
    BottomStrip,
    Btn,
    Loading,
    BtnDisplay,
  },
  data() {
    return {
      minimalUI: false,
      rendering: false,
      ready: false,
      initialData: false,
      onDisplay: false,
    };
  },
  computed: {
    ...mapGetters({
      getArtwork: "artworks/getArtwork",
      getRenderedArtwork: "artworks/getRenderedArtwork",
    }),
    ...mapGetters(["size", "uuid"]),
    id() {
      return this.$route.params.id;
    },
    data() {
      return this.getArtwork(this.id) || {};
    },
    payload() {
      return this.getRenderedArtwork(this.id) || {};
    },
    isCode() {
      if (!this.payload) return;
      return this.payload.type === "code";
    },
    isStore() {
      return this.$route.name === "StoreArtwork";
    },
    db() {
      if (!this.isCode) return;
      return doc(db, "artworks", this.id);
    },
    schema() {
      if (!this.isCode) return;
      return this.payload.payload.schema;
    },
    classes() {
      if (!this.data) return;
      return {
        "minimal-ui": this.minimalUI,
        bleed: !this.isCode,
      };
    },
    position() {
      return this.data.position || "center";
    },
    name() {
      return this.data.name;
    },
  },
  methods: {
    ...mapMutations(["setLocalTheme"]),
    display(e) {
      e.stopPropagation();
      if (this.isCode) {
        this.render();
      } else {
        this.preview();
      }
    },
    async preview() {
      if (this.rendering) return;

      this.rendering = true;

      const response = await renderImageBase64(
        this.data.image.src,
        "white",
        this.size,
        this.uuid
      );
      const body = await response.json();
      console.log("body", body);
      await timeout(2500);

      this.rendering = false;
      this.onDisplay = true;
    },
    async render() {
      if (this.rendering) return;
      console.log("Render starts");
      console.log();
      this.rendering = true;

      const params = new URLSearchParams({
        id: this.id,
        size: this.size,
        uuid: this.uuid,
      });

      const response = await fetch("https://api.monol.it/render?" + params);

      /* Format response to blob */
      const blob = await response.blob();
      console.log("Rendered blob recieved", blob);

      /*
      Upload blob to Firestore
      */
      await this.uploadImage(blob);

      /*
      Done
      */
      await timeout(500);
      this.rendering = false;
      this.onDisplay = true;
      console.log("Rendered");
    },
    async uploadImage(blob) {
      console.log("Image upload starts");
      console.log();

      const storage = getStorage();
      const storageRef = ref(storage, this.id);

      // Upload
      const snapshot = await uploadBytes(storageRef, blob);

      console.log("Uploaded as blob");
      console.log(snapshot);
      console.log();

      // Get url
      const url = await getDownloadURL(storageRef);
      console.log("Blob available at");
      console.log(url);
      console.log();

      await updateDoc(this.db, {
        url,
      });
    },
    reset() {
      this.$refs.knobsCode.reset();
    },
    onClick(e) {
      this.$router.go(-1);
    },
    onKnobsChanged: debounce(async function (params) {
      this.onDisplay = false;
      await updateDoc(this.db, {
        params,
      });
    }, 0),
  },
  async mounted() {
    this.setLocalTheme(this.data.theme);

    if (this.isCode) {
      const document = await getDoc(this.db);

      if (document.exists()) {
        this.initialData = document.data().params;
      }
    }
  },
  beforeUnmount() {
    this.setLocalTheme(null);
  },
};
</script>

<style lang="scss" scoped>
.artwork-view {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  padding: var(--height-bar) 0;
  padding-bottom: 44rem;
  column-gap: var(--spacing-gutter);
  grid-template-areas: "main aside";
  grid-template-columns: 1fr 375rem;

  &.bleed {
    grid-template-areas: "main";
    grid-template-columns: 1fr;
  }

  &.minimal-ui {
    padding: 0;
    position: relative;
    z-index: 10;
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  aside {
    grid-area: aside;
    padding: 0 var(--spacing-gutter);
  }

  main {
    height: 100%;
    display: flex;
    grid-area: main;
  }

  .render-circle {
    height: 32rem;
    width: 32rem;
    border-radius: 32rem;
    border: 1rem solid;
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .artwork {
    aspect-ratio: 3/4;
    min-height: 0;
    height: 100%;
    background-color: var(--base-color-canvas);
    justify-self: center;
    margin-left: var(--spacing-gutter);
    margin-right: var(--spacing-gutter);

    img {
      width: 100%;
      aspect-ratio: 3/4;
      mix-blend-mode: multiply;
    }
  }
}
</style>
