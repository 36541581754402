import script from "!!raw-loader!./src/sketch.js";
import markup from "raw-loader!./src/index.html";
import css from "!!raw-loader!./src/style.css";

export default {
  name: "hedgehog",
  version: "0.0.0",
  schema: {
    bg: {
      type: "color",
      default: "#ffffff",
    },
    fg: {
      type: "color",
      default: "#000000",
    },
    seed: {
      default: 0,
      step: 1,
    },
    offset: {
      default: 140,
      min: 0,
      max: 400,
      step: 10,
    },
    flowCellSize: {
      default: 10,
      min: 5,
      max: 200,
      step: 1,
    },
    // verticalPartitions: {
    //   default: 1,
    //   min: 1,
    //   max: 10,
    // },
    // horizontalPartitions: {
    //   default: 1,
    //   min: 1,
    //   max: 10,
    // },
    // verticalShift: {
    //   default: 200,
    //   min: 0,
    //   max: 500,
    // },
    // horizontalShift: {
    //   default: 40,
    //   min: 0,
    //   max: 500,
    // },
    noiseSize: {
      default: 0.0025,
      min: 0,
      max: 0.01,
      step: 0.0001,
    },
    noiseRadius: {
      default: 0.008,
      min: 0,
      max: 0.01,
      step: 0.001,
    },
    circularShape: {
      default: false,
    },
  },
  markup,
  script,
  css,
  cssUrls: [],
  scriptUrls: ["https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.0/p5.min.js"],
  dependencies: ["p5", "three.js"],
};
