<template>
  <li class="tab" :class="{ active: active }" @click="$emit('click', $event)">
    <icon :name="icon" v-if="icon" />
    <slot />
  </li>
</template>

<script>
import Icon from "@/components/Icon";
export default {
  components: {
    Icon,
  },
  props: {
    icon: String,
    active: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.tab {
  height: 44rem;
  padding: 0 var(--spacing-gutter);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4rem;
  flex: 1;

  &.active {
    background: var(--color-bg-secondary);
  }
}
</style>
