<template>
  <div class="artworks theme theme-black">
    <div class="container">
      <main v-if="view === 'view'" :style="style">
        <template v-if="!sortBy">
          <grid-cell
            v-for="(artwork, n) in computedArtworks"
            :key="n"
            :transparent="artwork.type === 'series'"
            v-bind="artwork"
            @click="navigateTo(artwork)"
          >
            <issue
              data-cursor="circle"
              v-if="artwork.type === 'series'"
              v-bind="artwork"
              :spread="spread"
              :title="artwork.artist"
              :subheading="artwork.name"
              @stacked="seriesExpanded = !$event"
            />
            <img v-if="artwork.type === 'image'" :src="artwork.image.s" data-cursor="circle" />
            <img
              v-if="artwork.type === 'code' && artwork.url"
              :src="artwork.url"
              style="object-fit: contain"
              data-cursor="circle"
            />
          </grid-cell>
        </template>
        <template v-else>
          <section v-for="(group, g) in sortedArtworks" :key="g">
            <header>{{ group[0].artist }}</header>
            <main :style="style">
              <grid-cell
                v-for="(artwork, n) in group"
                :key="n"
                :transparent="artwork.type === 'series'"
                v-bind="artwork"
                @click="navigateTo(artwork)"
              >
                <issue
                  data-cursor="circle"
                  v-if="artwork.type === 'series'"
                  v-bind="artwork"
                  :spread="spread"
                  :title="artwork.artist"
                  :subheading="artwork.name"
                  @stacked="seriesExpanded = !$event"
                />
                <img
                  v-if="artwork.type === 'image'"
                  :src="artwork.image.s"
                  data-cursor="circle"
                />
                <img
                  v-if="artwork.type === 'code' && artwork.url"
                  :src="artwork.url"
                  style="object-fit: contain"
                  data-cursor="circle"
                />
              </grid-cell>
            </main>
          </section>
        </template>
      </main>
    </div>
  </div>
</template>

<script>
import FilterBar from "@/components/FilterStrip";

import GridCell from "@/components/GridCell";
import ExhibitionPoster from "@/components/ExhibitionPoster";
import Issue from "@/components/Magazines/Issue";
import Index from "@/components/Index";

import { mapState, mapGetters, mapMutations } from "vuex";

import map from "@/funcs/map";

import ColorThief from "colorthief";

import { groupBy } from "lodash";

export default {
  name: "Index",
  components: {
    FilterBar,
    GridCell,
    ExhibitionPoster,
    Issue,
    Index,
  },
  props: {
    columns: Number,
    sortBy: String,
  },
  data() {
    return {
      view: "view",
      seriesExpanded: false,
    };
  },
  methods: {
    getTheme(artwork) {
      return artwork.theme || getTheme(artwork.dominantColor[0]);
    },
    getColor(colors) {
      if (!colors) return;
      return `rgb(${colors.join()})`;
    },
    navigateTo(artwork) {
      if (artwork.type === "series") {
        return;
      }

      this.$router.push({ name: "StoreArtwork", params: { id: artwork.id } });
    },
    loadColors() {
      let images = [];

      // const artworks = this.rawArtworks.filter(
      //   (artwork) => artwork.artistId === "KtY1vk_kzQ6Q"
      // );

      this.rawArtworks.forEach((artwork) => {
        if (artwork.type === "series") {
          images.push({ ...artwork });
          return;
        }

        const colorThief = new ColorThief();

        const img = document.createElement("img");

        const sizes = this.getImageSizes(artwork.src);
        img.src = sizes.s;

        img.addEventListener("load", (e) => {
          images.push({
            ...artwork,
            dominantColor: colorThief.getColor(img, 1),
            palette: colorThief.getPalette(img, 4, 1),
          });
        });
      });

      setTimeout(() => {
        images.sort((a, b) => {
          if (a.src < b.src) {
            return -1;
          }
          if (a.src > b.src) {
            return 1;
          }
          return 0;
        });
      }, 15000);
    },
  },
  computed: {
    ...mapState({
      rawArtworks: (state) => state.artworks.artworks,
    }),
    ...mapGetters({
      artworks: "artworks/all",
      getImageSizes: "artworks/getImageSizes",
    }),
    computedArtworks() {
      return [...this.artworks].sort(() => 0.5 - Math.random());
    },
    sortedArtworks() {
      if (!this.sortBy) return;
      return groupBy(this.artworks, this.sortBy);
    },
    style() {
      return {
        gridTemplateColumns: `repeat(${this.columns}, 1fr)`,
      };
    },
    spread() {
      return map(this.columns, 16, 2, 0.5, 2);
    },
  },
  watch: {
    seriesExpanded() {
      const theme = this.seriesExpanded ? "transparent" : "slate-light";
    },
  },
};
</script>

<style lang="scss" scoped>
.artworks {
  width: 100vw;
  background: var(--color-bg-primary);
  position: relative;

  .container {
    min-height: calc(100vh - 44rem);
  }

  main {
    display: grid;
    //padding-top: 44rem;
    transform: translateZ(0px);
  }

  img {
    width: 100%;
    aspect-ratio: 3/4;
    grid-area: photo;
    mix-blend-mode: multiply;
  }

  section {
    grid-column: 1 / -1;
    margin-bottom: 44rem;

    &:first-child {
      margin-top: 44rem;
    }
  }
  header {
    background: var(--color-bg-primary);
    height: 44rem;
    position: sticky;
    z-index: 100000;
    padding: 0 var(--spacing-gutter);
    display: flex;
    align-items: center;
    top: 0;
    text-transform: uppercase;
  }
}
</style>
