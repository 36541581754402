<template>
  <Render :native="native">
    <div class="file" :style="styleFile">
      <div class="content" :class="classesContent" :style="styleContent">
        <dither-canvas
          :ditherAlgorithm="ditherAlgorithm"
          :paletteId="paletteId"
          :colorDitherMode="colorDitherMode"
          :brightness="brightness"
          :contrast="contrast"
          :showOriginal="showOriginal"
          :contentFit="contentFit"
          :src="source"
          @loaded="loaded = true"
          v-show="source"
        />
      </div>
    </div>
  </Render>
</template>

<script>
import { getRatio, isNumeric } from "./helpers";
import { mapGetters } from "vuex";

import Render from "@/components/Render/Render";
import DitherCanvas from "@/components/DitherStudio";

export default {
  components: {
    Render,
    DitherCanvas,
  },
  props: {
    bleed: Boolean,
    ratio: { type: String, default: "1:1" },
    align: { type: String, default: "top" },
    orientation: { type: String, default: "portrait" },
    columnWidth: { type: Number, default: 8 },
    margin: { type: Boolean, default: true },
    fillColor: { type: String, default: "#ffffff" },
    strokeColor: { type: String, default: "#000000" },
    strokeWidth: { type: Number, default: "0" },
    contentFit: { type: String, default: "cover" },
    ditherAlgorithm: Number,
    paletteId: Number,
    colorDitherMode: Number,
    brightness: Number,
    contrast: Number,
    sourceImage: String,
    native: Boolean,
    blob: String,
    showOriginal: Boolean,
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    showIfEmpty() {
      setTimeout(() => {
        if (!this.source) this.loaded = true;
      }, 500);
    },
  },
  computed: {
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
    }),
    styleFile() {
      return {
        alignItems: this.align,
        padding: this.margin ? "116px" : 0,
        backgroundColor: this.fillColor,
      };
    },
    styleContent() {
      let aspectRatio;
      let gridColumn;
      let border;

      const getColumn = function (width) {
        if (!isNumeric(width)) return;
        const offset = 12 / 2 - width / 2 + 1;
        return `${offset} / span ${width}`;
      };

      aspectRatio = this.bleed
        ? "unset"
        : getRatio(this.ratio, this.orientation, "/");
      gridColumn = this.bleed ? "1 / 13" : getColumn(this.columnWidth);

      border =
        this.strokeWidth >= 0 &&
        `${this.strokeWidth}px solid ${this.strokeColor}`;

      return {
        aspectRatio,
        gridColumn,
        border,
        opacity: this.loaded ? 1 : 0,
      };
    },
    classesContent() {
      return {
        bleed: this.bleed,
        isolation: this.inIsolation,
        empty: !this.source,
        fade: !this.native,
      };
    },
    source() {
      return this.blob || this.sourceImage;
    },
  },
  deactivated() {
    this.loaded = false;
  },
  activated() {
    this.showIfEmpty();
  },
  mounted() {
    this.showIfEmpty();
  },
};
</script>

<style lang="scss" scoped>
.file {
  width: 100%;
  height: 100%;
  background: var(--base-color-canvas);
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .content {
    position: relative;

    &.isolation {
    }

    &.empty {
      background: var(--color-bg-primary);
    }

    &.bleed {
      grid-column: 1 / -1;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
