<template>
  <div class="artworks">
    <transition name="slide-y">
      <navigation-bar v-show="!seriesExpanded">
        <!-- Left -->
        <template v-slot:left>
          <router-link to="/settings">
            <battery :percentage="battery" :label="name" />
          </router-link>
        </template>

        <!-- Main navigation -->
        <router-link to="/gallery">Gallery</router-link>

        <template v-slot:right>
          <router-link class="search" to="/" style="color: var(--color-fg-secondary)"
            >Search</router-link
          >
        </template>
      </navigation-bar>
    </transition>

    <div class="container">
      <main :style="style">
        <div class="add" data-cursor="plus">
          <grid-cell @click="$router.push({ name: 'Tool' })" transparent>
            <div class="inner" data-cursor="plus">
              <icon name="Plus" />
            </div>
          </grid-cell>
        </div>
        <!-- <grid-cell>
          <exhibition-poster v-bind="exhibition" local />
        </grid-cell> -->
        <grid-cell transparent>
          <magazine magazine="sixteen" :no="2" />
        </grid-cell>
        <grid-cell
          v-for="(artwork, n) in computedArtworks"
          :key="n"
          :transparent="artwork.type === 'series'"
          :dot-scale="dotScale"
          v-bind="artwork"
          @click="navigateTo(artwork)"
        >
          <issue
            data-cursor="circle"
            v-if="artwork.type === 'series'"
            v-bind="artwork"
            :spread="spread"
            :title="artwork.artist"
            :subheading="artwork.name"
            @stacked="seriesExpanded = !$event"
          />
          <magazine v-if="artwork.type === 'magazine'" magazine="toiletpaper" :no="1" />
          <!-- <exhibition-poster v-if="artwork.artworkPosition" v-bind="artwork" /> -->
          <img v-if="artwork.type === 'image'" :src="artwork.image.s" data-cursor="circle" />
          <img
            v-if="artwork.type === 'code' && artwork.url"
            :src="artwork.url"
            style="object-fit: contain"
            data-cursor="circle"
          />
          <img
            v-if="artwork.type === 'file'"
            :src="artwork.previewImageUrl"
            style="object-fit: cover"
            data-cursor="circle"
          />
        </grid-cell>
      </main>
    </div>

    <!-- Filter bar -->
    <transition name="slide-y-reverse">
      <filter-strip v-model:columns="columnValue" v-show="!seriesExpanded">
        <!-- <button @click="loadColors">Load colors</button> -->
      </filter-strip>
    </transition>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import FilterStrip from "@/components/FilterStrip";

import Battery from "@/components/Battery";

import GridCell from "@/components/GridCell";
import Icon from "@/components/Icon";
import ExhibitionPoster from "@/components/ExhibitionPoster";
import Magazine from "@/components/Magazine";
import Issue from "@/components/Magazines/Issue";

import { mapState, mapGetters, mapMutations } from "vuex";
import exhibitions from "@/assets/exhibitions";

import map from "@/funcs/map";

import ColorThief from "colorthief";

export default {
  name: "Library",
  components: {
    NavigationBar,
    FilterStrip,
    GridCell,
    ExhibitionPoster,
    Issue,
    Icon,
    Magazine,
    Battery,
  },
  data() {
    return {
      columnValue: 0.879,
      seriesExpanded: false,
    };
  },
  methods: {
    ...mapMutations({
      addToLibrary: "artworks/addToLibrary",
    }),
    getTheme(artwork) {
      return artwork.theme || getTheme(artwork.dominantColor[0]);
    },
    getColor(colors) {
      if (!colors) return;
      return `rgb(${colors.join()})`;
    },
    navigateTo(artwork) {
      if (artwork.type === "series") {
        return;
      }

      if (artwork.type === "file") {
        this.$router.push({ name: "ToolEdit", params: { id: artwork.id } });
        return;
      }

      this.$router.push({ name: "Artwork", params: { id: artwork.id } });
    },
    loadColors() {
      let images = [];

      // const artworks = this.rawArtworks.filter(
      //   (artwork) => artwork.artistId === "KtY1vk_kzQ6Q"
      // );

      this.rawArtworks.forEach((artwork) => {
        if (artwork.type === "series") {
          images.push({ ...artwork });
          return;
        }

        const colorThief = new ColorThief();

        const img = document.createElement("img");

        const sizes = this.getImageSizes(artwork.src);
        img.src = sizes.s;

        img.addEventListener("load", (e) => {
          images.push({
            ...artwork,
            dominantColor: colorThief.getColor(img, 1),
            palette: colorThief.getPalette(img, 4, 1),
          });
        });
      });

      setTimeout(() => {
        images.sort((a, b) => {
          if (a.src < b.src) {
            return -1;
          }
          if (a.src > b.src) {
            return 1;
          }
          return 0;
        });
      }, 15000);
    },
  },
  computed: {
    ...mapState({
      rawArtworks: (state) => state.artworks.artworks,
    }),
    ...mapGetters(["battery", "files", "settings", "name"]),
    ...mapGetters({
      artworks: "artworks/all",
      library: "artworks/library",
      getImageSizes: "artworks/getImageSizes",
    }),
    computedArtworks() {
      //return this.artworks;

      //       const findExhibition = (id) => exhibitions.find((e) => e.id === id);
      //
      //       const _exhibitions = [findExhibition("OBjsYEQwiiyH"), findExhibition("JBjsYEQwiiyL")];

      return [...this.library, ...this.files];

      //return arr.sort(() => 0.5 - Math.random());

      //return [...this.files, ...library];
    },
    style() {
      let grid = {};
      if (this.settings.grid === "spacious") {
        grid = {
          padding: "64rem",
          gap: "64rem",
        };
      }
      return {
        gridTemplateColumns: `repeat(${this.columns}, 1fr)`,
        ...grid,
      };
    },
    spread() {
      return map(this.columns, 16, 2, 0.5, 2);
    },
    dotScale() {
      return map(this.columns, 8, 2, 0.5, 1);
    },
    columns() {
      return Math.round(map(this.columnValue, 1, 0, 2, 32));
    },
    exhibition() {
      return exhibitions.find((e) => e.id === "OBjsYEQwiiyH");
    },
  },
};
</script>

<style lang="scss" scoped>
.artworks {
  width: 100vw;
  position: relative;

  .container {
    min-height: calc(100vh - 44rem);
  }

  main {
    display: grid;
    padding-top: 44rem;
    transform: translateZ(0px);
    /*     column-gap: 8rem;
    row-gap: 8rem; */
  }

  img {
    width: 100%;
    aspect-ratio: 3/4;
    grid-area: photo;
    mix-blend-mode: multiply;
  }

  .add {
    --base-color-canvas: var(--color-bg-secondary);
    position: relative;

    .inner {
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;

      > * {
        pointer-events: none;
      }

      &:hover {
        > * {
          opacity: 0;
        }
      }
    }
  }
}
</style>
