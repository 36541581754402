<template>
  <div class="settings theme grid" :class="classes">
    <navigation-bar theme="transparent" subview>
      <template #right>
        <ul class="status-bar">
          <li>
            <span>{{ temperature }}°</span>
          </li>
          <li>
            <span>
              <wifi-icon :rssi="wifiStrength" />
            </span>
          </li>
          <li>
            <span>
              <battery :percentage="battery" />
            </span>
          </li>
        </ul>
      </template>
    </navigation-bar>
    <main>
      <Scene
        v-if="textures"
        ref="scene"
        :textures="textures"
        :initialName="name"
        :initalTextureIndex="textureIndex"
        :background="globalTheme === 'black' ? '#000' : null"
      />
    </main>
    <aside>
      <knobs>
        <knobs-group>
          <slider label="Frame" v-model="selectedDevice" :options="deviceOptions" />
        </knobs-group>
        <!-- <knobs-group>
          <li>
            <label>Battery</label>
            <span>
              <battery :percentage="battery" />
            </span>
          </li>
          <li>
            <label>Wi-Fi</label>
            <span>
              <wifi-icon :rssi="wifiStrength" />
            </span>
          </li>
          <li>
            <label>Temperature</label>
            <span>{{ temperature }}°</span>
          </li>
        </knobs-group> -->
        <!--Dither -->
        <knobs-group>
          <!-- <knob-header>Settings</knob-header> -->
          <slider label="Theme" :options="themeOptions" v-model="theme" />
          <slider label="Auto rotate" v-model="autoRotate" />
          <slider
            label="Interval"
            v-model="interval"
            :step="1"
            :min="1"
            :max="24"
            options
            :disabled="!autoRotate"
          />
          <slider
            label="Interface Size"
            v-model="uiScaleFactor"
            :options="textSizeOptions"
            :instant="false"
          />
        </knobs-group>
        <!-- <knobs-group>
          <slider label="Number" v-model="number" :min="50" :max="150" />
          <slider label="Steps" v-model="numberStep" :min="25" :max="100" :step="0.5" />
          <slider
            label="Auto rotate"
            v-model="interval"
            :step="1"
            :min="1"
            :max="24"
            options
          />
          <slider label="Color" v-model="color" colors />
        </knobs-group> -->
      </knobs>
    </aside>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";

import Knobs from "@/components/Knobs/Knobs";
import KnobsGroup from "@/components/Knobs/KnobsGroup";
import KnobHeader from "@/components/Knobs/KnobHeader";
import Slider from "@/components/Knobs/Slider";
import Battery from "@/components/Battery";
import WifiIcon from "@/components/WifiIcon";

import Artwork from "@/components/Artwork";
import Scene from "@/components/EInkTransition/Scene";

import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    NavigationBar,
    Knobs,
    KnobsGroup,
    KnobHeader,
    Slider,
    Artwork,
    Scene,
    Battery,
    WifiIcon,
  },
  data() {
    return {
      themeOptions: [
        { label: "Auto", value: "auto" },
        { label: "Light", value: "slate" },
        // { label: "Gray", value: "gray" },
        // { label: "Dark Gray", value: "darkgray" },
        { label: "Dark", value: "black" },
      ],
      autoRotate: true,
      textSizeOptions: [
        { label: "S", value: 0.6 },
        { label: "M", value: 0.8 },
        { label: "L", value: 1 },
        { label: "XL", value: 1.2 },
        { label: "XXL", value: 1.4 },
        { label: "XXXL 🙂", value: 2 },
      ],
      number: 100,
      numberStep: 50.5,
      interval: 12,
      color: "#ffffff",
    };
  },
  methods: {
    ...mapMutations(["setGlobalTheme", "setSelectedDevice", "setUIScaleFactor"]),
  },
  computed: {
    ...mapGetters([
      "battery",
      "wifiStrength",
      "name",
      "size",
      "temperature",
      "devicesData",
      "device",
      "globalTheme",
    ]),
    classes() {
      return {
        [`theme-${this.globalTheme}`]: this.globalTheme,
      };
    },
    deviceNames() {
      if (!this.devicesData) return;
      return this.devicesData.map((d) => d.Options.Name);
    },
    deviceOptions() {
      if (!this.devicesData) return;
      return this.devicesData.map((d) => {
        return {
          label: d.Options.Name,
          value: d.Uuid,
        };
      });
    },
    selectedDevice: {
      get() {
        return this.$store.state.selectedDevice;
      },
      set(uuid) {
        this.setSelectedDevice(uuid);
      },
    },
    theme: {
      get() {
        return this.$store.state.globalTheme;
      },
      set(theme) {
        this.setGlobalTheme(theme);
      },
    },
    uiScaleFactor: {
      get() {
        return this.$store.getters.uiScaleFactor;
      },
      set(theme) {
        this.setUIScaleFactor(theme);
      },
    },
    img() {
      return `https://api.monol.it/live/${this.selectedDevice}`;
    },
    textures() {
      if (!this.devicesData) return;
      return this.devicesData.map((d) => `https://api.monol.it/live/${d.Uuid}`);
    },
    textureIndex() {
      if (!this.devicesData) return;
      return this.devicesData.findIndex((d) => this.selectedDevice === d.Uuid);
    },
  },
  watch: {
    textureIndex() {
      if (!this.$refs.scene) return;
      this.$refs.scene.transition.transitionTo(this.textureIndex);
    },
    size(newSize, oldSize) {
      if (!this.$refs.scene) return;
      if (newSize === oldSize) return;
      this.$refs.scene.changeDeviceScale(this.size);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  height: 100vh;
  grid-template-columns: 375rem 1fr calc(100vh * (3 / 4.1));
  grid-template-areas: "aside spacing main";
  grid-column-gap: 16rem;

  &.theme-black {
    main {
      mix-blend-mode: screen;
    }
  }

  aside {
    grid-area: aside;
    padding-top: calc(var(--spacing-gutter) * 2 + 44rem);
  }
  main {
    grid-area: main;
    max-height: 100%;
    display: grid;
    place-items: center;
    position: relative;
    mix-blend-mode: multiply;
    padding-left: 16rem;
    margin-right: -16rem;
  }

  .status-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 24rem;
    height: 44rem;
  }

  /* li {
    list-style: none;
    height: 44rem;
    padding: var(--spacing-gutter);
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 16rem;
    padding: 0 16rem;
    opacity: var(--opacity-in-isolation);

    label {
      color: var(--color-fg-secondary);
    }
    span {
      display: flex;
      padding: 0 16rem;
    }
  } */
}
</style>
