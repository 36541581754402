<template>
  <div class="store-grid" :style="style">
    <div class="ratio" v-for="n in count" :key="n">
      <div class="item absolute">
        <frame />
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
export default {
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    count: {
      type: Number,
      default: 4,
    },
  },
  components: {
    Frame,
  },
  computed: {
    style() {
      return {
        gridTemplateColumns: `repeat(${this.columns}, 1fr)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.store-grid {
  display: grid;
  background: var(--color-bg-secondary);

  .ratio {
    aspect-ratio: 1/1;
    position: relative;

    /*     &:nth-child(1n) {
      background-color: #a3a3a3;
    }
    &:nth-child(2n) {
      background-color: #626262;
    }
    &:nth-child(3n) {
      background-color: #d6d6d6;
    }
    &:nth-child(4n) {
      background-color: #000000;
    }
    &:nth-child(5n) {
      background-color: #b1b1b1;
    }
    &:nth-child(6n) {
      background-color: #bbbbbb;
    }
    &:nth-child(7n) {
      background-color: #d3d4d5;
    }
    &:nth-child(8n) {
      background-color: #b5b5b5;
    } */

    &:hover {
      outline: 2px solid var(--color-fill-primary);
    }
  }

  .item {
    display: grid;
    place-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: ". photo .";
    //background: var(--color-bg-primary);
    > * {
      grid-area: photo;
    }
  }
}
</style>
