import { scaleLinear } from "d3-scale";

const themes = {
  THEME_BLACK: "black",
  THEME_DARKGRAY: "darkgray",
  THEME_GRAY: "gray",
  THEME_SLATE: "slate",
};

const getTheme = (color = 255) => {
  const scale = scaleLinear()
    .domain([0, 26, 52, 80, 131, 190, 255])
    .range([
      "THEME_BLACK",
      "THEME_BLACK",
      "THEME_GRAY",
      "THEME_GRAY",
      "THEME_GRAY",
      "THEME_SLATE",
      "THEME_SLATE",
    ]);

  return themes[scale(color)];
};

export { themes, getTheme };
