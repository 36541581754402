<template>
  <div class="grid-cell" :class="classes" :style="style">
    <div class="ratio">
      <div class="item absolute">
        <div
          class="frame"
          :class="{ transparent: transparent }"
          @mouseenter="hovered = true"
          @mouseleave="hovered = false"
          @click="onClick"
        >
          <slot />
          <div class="dot" :style="dotStyle" v-if="active">
            <icon :size="dotSize" name="Dot Filled" />
          </div>
        </div>
      </div>
    </div>
    <!--     <footer>
        <span class="capitilize">{{ artist }}</span> <span>Untitled</span>
      </footer> -->
  </div>
</template>

<script>
import Icon from "@/components/Icon";

import { mapGetters } from "vuex";

export default {
  components: {
    Icon,
  },
  props: {
    transparent: Boolean,
    bg2: String,
    bg: {
      type: String,
      default: "var(--color-bg-primary)",
    },
    theme: {
      type: String,
      default: "slate",
    },
    artist: String,
    active: Boolean,
    dotScale: Number,
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    onClick(e) {
      e.stopPropagation();
      this.$emit("click", e);
    },
  },
  computed: {
    ...mapGetters(["settings"]),
    classes() {
      return {
        hovered: this.hovered,
        square: this.settings.grid === "square",
      };
    },
    dotSize() {
      return 24 * this.dotScale;
    },
    dotStyle() {
      return {
        "--dot-scale": this.dotScale,
        "--dot-size": `${this.dotSize}rem`,
      };
    },
    style() {
      return {
        backgroundColor: this.bg2,
        "--hover-color": this.bg,
      };
    },
  },
  deactivated() {
    this.hovered = false;
  },
};
</script>

<style lang="scss" scoped>
.grid-cell {
  &.square:not(.transparent).hovered {
    background: var(--hover-color);
  }
  &.hovered {
    //cursor: pointer;
  }
  .ratio {
    aspect-ratio: 3/4;
    position: relative;
  }

  .item {
    display: grid;
    place-items: center;
    display: grid;
    grid-template-columns: 0fr 2fr 0fr;
    grid-template-areas: ". photo .";
    //background: var(--color-bg-primary);
    > * {
      grid-area: photo;
    }
  }

  &.square {
    .ratio {
      aspect-ratio: 1/1;
    }
    .item {
      grid-template-columns: 1fr 2fr 1fr;
    }
  }

  footer {
    padding-left: 16rem;
    margin-top: 8rem;
    margin-bottom: 8rem;
    mix-blend-mode: difference;
    opacity: 1;
  }

  .frame {
    aspect-ratio: 3/4;
    min-height: 0;
    width: 100%;
    position: relative;

    &:not(.transparent) {
      background: var(--base-color-canvas);
    }
  }

  .dot {
    position: absolute;
    bottom: -4rem;
    transform: translateY(calc(var(--dot-scale) * 100%));
    height: var(--dot-size);
    width: var(--dot-size);
  }
}
</style>
