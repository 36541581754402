<template>
  <div class="navigation-bar theme" :class="classes">
    <nav>
      <span @click="goBack" v-if="subview">
        <icon name="Arrow Left" data-cursor="circle" />
      </span>
      <slot name="left" />
    </nav>

    <!-- Main -->
    <main>
      <slot />
    </main>

    <!-- Right -->
    <aside>
      <slot name="right" />
    </aside>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { mapGetters } from "vuex";

export default {
  components: {
    Icon,
  },
  props: {
    subview: Boolean,
    theme: String,
    backRoute: Object,
  },
  methods: {
    goBack() {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
        return;
      }
      const routeLength = this.$route.path.split("/").length;

      if (this.$route.meta.view === "Store") {
        this.$router.push({ name: "Library" });
        return;
      }

      if (routeLength === 3) {
        this.$router.push({ name: "Home" });
        return;
      }

      this.$router.back();
    },
  },
  computed: {
    classes() {
      return {
        "theme-black": this.theme === "transparent",
        [`theme-${this.theme}`]: this.theme,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
  height: 44rem;
  padding: 0 var(--spacing-gutter);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-index-navigation-bar);
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  opacity: var(--opacity-in-isolation);

  &.theme {
    &-transparent {
      mix-blend-mode: difference;
      background-color: transparent;
      color: white;
    }
  }

  nav,
  main,
  aside {
    display: flex;
    gap: 44px;
    align-items: center;
    height: 100%;
  }

  nav {
    //grid-area: left;
    margin-left: -6rem;
  }

  main {
    //grid-area: main;
  }

  aside {
    //grid-area: right;
  }
}
</style>
