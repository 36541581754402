<template>
  <knobs>
    <!-- Upload image -->
    <knobs-group>
      <knob-header>Content</knob-header>
      <context-btn
        label="Source"
        :description="fileName"
        :icon="icon"
        @click.prevent="$refs.file.click()"
      />
      <!-- Content -->
      <slider
        label="Fit"
        v-if="hasImage"
        :modelValue="contentFit"
        :options="contentFitOptions"
        @update:modelValue="$emit('update:contentFit', $event)"
      />
      <input
        style="display: none"
        ref="file"
        type="file"
        accept="image/*"
        @change="uploadImage"
      />
    </knobs-group>

    <template v-if="hasImage">
      <!--Dither -->
      <knobs-group>
        <knob-header>Dithering</knob-header>
        <slider
          label="Type"
          :modelValue="ditherType"
          @update:modelValue="$emit('update:ditherType', $event)"
          :options="ditherKnobs"
          :instant="instantKnobs"
        />
        <slider
          label="Algorithm"
          :modelValue="ditherAlgorithm"
          @update:modelValue="$emit('update:ditherAlgorithm', $event)"
          :options="filteredDitherAlgorithmsKnobs"
          :instant="instantKnobs"
          v-if="filteredDitherAlgorithmsKnobs.length > 1"
        />
        <slider
          label="Palette"
          :modelValue="paletteId"
          @update:modelValue="$emit('update:paletteId', $event)"
          :options="palettes"
          :instant="instantKnobs"
        />
        <slider
          label="Comparison"
          :modelValue="colorDitherMode"
          @update:modelValue="$emit('update:colorDitherMode', $event)"
          :instant="instantKnobs"
          :options="colorDitherModeKnobs"
        />
      </knobs-group>

      <!-- Filters -->
      <knobs-group>
        <knob-header>Filters</knob-header>
        <slider
          label="Brightness"
          :modelValue="brightness"
          @update:modelValue="$emit('update:brightness', $event)"
          :min="0"
          :step="5"
          :max="200"
          :instant="instantKnobs"
        />
        <slider
          label="Contrast"
          :modelValue="contrast"
          @update:modelValue="$emit('update:contrast', $event)"
          :min="0"
          :step="5"
          :max="200"
          :instant="instantKnobs"
        />
      </knobs-group>

      <!-- Show original -->
      <knobs-group>
        <slider
          :modelValue="showOriginal"
          @update:modelValue="$emit('update:showOriginal', $event)"
          label="Show Original"
        />
      </knobs-group>
    </template>
  </knobs>
</template>

<script>
import Knobs from "@/components/Knobs/Knobs";
import KnobsGroup from "@/components/Knobs/KnobsGroup";
import KnobHeader from "@/components/Knobs/KnobHeader";
import Slider from "@/components/Knobs/Slider";
import ContextBtn from "@/components/ContextBtn";

import AlgorithmModel from "@/components/DitherStudio/generated_output/app/algorithm-model";
import Palette from "@/components/DitherStudio/app/color-palettes";
import ColorDitherModes from "@/components/DitherStudio/shared/color-dither-modes.js";

export default {
  components: {
    Knobs,
    KnobsGroup,
    KnobHeader,
    Slider,
    ContextBtn,
  },
  props: {
    showOriginal: Boolean,
    ditherType: String,
    ditherAlgorithm: Number,
    paletteId: Number,
    colorDitherMode: Number,
    brightness: Number,
    contrast: Number,
    fileName: String,
    contentFit: String,
    hasImage: Boolean,
  },
  data() {
    return {
      ditherAlgorithms: AlgorithmModel.colorDitherAlgorithms,
      ditherKnobs: AlgorithmModel.knobs,
      palettes: Palette.get(),
      contentFitOptions: [
        { value: "cover", label: "Fill" },
        { value: "contain", label: "Contain" },
        { value: "crop", label: "Crop" },
      ],
    };
  },
  methods: {
    uploadImage(event) {
      if (this.blob) {
        URL.revokeObjectURL(this.blob);
      }
      const file = event.target.files[0];
      this.blob = URL.createObjectURL(file);

      this.$emit("uploaded", {
        src: this.blob,
        name: file.name,
      });
    },
  },
  computed: {
    icon() {
      return this.hasImage ? "Ellipsis" : "Plus";
    },
    instantKnobs() {
      return this.ditherType !== "diffusion";
    },
    filteredDitherAlgorithmsKnobs() {
      const algorithm = this.ditherAlgorithms.find(
        (a) => a.id === this.ditherAlgorithm
      );

      if (!algorithm) return;

      return this.ditherAlgorithms
        .filter((a) => a.group === algorithm.group)
        .map((a) => ({
          value: a.id,
          label: a.title,
        }));
    },
    selectedDitherType() {
      return this.ditherKnobs.find((obj) => obj.value === this.ditherType);
    },
    colorDitherModeKnobs() {
      return Array.from(ColorDitherModes, ([name, payload]) => ({
        label: payload.title,
        value: payload.id,
      }));
    },
  },
  watch: {
    ditherType() {
      const newAlgorithm = this.ditherAlgorithms.find(
        (a) => a.group === this.ditherType
      ).id;

      this.$emit("update:ditherAlgorithm", newAlgorithm);
    },
  },
};
</script>

<style scoped></style>
