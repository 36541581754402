<template>
  <div
    class="magazine theme"
    @scroll="onScroll"
    :class="{ visible: visible && !issueActive, [`theme-${theme}`]: theme }"
  >
    <section class="hero" ref="hero">
      <figure ref="figure"></figure>
      <transition name="slide-y-reverse" appear>
        <bottom-strip
          v-show="visible"
          :title="name"
          :bullets="[`${count} issues`, `Every ${cadence}`, `$${price} / issue`]"
        >
          <btn type="secondary">Preview</btn>
          <btn>Subscribe</btn>
        </bottom-strip>
      </transition>
    </section>
    <transition name="fade" duration="50" appear>
      <section class="theme" style="padding: 16rem 0; position: relative" v-show="visible">
        <div class="grid" :style="gridStyle">
          <issue
            :spread="spread"
            v-for="(c, n) in content ? content : count"
            :key="n"
            v-bind="c ? c : null"
            :magazine="name"
            @stacked="onStacked"
          />
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import hammer from "hammerjs";

import Btn from "@/components/Btn";
import BottomStrip from "@/components/BottomStrip";
import Issue from "./Issue";

import getRandomInt from "@/funcs/getRandomInt";

export default {
  components: {
    BottomStrip,
    Btn,
    Issue,
  },
  props: {
    visible: Boolean,
    theme: String,
    name: String,
    cadence: String,
    issues: Number,
    price: Number,
    content: Array,
  },
  data() {
    return {
      scrollY: 0,
      count: (this.content && this.content.length) || this.issues || this.getRandomCount(),
      issueActive: false,
    };
  },

  methods: {
    onStacked(bool) {
      this.issueActive = !bool;
    },
    onScroll() {
      const bounds = this.$refs.hero.getBoundingClientRect();
      this.scrollY = -bounds.top / bounds.height;

      this.$emit("scrollY", this.scrollY);
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);

      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getRandomCount() {
      const arr = [3, 6, 9, 12];
      const index = getRandomInt(0, arr.length - 1);

      return arr[index];
    },
    createGestures() {
      this.manager = new hammer.Manager(this.$refs.figure);

      this.pan = new hammer.Pan({
        preventDefault: true,
        direction: hammer.DIRECTION_VERTICAL,
      });

      this.tap = new hammer.Tap();

      this.manager.add([this.pan, this.tap]);

      this.manager.on("tap", () => {
        this.$emit("zoomOut");
      });
      this.manager.on("panstart", this.onPanStart);
      this.manager.on("panend", this.onPanEnd);
      this.manager.on("pancancel", this.onPanEnd);
      this.manager.on("panmove", this.onPanMove);
    },
    onPanStart(e) {
      this.$emit("panstart");
    },
    onPanMove(e) {
      this.$emit("panmove", e.deltaY / 800);
    },
    onPanEnd(e) {
      const velocity = Math.abs(e.velocityY);

      if (velocity > 0.1) {
        this.$emit("zoomOut");
      } else {
        this.$emit("zoomIn");
      }
    },
  },
  computed: {
    gridStyle() {
      const columns = this.count >= 16 ? 6 : 3;
      const rowGap = this.count >= 16 ? "16rem" : "16rem";
      return {
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        rowGap,
      };
    },
    spread() {
      return this.count >= 16 ? 1 : 3.5;
    },
  },
  mounted() {
    this.createGestures();
  },
};
</script>

<style lang="scss" scoped>
.magazine {
  height: 100vh;
  overflow: hidden;

  &.visible {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hero {
    position: relative;

    &:active {
      cursor: grab;
    }

    figure {
      height: 150vh;
    }
  }
  footer {
    background: var(--color-bg-primary);
    padding: 0 var(--spacing-gutter);
    position: sticky;
    width: 100%;
    height: 44rem;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    aside {
      display: flex;
      column-gap: 8px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 var(--spacing-gutter);
    column-gap: var(--spacing-gutter);
    row-gap: 32rem;
    //overflow: hidden;
    transform: translateZ(0px);
  }

  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}
</style>
