<template>
  <iframe frameBorder="0" />
</template>

<script>
import { debounce } from "lodash";

import beforeMount from "!!raw-loader!./inject/beforeMount";
import mounted from "!!raw-loader!./inject/mounted";

export default {
  props: {
    markup: String,
    css: String,
    script: String,
    cssUrls: {
      type: Array,
      default() {
        return [];
      },
    },
    scriptUrls: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    /**
     * reload iframe
     */
    reload: debounce(function () {
      if (!this.window) return this.delayReload();
      this.iframe.onload = async () => {
        this.updateMarkup();
        this.addLinks();
        this.addStyle();
        await this.addScriptSrc();
        this.addInlineScript(beforeMount);
        this.addInlineScript();
        //this.addInlineScript(mounted);
        this.updateData();
        this.$emit("loaded", this.window);
      };
      this.window.location.reload();
    }, 500),
    /**
     * reload iframe after 100 msec
     */
    delayReload() {
      return setTimeout(() => {
        this.reload();
      }, 100);
    },
    updateMarkup() {
      if (!this.markup) return;
      this.markupElement.innerHTML = this.markup;
    },

    /**
     * Styles
     */

    addLinks() {
      this.cssUrls.forEach((href) => {
        this.head.appendChild(this.createLink(href));
      });
    },
    createLink(link) {
      const ele = document.createElement("link");
      ele.setAttribute("href", link);
      ele.setAttribute("rel", "stylesheet");
      return ele;
    },
    addStyle() {
      if (!this.css) return;
      this.head.appendChild(this.createStyle(this.css));
    },
    createStyle(rules) {
      const ele = document.createElement("style");
      ele.innerHTML = rules;
      return ele;
    },

    /**
     * Script
     */

    async addScriptSrc() {
      for (const src of this.scriptUrls) {
        const script = this.createScript(src);
        await this.insertScript(script);
      }
    },
    addInlineScript(text = this.script) {
      if (!text) return;
      const script = this.createScript();
      script.textContent = text;
      this.markupElement.appendChild(script);
    },
    insertScript(scriptElement) {
      return new Promise((resolve) => {
        this.markupElement.appendChild(scriptElement);
        scriptElement.onload = () => {
          resolve();
        };
      });
    },
    createScript(src) {
      const script = document.createElement("script");
      if (src) script.setAttribute("src", src);
      return script;
    },

    /* Data update */
    updateData() {
      if (!this.window) return;

      const payload = JSON.parse(JSON.stringify(this.data));

      this.window.postMessage({
        event: "datachanged",
        payload,
      });
    },
  },
  computed: {
    iframe() {
      return this.$el;
    },
    window() {
      return this.iframe && this.iframe.contentWindow;
    },
    document() {
      return this.window && this.window.document;
    },
    head() {
      return this.document && this.document.head;
    },
    markupElement() {
      return this.document && this.document.body;
    },
  },
  watch: {
    markup: "updateMarkup",
    css: "reload",
    script: "reload",
    cssUrls: "reload",
    scriptUrls: "reload",
    data: "updateData",
  },
  mounted() {
    this.reload();
  },
};
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
}
</style>
