<template>
  <file v-bind="payload" v-if="payload" native />
</template>

<script>
import db from "@/db";
import { doc, getDoc } from "firebase/firestore";

import File from "@/views/Tool/File";

export default {
  components: {
    File,
  },
  data() {
    return {
      payload: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    db() {
      return doc(db, "files", this.id);
    },
  },
  async mounted() {
    const payload = await getDoc(this.db);

    if (payload.exists()) {
      this.payload = payload.data();
    }
  },
};
</script>
