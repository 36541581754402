import ArrayUtil from "../shared/array-util.js";

/**
 * Note that color hex codes must be in 6 digit format, and not 3 digit format to work properly
 */
function defaultPalettes() {
  return [
    // { label: "---", isCustom: true },
    {
      value: 0,
      label: "4 bit",
      colors: [
        "#000000",
        "#111111",
        "#222222",
        "#333333",
        "#444444",
        "#555555",
        "#666666",
        "#777777",
        "#888888",
        "#999999",
        "#AAAAAA",
        "#BBBBBB",
        "#CCCCCC",
        "#DDDDDD",
        "#EEEEEE",
        "#FFFFFF",
      ],
    },
    {
      value: 1,
      label: "1 bit",
      colors: ["#000000", "#FFFFFF"],
    },
  ];
}

function getPalettes(minimumColorsLength) {
  return padPaletteColorsToMinimumLength(
    defaultPalettes(),
    minimumColorsLength
  );
}

//make sure each palette has at least the minimum number of colors
function padPaletteColorsToMinimumLength(palettes, minimumColorsLength) {
  return palettes.map((palette) => {
    if (!palette.isCustom && palette.colors.length < minimumColorsLength) {
      palette.colors = palette.colors.concat(
        ArrayUtil.create(minimumColorsLength - palette.colors.length, () => {
          return "#000000";
        })
      );
    }
    return palette;
  });
}

function generateUserSavedPaletteTitle(savedPaletteId) {
  return `Saved Palette ${savedPaletteId}`;
}

function generateUserSavedPalette(colors, savedPaletteId) {
  return {
    label: generateUserSavedPaletteTitle(savedPaletteId),
    colors: colors,
    isSaved: true,
  };
}

export default {
  get: getPalettes,
  generateUserSavedPalette,
  padPaletteColorsToMinimumLength,
};
