export default [
  {
    slug: "toiletpaper",
    name: "Toiletpaper",
    theme: "slate",
    cadence: "quarter",
    price: "25",
  },
  {
    slug: "sixteen",
    name: "SIXTEEN",
    theme: "darkgray",
    cadence: "quarter",
    price: "50",
  },
  {
    slug: "time",
    name: "VOODOO",
    theme: "slate",
    cadence: "quarter",
    price: "30",
  },
  // {
  //   slug: "mono",
  //   name: "Mono",
  //   theme: "black",
  // },
  {
    slug: "mono",
    name: "Black & White",
    theme: "black",
    video: true,
    cadence: "year",
    price: "8",
  },
  // {
  //   slug: "terracotta",
  //   name: "Terra Cotta",
  //   theme: "slate",
  //   cadence: "year",
  //   price: "90",
  // },
  {
    slug: "100",
    name: "100 for 10",
    theme: "gray",
    cadence: "month",
    price: "10",
    issues: 16,
    content: [
      {
        number: 111,
        title: "MRKA",
        subheading: "Shake II",
        artworks: [
          require("@/assets/img/magazine/100/issues/111/111-01@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-02@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-03@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-04@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-05@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-06@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-07@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-08@small.jpg"),
          require("@/assets/img/magazine/100/issues/111/111-09@small.jpg"),
        ],
      },
      {
        number: 53,
        title: "Joni Majer",
        subheading: "Making A Point",
        artworks: [
          require("@/assets/img/magazine/100/issues/53/53-01@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-02@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-03@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-04@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-05@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-06@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-07@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-08@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-09@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-10@small.jpg"),
          require("@/assets/img/magazine/100/issues/53/53-11@small.jpg"),
        ],
      },
      {
        number: 85,
        title: "Felix Bareis",
        subheading: "Supergrid",
        artworks: [
          require("@/assets/img/magazine/100/issues/85/85-01@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-02@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-03@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-04@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-05@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-06@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-07@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-08@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-09@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-10@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-11@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-12@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-13@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-14@small.jpg"),
          require("@/assets/img/magazine/100/issues/85/85-15@small.jpg"),
        ],
      },
      {
        number: 99,
        title: "Olaf Unverzart",
        subheading: "Earl y Grey",
        artworks: [
          require("@/assets/img/magazine/100/issues/99/99-01@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-02@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-03@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-04@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-05@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-06@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-07@small.jpg"),
          require("@/assets/img/magazine/100/issues/99/99-08@small.jpg"),
        ],
      },
      {
        number: 17,
        title: "Gui Martinez",
        subheading: "Reworks",
        artworks: [
          require("@/assets/img/magazine/100/issues/17/17-02@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-03@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-04@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-05@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-06@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-07@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-08@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-09@small.jpg"),
          require("@/assets/img/magazine/100/issues/17/17-10@small.jpg"),
        ],
      },
      {
        number: 18,
        title: "Damir Doma",
        subheading: "Collages",
        artworks: [
          require("@/assets/img/magazine/100/issues/18/18-02@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-03@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-04@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-05@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-06@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-07@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-08@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-09@small.jpg"),
          require("@/assets/img/magazine/100/issues/18/18-10@small.jpg"),
        ],
      },
      // {},
      // {},
      // {},
      // {},
      // {},
      // {},
      // {},
      // {},
      // {},
      // {},
      // {},
    ],
  },
];
