<template>
  <div class="knob-header" :style="style">
    <header>
      <slot />
    </header>
    <div class="symbol" @click="$emit('click', $event)" v-if="symbol">
      <sf-symbol :display="symbol" />
    </div>
    <aside v-if="!!$slots.aside">
      <slot name="aside" />
    </aside>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SfSymbol from "@/components/DS/SfSymbol";
export default {
  components: {
    SfSymbol,
  },
  props: {
    symbol: String,
  },
  computed: {
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
    }),
    style() {
      return {
        opacity: this.inIsolation ? 0 : 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.knob-header {
  padding-left: var(--spacing-gutter);
  display: grid;
  grid-template-columns: 1fr 44rem;
  align-items: end;
  padding-bottom: 12rem;
  color: var(--color-fg-secondary);
  height: 44rem;

  header,
  .symbol {
    font-size: 15rem;
    line-height: 17rem;
    /* identical to box height */
    letter-spacing: 0.09em;
    text-transform: uppercase;
  }

  aside,
  .symbol {
    justify-self: end;
    padding-right: 16rem;

    &:hover {
      color: black;
      cursor: default;
    }
  }
}
</style>
