<template>
  <div class="render-image">
    <img :src="src" @load="$emit('mounted')" />
  </div>
</template>

<script>
export default {
  props: {
    src: String,
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
