<template>
  <proportional-box :disabled="zoomed">
    <div class="render-scale" :style="containerStyle" ref="scale">
      <div
        class="view"
        :class="{ transparent: transparent }"
        :style="style"
        @click="toggleZoom"
        :data-cursor="cursor"
      >
        <slot />
      </div>
    </div>
  </proportional-box>
</template>

<script>
import { mapGetters } from "vuex";
import ProportionalBox from "@/components/ProportionalBox";

export default {
  components: {
    ProportionalBox,
  },
  props: {
    position: {
      type: String,
      default: "center",
    },
    transparent: Boolean,
  },
  data() {
    return {
      bounds: {},
      zoomed: false,
    };
  },
  methods: {
    onResize() {
      this.bounds = this.$refs.scale.getBoundingClientRect();
    },
    fitHeight() {},
    toggleZoom(e) {
      e.stopPropagation();
      this.zoomed = !this.zoomed;
      this.$emit("zoom", this.zoomed);
    },
  },
  computed: {
    ...mapGetters(["resolution"]),
    style() {
      return {
        transform: `scale(${this.scale})`,
        transformOrigin: this.transformOrigin,
      };
    },
    containerStyle() {
      let pos = "start";

      if (this.position === "center") pos = "center";
      if (this.position === "right") pos = "end";

      return {
        justifyContent: pos,
        padding: this.zoomed ? 0 : null,
      };
    },
    scale() {
      if (this.zoomed) {
        return this.bounds.width / this.resolution.width;
      }
      return this.bounds.height / this.resolution.height;
    },
    transformOrigin() {
      let x = "0%";

      if (this.position === "center") x = "50%";
      if (this.position === "right") x = "100%";

      return `${x} 0%`;
    },
    cursor() {
      return this.zoomed ? "minus" : "plus";
    },
  },
  mounted() {
    /**
     * Set onResize observer
     */

    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$refs.scale);
  },
  beforeUnmount() {
    this.observer.unobserve(this.$refs.scale);
  },
};
</script>

<style lang="scss" scoped>
.render-scale {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;

  .view {
    transform-origin: 0% 0%;
    background: var(--base-color-canvas);

    &.transparent {
      background: transparent;
    }
  }
}
</style>
