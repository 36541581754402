import faker from "faker";

const getValue = (path) =>
  [faker].concat(path.split(".")).reduce((a, b) => a[b])();

export default function () {
  const types = [
    "commerce.productAdjective",
    "commerce.productMaterial",
    "company.catchPhraseAdjective",
    "music.genre",
    "company.catchPhrase",
  ].sort(() => 0.5 - Math.random());

  return getValue(types[0]);
}
