<template>
  <div class="featured theme theme-black">
    <navigation-bar theme="transparent" subview />
    <!-- Carousel -->
    <section>
      <carousel2 />
    </section>

    <section style="z-index: 100">
      <!--       <grid :columns="4" :count="20" /> -->
      <ImageGrid :columns="computedColumns" :sortBy="sortBy" />
      <FilterBar v-model:columns="columns" @sort="sortBy = $event" />
    </section>

    <!--     <section>
      <frame />
      <img
        class="fill absolute"
        :src="require('@/assets/artworks/bruce-gilden/06.jpg')"
      />
      <footer>
        <label>
          Featured artist
          <span class="capitilize">Bruce Gilden</span>
        </label>
      </footer>
    </section>

    <section>
      <ExhibitionPoster v-bind="testExhibition" />
    </section> -->

    <!-- Carousel -->
    <!--     <section>
      <carousel />
    </section>

    <section>
      <grid />
    </section>

    <section>
      <frame />
      <img
        class="fill absolute"
        :src="require('@/assets/img/jd/ol-pejeta-1.jpg')"
      />
      <footer>
        <label>
          Featured artist
          <span class="capitilize">Jack Davison</span>
        </label>
      </footer>
    </section>

    <section>
      <grid :columns="4" :count="20" />
    </section>

    <section
      class="theme theme-black"
      style="
        padding: 0 var(--spacing-gutter);
        padding-bottom: 400px;
        width: 100vw;
        overflow: hidden;
      "
    >
      <h1 class="display-1">
        AAA<br />
        Technology magazine created by 0s and 1s
      </h1>

      <div style="display: grid; grid-template-columns: repeat(4, 1fr)">
        <issue v-for="n in 4" :key="n" />
      </div>
    </section>

    <section>
      <frame />
      <video
        class="fill absolute"
        :src="require('@/assets/mp4/aaa.mp4')"
        autoplay
        loop
        playsinline
      />
      <footer>
        <label
          >AAA<br />
          Technology magazine created exclusively by 0s and 1s</label
        >
      </footer>
    </section>

    <section class="theme theme-black" style="background-color: #6a6a6a">
      <div style="aspect-ratio: 3/4" />
      <footer>
        <label
          >AAA 4<br />
          A very volatile issue</label
        >
      </footer>
    </section> -->
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import Carousel from "@/components/Store/Carousel";
import Carousel2 from "@/components/Carousel";
import Grid from "@/components/Store/Grid";
import Frame from "@/components/Frame";
import Issue from "@/components/Magazines/Issue";

import ExhibitionPoster from "@/components/ExhibitionPoster";

import ImageGrid from "@/components/ImageGrid";

import FilterBar from "@/components/FilterBar";

import { mapMutations, mapGetters } from "vuex";

import exhibitions from "@/assets/exhibitions";

import map from "@/funcs/map";

export default {
  name: "Store",
  components: {
    NavigationBar,
    Carousel,
    Carousel2,
    Grid,
    Frame,
    Issue,
    ExhibitionPoster,
    ImageGrid,
    FilterBar,
  },
  data() {
    return {
      columns: 0.5,
      sortBy: null,
    };
  },
  methods: {
    ...mapMutations(["setLocalTheme"]),
  },
  computed: {
    testExhibition() {
      return exhibitions.find((e) => e.id === "OBjsYEQwiiyH");
    },
    computedColumns() {
      return Math.round(map(this.columns, 1, 0, 2, 32));
    },
  },
  activated() {
    this.setLocalTheme("black");
  },
  deactivated() {
    this.setLocalTheme(null);
  },
};
</script>

<style lang="scss" scoped>
.featured {
  background-color: var(--color-fill-primary);
  display: flex;
  flex-direction: column;
  //row-gap: 1rem;
  /*   scroll-snap-type: y;
  height: 100vh;
  overflow: auto; */
}

section {
  background-color: var(--color-bg-primary);
  position: relative;
  /*   scroll-snap-align: start; */
}

footer {
  width: 100%;
  padding: var(--spacing-gutter);
  color: white;
  bottom: 0;
  position: sticky;
}

.test {
  background: linear-gradient(to bottom, var(--color-bg-primary), black);

  footer {
    mix-blend-mode: difference;
  }
}
.display-1 {
  font-size: 156rem;
  line-height: 152rem;
  letter-spacing: 156rem * -0.02;
  font-family: HelveticaNowRegular;
  font-weight: normal;
}
</style>
