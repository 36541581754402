<template>
  <div class="filter-strip">
    <!--     <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 11V5H11V11H5V13H11V19H13V13H19V11H13Z"
        fill="black"
      />
    </svg> -->

    <input
      data-cursor="circle"
      class="theme theme-black"
      type="range"
      :min="0"
      :max="1"
      step="0.001"
      :value="columns"
      @input="$emit('update:columns', $event.target.value)"
    />
  </div>
</template>

<script>
import Btn from "@/components/Btn";
export default {
  components: {
    Btn,
  },
  props: {
    columns: {
      type: [Number, String],
      default: 8,
    },
    min: {
      type: Number,
      default: 2,
    },
    max: {
      type: Number,
      default: 32,
    },
  },
  data() {
    const colors = [];

    for (let i = 15; i >= 1; i = i - 4) {
      colors.push(`var(--base-color-${i})`);
    }

    return {
      colors,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-strip {
  height: var(--height-bar);
  width: 100%;
  position: sticky;
  bottom: 0;
  /*   background: var(--color-bg-secondary); */
  padding: 0 var(--spacing-gutter);
  display: grid;
  //grid-template-columns: repeat(12, 1fr);
  justify-content: center;
  align-items: center;
  mix-blend-mode: difference;

  .search {
    grid-column: 3 / span 1;
    opacity: 0.5;
  }

  input {
    grid-column: 3 / span 1;
  }

  .filters {
    grid-column: 4 / -1;
    display: flex;
    column-gap: 8rem;
  }

  .colors {
    display: flex;

    li {
      height: 32rem;
      width: 32rem;
    }
  }
}
</style>
