<template>
  <render :payload="payload" native />
</template>

<script>
import { mapGetters } from "vuex";

import Render from "@/components/Render/Render";

export default {
  components: {
    Render,
  },
  computed: {
    ...mapGetters({
      getRenderedArtwork: "artworks/getRenderedArtwork",
    }),
    payload() {
      return this.getRenderedArtwork(this.$route.params.id) || {};
    },
  },
};
</script>

<style scoped></style>
