<template>
  <div @click="$emit('click')" :class="`icon icon--${name}`" v-html="content" />
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    content() {
      const svg = require(`@/assets/svg/${this.name}.svg`);
      return svg.default;
    },
    style() {
      if (!this.size) return false;

      return {
        width: `${this.width}rem`,
        height: `${this.height}rem`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: flex;
  width: 24rem;
  height: 24rem;
}
</style>

<style lang="scss">
.icon {
  > * {
    pointer-events: none;
  }
}
.icon svg {
  width: 100%;
  height: 100%;
}
</style>
