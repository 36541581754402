<template>
  <div class="index theme">
    <table
      style="position: sticky; top: 44rem"
      cellpadding="0"
      cellspacing="0"
      border="0"
    >
      <thead>
        <tr>
          <th></th>
          <th>Artist</th>
          <th>Name</th>
          <th>Year</th>
          <th>Price</th>
          <th>Type</th>
        </tr>
      </thead>
    </table>
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr v-for="artwork in artworks" :key="artwork.id">
          <td class="artwork">
            <issue
              data-cursor="circle"
              v-if="artwork.type === 'series'"
              v-bind="artwork"
              :spread="1"
              :title="artwork.artist"
              :subheading="artwork.name"
            />
            <img v-else :src="artwork.image.s" data-cursor="circle" />
            <img
              v-if="artwork.type === 'code' && artwork.url"
              :src="artwork.url"
              style="object-fit: contain"
              data-cursor="circle"
            />
          </td>
          <td class="capitilize">{{ artwork.artist }}</td>
          <td>{{ artwork.name }}</td>
          <td>{{ getRandomDate() }}</td>
          <td>$50.00</td>
          <td>{{ getType(artwork.type) }}</td>
          <!--           <td>
            <ul class="colors" v-if="artwork.palette">
              <li
                v-for="color in artwork.palette"
                :key="color + artwork.id"
                :style="{
                  backgroundColor: getColor(color),
                }"
              />
            </ul>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Issue from "@/components/Magazines/Issue";
export default {
  components: {
    Issue,
  },
  props: {
    artworks: Array,
  },
  methods: {
    getColor(colors) {
      if (!colors) return;
      return `rgb(${colors.join()})`;
    },
    getType(type) {
      if (type === "series") return "Series";
      if (type === "magazine") return "Magazine";
      return "Artwork";
    },
    getRandomDate(from = new Date("1/1/1999"), to = new Date("1/1/22")) {
      from = from.getTime();
      to = to.getTime();
      const date = new Date(from + Math.random() * (to - from));
      return date.getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  thead {
    background-color: var(--color-bg-secondary);
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
  th,
  td {
    padding: 0rem 16rem;
    height: 44rem;
    vertical-align: middle;
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
  }
  .artwork {
    //min-height: 44rem;

    > * {
      height: 44rem;
    }
  }
  td {
    height: auto;
    text-transform: none;

    &.capitilize {
      text-transform: uppercase;
    }
  }

  tbody tr:hover {
    background-color: var(--color-bg-inverse);
    color: var(--color-fg-inverse);
  }
  .colors {
    display: flex;

    li {
      height: 32rem;
      width: 32rem;
    }
  }
}
</style>
