<template>
  <div class="artwork" :class="{ transparent: transparent }">
    <div class="canvas">
      <!-- Image -->
      <template v-if="image">
        <transition name="fade">
          <img :src="image" @load="isLoaded = true" v-show="isLoaded" />
        </transition>
      </template>

      <!-- Slot -->
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transparent: Boolean,
    image: String,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.artwork {
  width: 100%;
  min-height: 0;
  position: relative;
  aspect-ratio: 3/4;

  &:not(.transparent) {
    background: var(--base-color-canvas);
  }

  .canvas {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    mix-blend-mode: multiply;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
</style>
