<template>
  <div class="model" :style="{ opacity: ready ? 1 : 0 }" />
</template>

<script>
import {
  PerspectiveCamera,
  MeshPhysicalMaterial,
  Scene,
  EquirectangularReflectionMapping,
  WebGLRenderer,
  ACESFilmicToneMapping,
  sRGBEncoding,
  Vector3,
  Color,
  Box3,
  BoxGeometry,
  MathUtils,
  TextureLoader,
  PMREMGenerator,
  RepeatWrapping,
  BufferAttribute,
} from "three";

import {
  EffectComposer,
  EffectPass,
  RenderPass,
  BlendFunction,
  ChromaticAberrationEffect,
  TiltShiftEffect,
  NoiseEffect,
  SMAAEffect,
  BrightnessContrastEffect,
} from "postprocessing";

import EInkMaterial from "./material";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

import gsap from "gsap";

export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    orbitElement: Element,
    textures: {
      type: Array,
      required: true,
    },
    initialName: String,
    background: {
      type: String,
      default: "#fff",
    },
    initalTextureIndex: Number,
  },
  data() {
    return {
      size: { x: 0, y: 0, z: 0 },
      cameraZ: null,
      activeTexture: 0,
      textureCount: 40,
      width: 0,
      height: 0,
      deviceScale: 1,
      params: {
        pauseDuration: 1000,
        transitionDuration: 1000,
        background: "#FFF",
        autoPlay: false,
        autoRotate: false,
        autoRotateSpeed: 2,
      },
      ready: false,
    };
  },
  methods: {
    changeDeviceScale(size, duration = 0.75) {
      const scale = size === "SMALL" ? 13.3 / 42 : 1;

      gsap.to(this.camera.position, {
        duration,
        ease: "back.out(.5)",
        x: 0,
        y: 0,
        z: this.cameraZ / scale,
        onUpdate: () => {
          this.controls.update();
        },
        onComplete: () => {
          this.deviceScale = scale;
        },
      });
    },
    fitObjectToViewport(camera, object, offset = 1.0, orbitControls) {
      const boundingBox = new Box3();
      boundingBox.setFromObject(object);

      var middle = new Vector3();
      var size = new Vector3();
      boundingBox.getSize(size);

      this.size = size;

      const fov = camera.fov * (Math.PI / 180);
      const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
      let dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
      let dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
      let cameraZ = Math.max(dx, dy) * (offset + 0.05);
      this.cameraZ = cameraZ;

      // offset the camera, if desired (to avoid filling the whole canvas)
      if (offset !== undefined && offset !== 0) this.cameraZ *= offset;
      camera.position.set(0, 0, this.cameraZ / this.deviceScale);

      // set the far plane of the camera so that it easily encompasses the whole object
      const minZ = boundingBox.min.z;
      const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

      this.size = size;

      camera.far = cameraToFarEdge * 3;

      camera.updateProjectionMatrix();

      object.rotation.y = MathUtils.degToRad(0);
      object.rotation.x = MathUtils.degToRad(0);
      object.rotation.z = MathUtils.degToRad(0);

      if (orbitControls !== undefined) {
        // set camera to rotate around the center
        orbitControls.target = new Vector3(0, 0, 0);

        // prevent camera from zooming out far enough to create far plane cutoff
        orbitControls.maxDistance = cameraToFarEdge * 2;
      }
    },
    init() {
      const w = this.$el.offsetWidth;
      const h = this.$el.offsetHeight;

      this.camera = new PerspectiveCamera(25, w / h, 0.25, 400);

      this.renderer = new WebGLRenderer({ antialias: false, alpha: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(w, h);
      this.renderer.toneMapping = ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 0.35;
      this.renderer.outputEncoding = sRGBEncoding;
      this.$el.appendChild(this.renderer.domElement);

      this.scene = new Scene();

      //this.scene.background = new Color(0xd6d6d6);

      new RGBELoader().load(
        require("./assets/hdr/belfast_sunset_puresky_1k.hdr"),
        //require("./assets/brown_photostudio_06_2k.hdr"),
        (texture) => {
          texture.mapping = EquirectangularReflectionMapping;

          //this.scene.background = texture;
          this.scene.environment = texture;

          this.texture = texture;

          this.controls = new OrbitControls(this.camera, this.$el);
          this.controls.target.set(0, 0, 0);
          this.controls.enableZoom = false;
          this.controls.autoRotate = this.params.autoRotate;
          //this.controls.autoRotateSpeed *= -1;
          this.controls.enableDamping = true;
          // }

          // Post processing
          this.composer = new EffectComposer(this.renderer);
          this.composer.addPass(new RenderPass(this.scene, this.camera));

          this.caEffect = new ChromaticAberrationEffect({
            modulationOffset: 3.5,
            radialModulation: false,
          });

          this.noiseEffect = new NoiseEffect({
            blendFunction: BlendFunction.COLOR_BURN,
          });

          this.noiseEffect.blendMode.opacity.value = 0.2;

          this.tiltShift = new TiltShiftEffect({
            resolutionScale: 2,
            offset: 0.0,
            rotation: 0.67,
            focusArea: 0.5,
            feather: 0.6,
            kernelSize: 4,
          });

          //this.composer.addPass(new EffectPass(this.camera, new SMAAEffect()));
          this.composer.addPass(new EffectPass(this.camera, this.tiltShift));
          this.composer.addPass(new EffectPass(this.camera, this.noiseEffect));
          this.composer.addPass(new EffectPass(this.camera, this.caEffect));

          // model
          new OBJLoader().load(
            require("./assets/monolit_l_frame_20230413.obj"),
            async (object) => {
              this.object = object;
              this.mesh = object.children;

              const box = new Box3().setFromObject(object);
              const size = box.getSize(new Vector3()).length();
              const center = box.getCenter(new Vector3());

              let envMap = new PMREMGenerator(this.renderer).fromCubemap(
                this.texture
              );

              const normals = new TextureLoader().load(
                require("./assets/metal_normal_gl.jpg"),
                () => {}
              );
              const colors = new TextureLoader().load(
                require("./assets/metal_color_3.jpg"),
                () => {}
              );

              normals.wrapT = RepeatWrapping;
              colors.wrapT = RepeatWrapping;
              normals.wrapS = RepeatWrapping;
              colors.wrapS = RepeatWrapping;

              object.children[0].material = new MeshPhysicalMaterial({
                clearcoat: 0.5,
                metalness: 1,
                reflectivity: 1,
                roughness: 0.2,
                color: new Color("#fff"),
                map: colors,
                normalMap: normals,
              });

              this.createTextures();

              this.camera.near = size / 10;
              this.camera.far = size * 100;
              this.camera.updateProjectionMatrix();

              object.position.x += object.position.x - center.x;
              object.position.y += object.position.y - center.y;
              object.position.z += object.position.z - center.z;

              this.scene.background = new Color(this.background);
              //this.scene.background = new Color("#fff");

              this.fitObjectToViewport(
                this.camera,
                object,
                undefined,
                this.controls
              );

              if (this.initialName === "MONOLIT mini") {
                this.changeDeviceScale("mini", 0);
              }

              if (this.initalTextureIndex > 0) {
                this.transition.transitionTo(this.initalTextureIndex, 0);
              }

              this.scene.add(object);

              this.start();
            }
          );
        }
      );

      this.observer = new ResizeObserver(this.onResize);
      this.observer.observe(this.$el);
    },
    stop() {
      if (this.raf) {
        cancelAnimationFrame(this.raf);
        this.raf = null;
      }
    },
    start() {
      if (!this.raf) this.raf = requestAnimationFrame(this.render);
    },
    createTextures() {
      //       for (let i = 1; i <= this.textureCount; i++) {
      //         const url = require(`./assets/img/${i}.jpg`);
      //         this.textures.push(url);
      //       }
      //
      //       this.textures.sort(() => Math.random() - 0.5);

      const geometry = new BoxGeometry(1, 1, 1);
      const boundingBox = new Box3().setFromBufferAttribute(
        this.mesh[1].geometry.attributes.position
      );

      const width = boundingBox.max.x - boundingBox.min.x;
      const height = boundingBox.max.y - boundingBox.min.y;

      // Create new UV set
      this.setMeshUVs(this.mesh[1], boundingBox);

      this.transition = new EInkMaterial(
        this.textures,
        { width, height },
        () => {
          this.ready = true;
        }
      );

      this.mesh[1].material = this.transition.material;
    },
    setMeshUVs(mesh, boundingBox) {
      const geometry = mesh.geometry;
      const uvs = [];

      // Assuming the mesh is a plane, iterate through its vertices and calculate UV coordinates
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        const x = geometry.attributes.position.getX(i);
        const y = geometry.attributes.position.getY(i);
        const z = geometry.attributes.position.getZ(i);

        // Normalize the vertex coordinates to the range [0, 1]
        const u =
          (x - boundingBox.min.x) / (boundingBox.max.x - boundingBox.min.x);
        const v =
          (y - boundingBox.min.y) / (boundingBox.max.y - boundingBox.min.y);

        uvs.push(u, v);
      }

      // Create a new BufferAttribute for the UVs and set it to the geometry
      const uvAttribute = new BufferAttribute(new Float32Array(uvs), 2);
      geometry.setAttribute("uv", uvAttribute);
    },
    render() {
      this.raf = requestAnimationFrame(this.render);

      if (this.transition && this.params.autoPlay) {
        this.transition.update();
      }

      if (this.controls) {
        this.controls.update();
      }

      if (this.composer) {
        this.composer.render();
      }
    },
    onResize() {
      const w = this.$el.offsetWidth;
      const h = this.$el.offsetHeight;
      this.width = w;
      this.height = h;
      this.camera.aspect = w / h;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(w, h);
      if (this.object)
        this.fitObjectToViewport(
          this.camera,
          this.object,
          undefined,
          this.controls
        );
    },
  },
  watch: {
    background() {
      this.scene.background = new Color(this.background);

      this.renderer.toneMappingExposure = this.background ? 0.05 : 0.35;
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.stop();
    if (!this.renderer) return;
    this.renderer.forceContextLoss();
    this.renderer.context = null;
    this.renderer.domElement = null;
    this.renderer = null;

    this.observer.unobserve(this.$el);
  },
};
</script>

<style scoped>
.model {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: grayscale(0.95) contrast(1);
  cursor: grabbing;
  transition: opacity var(--transition-duration-default)
    var(--transition-ease-drawer);
}
</style>
