<template>
  <div class="dither-canvas" :class="{ [contentFit]: contentFit }">
    <canvas ref="sourceCanvasOutput" v-show="showOriginal"></canvas>
    <canvas
      v-show="!showOriginal"
      ref="transformCanvasOutput"
      style="image-rendering: pixelated"
    ></canvas>

    <color-dither-section
      ref="colorDitherSection"
      v-show="isImageLoaded"
      :dither-algorithm="ditherAlgorithm"
      :palette-id="paletteId"
      :color-dither-mode="colorDitherMode"
      @request-worker="onWorkerRequested"
      :request-canvases="requestPermissionCallbackBuilder(onCanvasesRequested)"
      :request-display-transformed-image="
        requestPermissionCallbackBuilder(onRequestDisplayTransformedImage)
      "
      :is-webgl-enabled="isWebglEnabled"
      :isColorPickerLivePreviewEnabled="true"
      :is-live-preview-enabled="isLivePreviewEnabled"
      :dither-algorithms="colorDitherAlgorithms"
    />
  </div>
</template>

<script>
import Constants from "../../generated_output/app/constants.js";
import AlgorithmModel from "../../generated_output/app/algorithm-model.js";
import Canvas from "../canvas.js";
import WorkerHeaders from "../../shared/worker-headers.js";
import WorkerUtil from "../worker-util.js";
import WebGl from "../webgl.js";
import WebGlSmoothing from "../webgl-smoothing.js";
import WebGlBilateralFilter from "../webgl-bilateral-filter.js";
import WebGlCanvasFilters from "../webgl-canvas-filters.js";
import ImageFiltersModel from "../image-filters-model.js";

import Fs from "../fs";

import ColorDitherSection from "./color-dither.vue";

//webworker stuff
let imageId = 0;
let ditherWorkers;

//canvases
let sourceCanvas;
let originalImageCanvas;
let transformCanvas;
let ditherOutputCanvas;
let transformCanvasWebGl;
let sourceCanvasOutput;
let transformCanvasOutput;

let sourceWebglTexture;
let ditherOutputWebglTexture;

//used to keep track of which tabs have loaded a new image to them, after an image is loaded
//this is because originally, only the active tab when an image is loaded will register it as new
const tabsThatHaveSeenImageSet = new Set();

export default {
  name: "DitherCanvas",
  components: {
    ColorDitherSection,
  },
  props: {
    brightness: {
      type: Number,
      default: 100,
    },
    contrast: {
      type: Number,
      default: 100,
    },
    ditherAlgorithm: {
      type: Number,
      default: 77,
    },
    paletteId: {
      type: Number,
      default: 0,
    },
    colorDitherMode: {
      type: Number,
      default: 0,
    },
    contentFit: {
      type: String,
      default: "cover",
    },
    showOriginal: Boolean,
    src: String,
  },
  data() {
    return {
      colorDitherAlgorithms: AlgorithmModel.colorDitherAlgorithms,
      loadedImage: null,
      isLivePreviewEnabled: true,
      automaticallyResizeLargeImages: true,
      isWebglSupported: false,
      isWebglEnabled: false,
      isWebglHighpFloatSupported: false,
      areCanvasFiltersSupported: false, //required for increasing image contrast and saturation
    };
  },
  methods: {
    /*
     * Loading and saving image stuff
     */
    save() {
      var link = document.createElement("a");
      link.download = "monolit.png";
      link.href = this.$refs.transformCanvasOutput.toDataURL();
      link.click();
    },
    async loadImageUrl() {
      if (!this.src) return;
      const { image, file } = await Fs.openImageUrl(this.src);
      console.log("loadImageUrl", image, file);
      this.loadImage(image, file);
    },
    loadImage(image, file) {
      const loadedImage = {
        width: image.width,
        height: image.height,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      };

      //resize large images if necessary
      const largeImageDimensionThreshold = 2560;
      const largestImageDimension = Math.max(loadedImage.width, loadedImage.height);
      if (
        this.automaticallyResizeLargeImages &&
        largestImageDimension > largeImageDimensionThreshold
      ) {
        const resizePercentage = largeImageDimensionThreshold / largestImageDimension;
        Canvas.loadImage(originalImageCanvas, image, resizePercentage);
        loadedImage.width = originalImageCanvas.canvas.width;
        loadedImage.height = originalImageCanvas.canvas.height;
      } else {
        Canvas.loadImage(originalImageCanvas, image);
      }
      originalImageCanvas.context.drawImage(originalImageCanvas.canvas, 0, 0);
      //finish loading image
      this.loadedImage = loadedImage;

      this.imageFiltersBeforeDitherChanged(false);
      // tabsThatHaveSeenImageSet.clear();
      // tabsThatHaveSeenImageSet.add(this.activeDitherComponentId);
      this.activeDitherSection.imageLoaded(this.imageHeader, true);

      this.$emit("loaded");
    },
    imageFiltersBeforeDitherChanged(notifyDitherSection = true) {
      //apply filters
      this.imagePixelationChanged();
      if (this.isWebglEnabled) {
        //reset source texture
        transformCanvasWebGl.gl.deleteTexture(sourceWebglTexture);
        sourceWebglTexture = WebGl.createAndLoadTextureFromCanvas(
          transformCanvasWebGl.gl,
          sourceCanvas.canvas
        );
        if (!this.areCanvasFiltersSupported) {
          this.applyWebGlCanvasFilters();
        }
      }

      //load image into the webworkers
      imageId = WorkerUtil.generateImageId(imageId);
      const buffer = Canvas.createSharedImageBuffer(sourceCanvas);
      const ditherWorkerHeader = WorkerUtil.createLoadImageHeader(
        imageId,
        this.imageHeader.width,
        this.imageHeader.height
      );
      ditherWorkers.forEach((ditherWorker) => {
        //copy image to web workers
        ditherWorker.postMessage(ditherWorkerHeader);
        ditherWorker.postMessage(buffer);
      });
      if (notifyDitherSection) {
        this.activeDitherSection.imageLoaded(this.imageHeader);
      }
    },
    applyWebGlCanvasFilters() {
      const filters = this.imageFiltersRaw;
      //don't do anything if filters are all invalid or at defaults
      if (Object.keys(filters).length < 1) {
        return;
      }
      const imageHeader = this.imageHeader;
      WebGlCanvasFilters.filter(
        transformCanvasWebGl.gl,
        sourceWebglTexture,
        imageHeader.width,
        imageHeader.height,
        filters.contrast,
        filters.saturation,
        filters.brightness,
        filters.hue
      );
      sourceCanvas.context.drawImage(transformCanvasWebGl.canvas, 0, 0);
      transformCanvasWebGl.gl.deleteTexture(sourceWebglTexture);
      sourceWebglTexture = WebGl.createAndLoadTextureFromCanvas(
        transformCanvasWebGl.gl,
        sourceCanvas.canvas
      );
    },
    imagePixelationChanged() {
      const imageHeader = this.imageHeader;
      const scaleAmount = 1;
      const filters = this.areCanvasFiltersSupported ? this.imageFilters : "";
      Canvas.copy(originalImageCanvas, sourceCanvas, scaleAmount, filters);
      Canvas.copy(originalImageCanvas, transformCanvas, scaleAmount, filters);

      if (this.isWebglSupported) {
        transformCanvasWebGl.canvas.width = imageHeader.width;
        transformCanvasWebGl.canvas.height = imageHeader.height;
        transformCanvasWebGl.gl.deleteTexture(sourceWebglTexture);
        sourceWebglTexture = WebGl.createAndLoadTextureFromCanvas(
          transformCanvasWebGl.gl,
          sourceCanvas.canvas
        );
      }
      //we have to unset hue-rotate here, otherwise it will remain set for some reason even though other filters reset
      //sourceCanvas filter needs to be reset after webgl texture is created, otherwise results of the filter won't be saved in the texture
      if (this.areCanvasFiltersSupported && transformCanvas.context.filter) {
        transformCanvas.context.filter = "hue-rotate(0deg)";
        sourceCanvas.context.filter = "hue-rotate(0deg)";
      }
    },

    /*
     * Web workers
     */
    workerMessageReceivedDispatcher(e) {
      console.log("workerMessageReceivedDispatcher");
      const messageData = e.data;
      const messageFull = new Uint8Array(messageData);
      //get image id and messageTypeId from start of buffer
      const messageImageId = messageFull[0];
      //check for race condition where worker was working on old image
      if (messageImageId !== imageId) {
        return;
      }
      const messageTypeId = messageFull[1];
      //rest of the buffer is the actual pixel data
      const pixels = messageFull.subarray(2);
      switch (messageTypeId) {
        default:
          this.$refs.colorDitherSection.ditherWorkerMessageReceivedDispatcher(
            messageTypeId,
            pixels
          );
          break;
      }
    },
    onRequestDisplayTransformedImage(componentId) {
      if (this.isWebglEnabled) {
        //copy output to ditherOutputCanvas so we don't lose it for post filter dithers
        Canvas.copy(transformCanvas, ditherOutputCanvas);
      }
      const scaleAmount = 1;

      Canvas.copy(sourceCanvas, sourceCanvasOutput, scaleAmount);
      Canvas.copy(this.transformedSourceCanvas, transformCanvasOutput, scaleAmount);
      // if (componentId === this.activeDitherComponentId) {
      // }
    },
    onCanvasesRequested(callback) {
      callback(transformCanvas, transformCanvasWebGl, sourceWebglTexture);
    },
    //used to build callback functions for onRequestDisplayTransformedImage and onCanvasesRequested
    //so that requester is not aware of, and thus cannot change their componentId
    requestPermissionCallbackBuilder(callback) {
      return (...args) => {
        callback(...args);
      };
    },
    onWorkerRequested(callback) {
      console.log("onWorkerRequested");
      let worker = ditherWorkers.getNextWorker();
      callback(worker);
    },
    onOpenImageError(errorMessage) {
      console.log(errorMessage);
    },
  },
  computed: {
    //the source canvas for transformed (dithered and filtered image)
    //before zoom
    transformedSourceCanvas() {
      return transformCanvas;
    },
    isImageLoaded() {
      return this.loadedImage != null;
    },
    imageHeader() {
      if (!this.isImageLoaded) {
        return null;
      }
      let width = Math.ceil(this.loadedImage.width);
      let height = Math.ceil(this.loadedImage.height);
      return {
        width: width,
        height: height,
        //filter values are used for color dither for optimize palette results caching
        //doesn't need the value of image smoothing after, since this happens after the dither completes
        // contrast: this.selectedImageContrastIndex,
        // saturation: this.selectedImageSaturationIndex,
      };
    },
    activeDitherSection() {
      return this.$refs.colorDitherSection;
    },
    imageFiltersRaw() {
      const filters = {};
      const contrast = this.contrast;
      const brightness = this.brightness;

      if (contrast !== 100) {
        filters["contrast"] = contrast;
      }
      if (brightness !== 100) {
        filters["brightness"] = brightness;
      }
      return filters;
    },
    imageFilters() {
      const filtersRaw = this.imageFiltersRaw;
      const filters = [];
      if ("contrast" in filtersRaw) {
        filters.push(`contrast(${filtersRaw.contrast}%)`);
      }
      if ("brightness" in filtersRaw) {
        filters.push(`brightness(${filtersRaw.brightness}%)`);
      }
      // if ("saturation" in filtersRaw) {
      //   filters.push(`saturate(${filtersRaw.saturation}%)`);
      // }
      // if ("hue" in filtersRaw) {
      //   filters.push(`hue-rotate(${filtersRaw.hue}deg)`);
      // }
      return filters.join(" ");
    },
    areCanvasFiltersEnabled() {
      return this.areCanvasFiltersSupported || this.isWebglEnabled;
    },
  },
  watch: {
    imageFilters() {
      if (this.isImageLoaded) {
        this.imageFiltersBeforeDitherChanged();
      }
    },
    src: "loadImageUrl",
  },
  created() {
    WorkerUtil.getDitherWorkers(Constants.ditherWorkerUrl).then((workers) => {
      ditherWorkers = workers;
      ditherWorkers.forEach((ditherWorker) => {
        ditherWorker.onmessage = this.workerMessageReceivedDispatcher;
      });
    });
    originalImageCanvas = Canvas.create();
    sourceCanvas = Canvas.create();
    transformCanvas = Canvas.create();
    transformCanvasWebGl = Canvas.createWebgl();
    ditherOutputCanvas = Canvas.create();
    this.areCanvasFiltersSupported = Canvas.areCanvasFiltersSupported(originalImageCanvas);
    //check for webgl support
    if (transformCanvasWebGl.gl) {
      this.isWebglSupported = true;
      this.isWebglHighpFloatSupported = transformCanvasWebGl.supportsHighFloatPrecision;
    }

    //remove webgl algorithms requiring high precision ints (if necessary)
    if (!transformCanvasWebGl.supportsHighIntPrecision) {
      const removeUnsupportedWebGl = (algorithm) => {
        if (algorithm.requiresHighPrecisionInt) {
          algorithm.webGlFunc = null;
        }
        return algorithm;
      };
      this.colorDitherAlgorithms = this.colorDitherAlgorithms.map(removeUnsupportedWebGl);
    }
  },
  async mounted() {
    const refs = this.$refs;
    sourceCanvasOutput = Canvas.create(refs.sourceCanvasOutput);
    transformCanvasOutput = Canvas.create(refs.transformCanvasOutput);

    this.isWebglEnabled = this.isWebglSupported;

    //have to set alertsContainer property here, since it does not exist yet in created hook
    if (this.isWebglSupported) {
      //console.log("maxTextureSize", transformCanvasWebGl.maxTextureSize);
    }

    await this.loadImageUrl();

    setTimeout(() => {
      //this.save();
    }, 2000);
  },
  beforeUnmount() {},
  activated() {
    console.log("activated");
  },
};
</script>

<style lang="scss" scoped>
.dither-canvas {
  position: absolute;
  width: 100%;
  height: 100%;

  &.contain {
    canvas {
      object-fit: contain;
    }
  }
  &.cover {
    canvas {
      object-fit: cover;
    }
  }

  &.contain,
  &.cover {
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
