<template>
  <div class="grid-overlay" :style="style">
    <div class="col" v-for="n in columns" :key="n"></div>
    <template v-if="margin">
      <div
        class="margin"
        v-for="side in ['top', 'bottom', 'left', 'right']"
        :key="side"
        :style="setMarginStyle(side)"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    margin: Boolean,
    columns: {
      type: Number,
      default: 12,
    },
    marginSize: {
      type: Number,
      default: 116,
    },
  },
  methods: {
    setMarginStyle(side) {
      let prop;
      if (side === "left" || side === "right") prop = "height";
      if (side === "top" || side === "bottom") prop = "width";

      return {
        [`border-${side}`]: "2px dashed",
        [side]: `${this.marginSize}px`,
        [prop]: "100%",
      };
    },
  },
  computed: {
    style() {
      const margin = this.margin ? this.marginSize : 0;
      return {
        padding: `${margin}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-overlay {
  display: grid;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  mix-blend-mode: difference;
  color: white;
  opacity: 0.5;

  .col {
    border-left: 2px dashed;

    &:first-child {
      border-left: 0px;
    }
  }
  .margin {
    position: absolute;
  }
}
</style>
