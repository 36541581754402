export default [
  { id: "UGPIDSAEtkOI", name: "Marcus Schaefer" },
  { id: "sbCTgXkrJvsu", name: "Franco Grignani" },
  { id: "NSpvOEKGRvSn", name: "Jack Davison" },
  { id: "iYFyN_5yuwE_", name: "Ryan Carl" },
  { id: "eI8VDj7n3Dg6", name: "Bruce Gilden" },
  { id: "TFNAad6Qb6lP", name: "Kobayashi Ikki" },
  { id: "CAYD-CV3nUCm", name: "Tatsuo Suzuki" },
  { id: "KtY1vk_kzQ6Q", name: "loackme" },
  { id: "oYT8f_pQkR5Q", name: "Fabian Fohrer" },
  { id: "dsH2SrUaeIjf", name: "Matt DesLauriers" },
  { id: "b7c10uSdpak_", name: "Julia Johnson" },
  { id: "BNxFg9LuOkg0", name: "Gareth McConnell" },
  { id: "OljjB3W2-UmU", name: "Jakob Henner" },
  { id: "Jc5mry_mbXGp", name: "Chadwick Tyler" },
  { id: "GS5x_NWUWe7e", name: "Drew Vickers" },
  { id: "HFSrgKoZVfZJ", name: "Kalen Hollomon" },
  { id: "GvMHxhiJ_skj", name: "Werner Bischof" },
  { id: "DsJHcIjjD_bo", name: "Ukendt Kunstner" },
  { id: "MDILctjuiLbo", name: "Mark McKnigh" },
];
