<template>
  <div class="render-code">
    <sandbox
      :markup="markup"
      :css="computedCss"
      :script="script"
      :scriptUrls="scriptUrls"
      :data="data"
    />
  </div>
</template>

<script>
import Sandbox from "@/components/Sandbox/Sandbox";

import db from "@/db";
import { doc, onSnapshot } from "firebase/firestore";

export default {
  components: {
    Sandbox,
  },
  props: {
    id: String,
    schema: Object,
    markup: String,
    script: String,
    css: String,
    dependencies: Array,
    scriptUrls: Array,
    cssUrls: Array,
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    computedCss() {
      const reset = "body {margin: 0px; padding: 0px}";

      if (!this.css) return reset;
      return `${reset} ${this.css}`;
    },
  },
  methods: {
    onUpdate(document) {
      const data = document.data();
      this.data = data.params;
    },
  },
  mounted() {
    this.unsubscribe = onSnapshot(doc(db, "artworks", this.id), this.onUpdate);
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
.render-code {
  display: contents;
}
</style>
