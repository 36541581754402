<template>
  <footer
    class="bottom-strip"
    :style="{ position: position }"
    :class="{ transparent: transparent }"
  >
    <header>
      <label>
        <span class="capitilize">{{ title }}</span>
        <span v-if="subheading">{{ subheading }}</span>
      </label>
      <ul v-if="bullets">
        <li v-for="(bullet, n) in bullets" :key="n">
          <span class="bullet">●</span> {{ bullet }}
        </li>
      </ul>
    </header>
    <aside>
      <slot />
    </aside>
  </footer>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Artist name",
    },
    subheading: String,
    bullets: Array,
    position: {
      type: String,
      default: "sticky",
    },
    transparent: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.bottom-strip {
  background: var(--color-bg-primary);
  padding: 0 var(--spacing-gutter);
  position: sticky;
  width: 100%;
  height: 44rem;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.transparent {
    background: transparent;
  }

  header,
  aside {
    display: flex;
    column-gap: 8rem;
  }

  aside {
    column-gap: 24rem;
  }

  header {
    label {
      display: flex;
      column-gap: 10rem;
    }

    ul {
      display: flex;
      column-gap: 8rem;

      li {
        display: flex;
        align-items: center;
        column-gap: 4rem;
      }

      .bullet {
        width: 20rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
