<template>
  <div class="wifi" @click="showLabel = !showLabel">
    <svg width="19rem" height="16rem" viewBox="0 0 19 16" :title="`RSSI: -${rssi} dBm`">
      <path v-for="(d, n) in paths" :key="n" :d="d" :fill="setColor(n)" />
    </svg>
    <span v-if="showLabel">RSSI: -{{ rssi }} dBm</span>
  </div>
</template>

<script>
import { scaleLinear, interpolateRound } from "d3";
export default {
  props: {
    rssi: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    healthyBars() {
      const scale = scaleLinear()
        .domain([0, 50, 60, 70, 100])
        .range([3, 3, 2, 1, 1])
        .interpolate(interpolateRound)
        .clamp(true);
      return Math.round(scale(this.rssi));
    },
  },
  methods: {
    setColor(n) {
      return n < this.healthyBars
        ? "var(--color-fill-primary)"
        : "var(--color-fill-secondary)";
    },
  },
  data() {
    return {
      showLabel: false,
      paths: [
        "M5.98215 10.4571C6.44412 9.99517 6.99256 9.62872 7.59615 9.3787C8.19975 9.12868 8.84668 9 9.5 9C10.1533 9 10.8003 9.12868 11.4039 9.3787C12.0074 9.62872 12.5559 9.99517 13.0179 10.4571L9.5 13.975L5.98215 10.4571Z",
        "M14.435 9.0208C11.7014 6.28713 7.26922 6.28713 4.53555 9.0208L3.12134 7.60659C6.63606 4.09187 12.3345 4.09187 15.8493 7.60659L14.435 9.0208Z",
        "M17.2635 6.19238C12.9677 1.89661 6.00289 1.89661 1.70712 6.19238L0.292908 4.77816C5.36972 -0.298653 13.6009 -0.298653 18.6777 4.77816L17.2635 6.19238Z",

        // "M11.3137 13.8787C10.1421 12.7071 8.24264 12.7071 7.07107 13.8787L5.65685 12.4645C7.60948 10.5118 10.7753 10.5118 12.7279 12.4645L11.3137 13.8787Z",
        // "M14.1421 11.0503C11.4085 8.31658 6.97631 8.31658 4.24264 11.0503L2.82843 9.63604C6.34315 6.12132 12.0416 6.12132 15.5563 9.63604L14.1421 11.0503Z",
        // "M16.9706 8.22183C12.6748 3.92606 5.70998 3.92606 1.41421 8.22183L0 6.80761C5.07682 1.7308 13.308 1.7308 18.3848 6.80761L16.9706 8.22183Z",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wifi {
  display: flex;
  align-items: center;
  column-gap: 8rem;
}
</style>
