import script from "!!raw-loader!./sketch.js";

export default {
  name: "weave2",
  version: "0.0.0",
  schema: {
    shapeAngle: {
      type: "number",
      default: 0,
      min: 0,
      max: 360,
    },
    bg: {
      type: "color",
      default: "#ffffff",
    },
    flowCellSize: {
      type: Number,
      default: 8,
      min: 4,
      max: 12,
      step: 1,
    },
  },
  script,
  style: "",
  cssUrls: [],
  scriptUrls: ["https://cdnjs.cloudflare.com/ajax/libs/p5.js/0.5.10/p5.js"],
  dependencies: ["p5", "three.js"],
};
