<template>
  <div class="magazine-3d"></div>
</template>

<script>
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  BoxGeometry,
  MeshLambertMaterial,
  MeshPhysicalMaterial,
  Mesh,
  AmbientLight,
  DirectionalLight,
  TextureLoader,
} from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  props: {
    magazine: {
      type: String,
      default: "sixteen",
    },
    no: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      textures: {
        front: require(`@/assets/img/magazine/${this.magazine}/front.png`),
        spine: require(`@/assets/img/magazine/${this.magazine}/spine.png`),
        top: require(`@/assets/img/magazine/${this.magazine}/top.png`),
        bottom: require(`@/assets/img/magazine/${this.magazine}/bottom.png`),
        edge: require(`@/assets/img/magazine/${this.magazine}/edge.png`),
        back: [
          require(`@/assets/img/magazine/${this.magazine}/back.png`),
          require(`@/assets/img/magazine/${this.magazine}/back_1.png`),
          require(`@/assets/img/magazine/${this.magazine}/back_2.png`),
          require(`@/assets/img/magazine/${this.magazine}/back_3.png`),
        ],
      },
      backCover: this.no || 0,
    };
  },
  methods: {
    draw() {
      this.raf = requestAnimationFrame(this.draw);

      //this.cube.rotation.x += 0.005;
      //this.cube.rotation.y += 0.01;

      this.cube.rotation.y -= (Math.PI / 360) * 1;
      if (this.cube.rotation.y <= Math.PI * -2) {
        this.cube.rotation.y = 0;
        this.updateBackCover();
      }

      //const t = this.app.scrollTop / 3000;
      //const rx = t * Math.PI * -0.5;
      //const ry = t * Math.PI * 2;
      //this.cube.rotation.x = rx;

      this.controls.update();

      this.renderer.render(this.scene, this.camera);
    },
    updateBackCover() {
      if (this.backCover + 1 === this.backCovers.length) {
        this.backCover = 0;
      } else {
        this.backCover++;
      }
      const texture = this.backCovers[this.backCover];

      this.cube.material[5].map = texture;
    },
    onResize() {
      const bounds = this.$el.getBoundingClientRect();

      this.renderer.setSize(bounds.width, bounds.height);
      this.camera.aspect = bounds.width / bounds.height;
      this.camera.updateProjectionMatrix();
    },
  },
  computed: {
    backCovers() {
      const loader = new TextureLoader();
      return this.textures.back.map((url) => loader.load(url));
    },
  },
  mounted() {
    this.app = document.querySelector("#app");
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(
      60,
      this.$el.offsetWidth / this.$el.offsetHeight,
      0.1,
      1000
    );

    this.renderer = new WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.$el.offsetWidth, this.$el.offsetHeight);
    this.$el.appendChild(this.renderer.domElement);

    this.ambient = new AmbientLight(0x222222); // soft white ambient
    this.scene.add(this.ambient);

    this.light = new DirectionalLight(0xffffff);
    this.light.position.set(0, 0, 5);
    this.scene.add(this.light);

    const loader = new TextureLoader();

    const urls = [
      this.textures.edge,
      this.textures.spine,
      this.textures.top,
      this.textures.bottom,
      this.textures.front,
      this.textures.back[this.no],
    ];

    const materials = urls.map((url, n) => {
      return new MeshPhysicalMaterial({
        map: loader.load(url),
        shininess: 1,

        metalness: 0.4,
        roughness: 0.7,
        clearcoat: 0,
        clearcoatRoughness: 1,
        reflectivity: 1,
      });
    });
    const size = 4;
    const geometry = new BoxGeometry((3 / 4) * size, size, 24 / 168);

    this.cube = new Mesh(geometry, materials);

    this.scene.add(this.cube);

    this.camera.position.z = 5;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.screenSpacePanning = true;
    this.controls.minDistance = 1.4;
    this.controls.maxDistance = 6;
    //this.controls.target.set( 0, 0, 0 )
    this.controls.enableZoom = false;
    this.controls.enableDamping = true;
    this.controls.autoRotate = false;

    this.controls.addEventListener("change", () => {
      this.ambient.position.copy(this.camera.position);
      this.light.position.copy(this.camera.position);
    });

    /**
     * Set onResize observer
     */

    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$el);

    this.draw();
  },
  beforeUnmount() {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }
    if (!this.renderer) return;
    this.renderer.forceContextLoss();
    this.renderer.context = null;
    this.renderer.domElement = null;
    this.renderer = null;

    this.observer.unobserve(this.$el);
  },
};
</script>

<style scoped>
.magazine-3d {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
