<template>
  <div class="global-settings">
    <div class="shim" v-show="inIsolation"></div>
    <div class="fill" />
    <knobs>
      <knobs-group>
        <slider label="Grid" v-model="grid" :options="gridOptions" />
      </knobs-group>
      <knobs-group>
        <slider label="Number" v-model="number" :min="50" :max="150" />
        <slider
          label="Steps"
          v-model="numberStep"
          :min="25"
          :max="100"
          :step="0.5"
        />
        <slider
          label="Marks"
          v-model="numberMarks"
          :step="5"
          :min="50"
          :max="250"
          options
        />
      </knobs-group>
    </knobs>
  </div>
</template>

<script>
import Knobs from "@/components/Knobs/Knobs";
import KnobsGroup from "@/components/Knobs/KnobsGroup";
import KnobHeader from "@/components/Knobs/KnobHeader";
import Slider from "@/components/Knobs/Slider";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Knobs,
    KnobsGroup,
    KnobHeader,
    Slider,
  },
  data() {
    return {
      gridOptions: [
        { label: "Bleed", value: "bleed" },
        { label: "Spacious", value: "spacious" },
        { label: "Square", value: "square" },
      ],
      number: 100,
      numberStep: 50.5,
      numberMarks: 200,
    };
  },
  methods: {
    ...mapMutations(["setGlobalSetting"]),
  },
  computed: {
    ...mapGetters(["settings"]),
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
    }),
    grid: {
      get() {
        return this.settings.grid;
      },
      set(value) {
        this.setGlobalSetting({ key: "grid", value });
      },
    },
  },
};
</script>

<style lang="scss">
.global-settings {
  .slider .thumb:not(.number) {
    background: var(--color-bg-secondary) !important;
  }
  .knobs {
    filter: invert(1);
  }
}
</style>

<style lang="scss" scoped>
.global-settings {
  position: fixed;
  width: 375rem;
  bottom: calc(var(--spacing-gutter) / 2);
  left: calc(var(--spacing-gutter) / 2);
  padding: calc(var(--spacing-gutter) / 2);
  z-index: 1000;

  .shim {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .fill {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(221, 221, 221, 0.85);
    background: var(--color-bg-primary);
    backdrop-filter: blur(80px);
    opacity: var(--opacity-in-isolation);
    filter: invert(1);
  }
}
</style>
