<template>
  <div class="loading">
    <div class="ball" v-for="n in 3" :ref="setBalls" :key="n" />
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  data() {
    return {
      balls: [],
    };
  },
  methods: {
    setBalls(el) {
      if (el) {
        this.balls.push(el);
      }
    },
  },
  beforeUpdate() {
    this.balls = [];
  },
  updated() {},
  mounted() {
    this.timeline = gsap.timeline({
      repeat: -1,
      yoyo: false,
      repeatDelay: 0,
    });

    this.timeline
      .to(
        this.balls,
        {
          scale: 0.95,
          opacity: 0.5,
          duration: 0.2,
          stagger: 0.15,
          y: -4,
        },
        0.1
      )
      .to(
        this.balls,
        {
          scale: 1,
          opacity: 1,
          y: 0,
          duration: 0.2,
          stagger: 0.15,
        },
        0.5
      );
  },
  beforeUnmount() {
    this.timeline.kill();
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  align-items: center;
  column-gap: 4rem;

  .ball {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: currentcolor;
  }
}
</style>
