<template>
  <div class="slider" :style="style" :class="{ disabled: disabled || knobsDisabled }">
    <!-- Thumb label -->
    <thumb-label :t="t" v-if="active" :value="computedLabel" :symbol="type === 'boolean'" />

    <!-- Number --->
    <template v-if="type === 'number'">
      <slider-number
        v-model="computedValue"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        :active="active"
        @t="t = $event"
      />
    </template>

    <!-- Options --->
    <template v-if="type === 'options' || type === 'options-text' || type === 'boolean'">
      <slider-options
        v-model="computedValue"
        :options="options"
        :active="active"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
        @t="t = $event"
      />
    </template>

    <!-- Color --->
    <template v-if="type === 'color'">
      <slider-color
        v-model="computedValue"
        @t="t = $event"
        @colors="colorOptions = $event"
        :scheme="scheme"
      />
    </template>

    <!-- Overlay -->
    <main>
      <span
        class="label"
        :class="{
          bottom:
            (type === 'options' ||
              type === 'options-text' ||
              type === 'color' ||
              type === 'boolean') &&
            active,
        }"
      >
        <div class="icon" v-if="icon">
          <sf-symbol :display="icon" :ligatures="['large']" />
        </div>
        {{ label }}
      </span>
      <span class="value" v-show="!active">
        <slot name="value" />
        <sf-symbol v-if="type === 'boolean'" :display="computedLabel" :ligatures="['small']" />
        <template v-else>
          <span>{{ computedLabel }}</span>
        </template>
      </span>
    </main>

    <!-- Range -->
    <footer
      v-show="active && type !== 'options-text' && type !== 'color' && type !== 'boolean'"
    >
      <span>{{ min }}</span>
      <span>{{ max }}</span>
    </footer>
  </div>
</template>

<script>
import gsap from "gsap";
import hammer from "hammerjs";

import SfSymbol from "@/components/DS/SfSymbol";

import SliderNumber from "./knobs/SliderNumber";
import SliderOptions from "./knobs/SliderOptions";
import SliderColor from "./knobs/SliderColor";

import ThumbLabel from "./ThumbLabel";

import { mapGetters, mapMutations } from "vuex";

import map from "./utils/map";

export default {
  components: {
    SfSymbol,
    SliderNumber,
    SliderOptions,
    SliderColor,
    ThumbLabel,
  },
  props: {
    label: String,
    icon: String,
    colors: [Boolean, String],
    scheme: String,
    options: {
      type: [Boolean, Array],
    },
    events: Array,
    modelValue: [Number, Array, String, Boolean, Object],
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 0.01,
    },
    isolate: {
      type: Boolean,
      default: true,
    },
    isolationMode: {
      type: String,
      default: "void",
    },
    /* 
    Wait to update value to touchend - for heavy compuations 
    */
    instant: {
      type: Boolean,
      default: true,
    },
    verticalScroll: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      t: 0,
      active: false,
      computedValue: this.modelValue,
      colorOptions: [],
    };
  },
  methods: {
    /* Component logic
     */
    ...mapMutations({
      setIsolation: "knobs/setIsolation",
      setDisabled: "knobs/setDisabled",
      setScrollingState: "knobs/setScrollingState",
      setIsolationMode: "knobs/setIsolationMode",
    }),
    init() {
      this.manager = new hammer.Manager(this.$el);

      this.pan = new hammer.Pan({
        threshold: this.dragThreshold,
        //touchAction: "compute",
        direction: hammer.DIRECTION_HORIZONTAL,
        //domEvents: true
      });

      this.manager.add([this.pan]);

      this.manager.on("panstart", this.onPanStart);
      this.manager.on("panend", this.onPanEnd);
      this.manager.on("pancancel", this.onPanEnd);
      this.manager.on("panleft panright", this.onPanMove);
    },
    onPanStart(e) {
      if (this.knobsDisabled) return;
      if (this.verticalScroll) {
        if (this.knobsDisabled) {
          this.setDisabled(false);
          return false;
        }
        this.setDisabled(false);
        //if (this.disabled) return false;
        if (e.velocityX === 0 || e.velocityY !== 0) {
          this.setDisabled(true);
          return false;
        }
      }
      this.active = true;
    },
    onPanMove(e) {
      if (this.knobsDisabled) return;

      if (this.verticalScroll) {
        if (this.knobsDisabled) {
          this.active = false;
        }
        this.setDisabled(false);

        if (!this.disabled & !this.active) this.active = true;
        // this.animate({
        //   y: -8 + e.deltaY / 10
        // });
      }
    },
    onPanEnd(e) {
      if (this.knobsDisabled) return;
      // if (this.verticalScroll) {
      // }
      this.setDisabled(false);
      this.active = false;
      if (!this.instant) this.$emit("update:modelValue", this.computedValue);
    },

    /* Component helpers
     */
    animate({ duration = 0.5, y = 0 }) {
      if (this.animation) this.animation.kill();

      this.animation = gsap.to(this.$el, duration, {
        y,
        ease: "back.out(1.7)",
      });
    },
    getType(value) {
      if (this.options) {
        if (this.options instanceof Array) return "options-text";
        return "options";
      }
      if (this.colors) return "color";
      switch (true) {
        case typeof value === "number":
          return "number";
        case value instanceof Object:
          return "options";
        case typeof value === "boolean":
          return "boolean";
        case value instanceof Array:
          return "range";
        default:
          return false;
      }
    },
  },
  computed: {
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
      isScrolling: "knobs/isScrolling",
      globalIsolationMode: "knobs/isolationMode",
      knobsDisabled: "knobs/disabled",
      dragThreshold: "knobs/dragThreshold",
    }),
    type() {
      return this.getType(this.modelValue);
    },
    style() {
      const opacity =
        this.inIsolation && this.globalIsolationMode === "void" && !this.active
          ? 0
          : this.disabled
          ? 0.3
          : 1;
      return {
        zIndex: this.active ? 100 : null,
        opacity,
      };
    },
    computedLabel() {
      if (this.options) {
        if (this.options instanceof Array) {
          const option = this.options.find((mark) => mark.value === this.computedValue);
          if (option) {
            return option.label;
          }
        }
      }

      if (this.colors && this.colorOptions.length > 0) {
        return this.colorOptions.find((color) => color.value === this.computedValue).label;
      }

      if (typeof this.computedValue === "boolean") {
        return this.computedValue ? "􀀁" : "􀀀";
      }
      return this.computedValue;
    },
  },
  watch: {
    computedValue() {
      if (this.instant) this.$emit("update:modelValue", this.computedValue);
    },
    modelValue() {
      this.computedValue = this.modelValue;
    },
    active() {
      this.setIsolationMode(this.isolationMode);
      this.setIsolation({
        isolate: this.active,
        events: this.events,
      });

      this.animate({
        duration: this.active ? 0.5 : 0.25,
        y: this.active ? -8 : 0,
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.manager.destroy();
  },
};
</script>

<style lang="scss" scoped>
$height: 44rem;
$grid-column-gap: 16rem;
.slider {
  position: relative;
  width: 100%;
  height: $height;
  touch-action: pan-y !important;
  cursor: grab;
  // border-bottom: 0.5px solid rgba(black, 0.2);

  &.disabled {
    pointer-events: none;
    touch-action: none !important;
  }

  main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    pointer-events: none;
  }

  main {
    // @include typeset(medium-body);
    span.value {
      padding-right: $grid-column-gap;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 20rem;
    }

    input[type="number"] {
      background: red;
      font-size: inherit;
      pointer-events: all;
      display: inline;
      height: 100%;
      padding: 0;
      text-align: right;
      max-width: 64rem;
    }

    span.label {
      color: var(--color-fg-secondary);
      display: flex;
      padding-left: $grid-column-gap;

      &.bottom {
        position: absolute;
        width: 100%;
        left: 0;
        height: $height;
        bottom: -$height;
        align-items: center;
        justify-content: center;
      }
      .icon {
        width: 28rem;
        font-size: 13rem;
        display: grid;
        place-items: center;
      }
    }
  }

  footer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    height: $height;
    bottom: -$height;
    /*     padding-left: $grid-column-gap;
    padding-right: $grid-column-gap; */
    top: auto;
  }
}
</style>
