import {
  Vector2,
  MeshPhysicalMaterial,
  TextureLoader,
  Texture,
  ClampToEdgeWrapping,
  LinearFilter,
} from "three";

import CustomShaderMaterial from "three-custom-shader-material/vanilla";

const vertexShaderSource = `
  varying vec2 v_texCoord;

  void main() {
	v_texCoord = uv;
	csm_PositionRaw = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const fragmentShaderSource = `
  precision mediump float;

  uniform sampler2D u_image1;
  uniform sampler2D u_image2;
  uniform float u_transition;

  varying vec2 v_texCoord;

  float eInkEffect(float value) {
	const float steps = 28.0;
	return floor(value * steps) * (1.0 / steps);
  }

  vec4 applyEInkColor(vec4 color) {
	return vec4(eInkEffect(color.r), eInkEffect(color.g), eInkEffect(color.b), color.a);
  }

  vec4 invertedColor(vec4 color) {
	return vec4(1.0 - color.rgb, color.a);
  }

  void main() {
	vec4 color1 = applyEInkColor(texture2D(u_image1, v_texCoord));
	vec4 color2 = applyEInkColor(texture2D(u_image2, v_texCoord));

	vec4 resultColor;
	if (u_transition < 0.1) {
	  resultColor = color1;
	} else if (u_transition < 0.3) {
	  resultColor = mix(color1, invertedColor(color2), (u_transition - 0.1) / 0.2);
	} else if (u_transition < 0.4) {
	  resultColor = mix(invertedColor(color2), vec4(1.0, 1.0, 1.0, 1.0), (u_transition - 0.3) / 0.1);
	} else if (u_transition < 0.6) {
	  float factor = (u_transition - 0.4) / 0.2;
	  vec4 clearColor2 = vec4(color2.rgb, factor);
	  vec4 clearColor1 = vec4(invertedColor(color1).rgb, 1.0 - factor);
	  resultColor = mix(clearColor1, clearColor2, v_texCoord.y);
	} else if (u_transition < 0.8) {
	  resultColor = mix(color1, color2, (u_transition - 0.6) / 0.2);
	} else {
	  resultColor = color2;
	}

	csm_DiffuseColor = resultColor;
  }
`;

export default class EInkMaterial {
  constructor(images, bounds, callback) {
    this.images = images;
    this.imageTextures = [];
    this.imageLoadCount = 0;
    this.imageIndex = 0;
    this.transitionState = 0; // 0: waiting, 1: transitioning
    this.lastTransitionTime = Date.now();
    this.transitionDuration = 1000;
    this.pauseDuration = 1000;
    this.callback = callback;
    this.animationFrameId = null;

    this.textureSize = new Vector2(bounds.width, bounds.height);

    this.loader = new TextureLoader();

    this.material = new CustomShaderMaterial({
      baseMaterial: MeshPhysicalMaterial,
      uniforms: {
        u_image1: { type: "t", value: null },
        u_image2: { type: "t", value: null },
        u_transition: { type: "f", value: 0 },
      },
      vertexShader: vertexShaderSource,
      fragmentShader: fragmentShaderSource,
      clearcoat: 1.0,
      metalness: 1,
      roughness: 0.5,
    });

    this.createTextures();
  }

  createTextures(callback) {
    this.images.forEach((url, index) => this.createTexture(url, index));
  }

  createTexture(url, index) {
    this.loadImage(url, (image) => {
      const texture = this.createCroppedTexture(image, 1200, 1600);

      this.imageTextures[index] = texture;
      this.imageLoadCount++;

      if (this.imageLoadCount === this.images.length) {
        this.setInitialTexture();
        if (typeof this.callback === "function") this.callback();
      }
    });
  }

  loadImage(url, onLoad) {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = url;
    image.onload = () => {
      onLoad(image);
    };
  }

  createCroppedTexture(image, meshWidth, meshHeight) {
    let canvas;
    if (typeof OffscreenCanvas !== "undefined") {
      canvas = new OffscreenCanvas(meshWidth, meshHeight);
    } else {
      canvas = document.createElement("canvas");
      canvas.width = meshWidth;
      canvas.height = meshHeight;
    }

    canvas = document.createElement("canvas");
    canvas.width = meshWidth;
    canvas.height = meshHeight;

    const ctx = canvas.getContext("2d");

    const imgAspectRatio = image.width / image.height;
    const meshAspectRatio = meshWidth / meshHeight;
    let sourceWidth, sourceHeight, sourceX, sourceY;

    if (imgAspectRatio > meshAspectRatio) {
      sourceWidth = image.height * meshAspectRatio;
      sourceHeight = image.height;
      sourceX = (image.width - sourceWidth) / 2;
      sourceY = 0;
    } else {
      sourceWidth = image.width;
      sourceHeight = image.width / meshAspectRatio;
      sourceX = 0;
      sourceY = (image.height - sourceHeight) / 2;
    }

    ctx.drawImage(
      image,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      meshWidth,
      meshHeight
    );

    const texture = new Texture(canvas);
    texture.needsUpdate = true;
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping;
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;

    return texture;
  }

  setInitialTexture() {
    this.material.uniforms.u_image1.value = this.imageTextures[this.imageIndex];
    this.material.uniforms.u_image2.value =
      this.imageTextures[(this.imageIndex + 1) % this.images.length];
    this.material.uniforms.u_transition.value = 0;
  }

  async transitionTo(targetIndex, duration = this.transitionDuration) {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
    }

    const startTime = Date.now();
    const image1Index = this.imageIndex;
    const image2Index = targetIndex;

    this.material.uniforms.u_image1.value = this.imageTextures[image1Index];
    this.material.uniforms.u_image2.value = this.imageTextures[image2Index];

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      this.material.uniforms.u_transition.value = progress;

      if (progress < 1) {
        this.animationFrameId = requestAnimationFrame(animate);
      } else {
        this.imageIndex = targetIndex;
        this.material.uniforms.u_image1.value = this.imageTextures[this.imageIndex];
        this.material.uniforms.u_image2.value = this.imageTextures[image1Index];
        this.material.uniforms.u_transition.value = 0;
        this.animationFrameId = null;
      }
    };

    animate();
  }

  update() {
    const t = Date.now();
    let transition = 0;

    // Calculate transition progress and state
    if (this.transitionState === 0) {
      if (t - this.lastTransitionTime >= this.pauseDuration) {
        this.transitionState = 1;
        this.lastTransitionTime = t;
      }
    } else if (this.transitionState === 1) {
      const progress = (t - this.lastTransitionTime) / this.transitionDuration;
      transition = progress;

      if (progress >= 1) {
        transition = 1; // Clamp the transition value to 1
        this.transitionState = 0;
        this.lastTransitionTime = t;
        this.imageIndex = (this.imageIndex + 1) % this.images.length;
      }
    }

    // Update images and transition uniforms
    const image1Index = this.imageIndex;
    const image2Index = (this.imageIndex + 1) % this.images.length;

    if (transition < 1) {
      this.material.uniforms.u_image1.value = this.imageTextures[image1Index];
      this.material.uniforms.u_image2.value = this.imageTextures[image2Index];
    }

    // Update transition uniform
    this.material.uniforms.u_transition.value = transition;
  }
}
