<template>
  <div
    class="issue theme"
    :class="classes"
    @click="toggle"
    @mouseover="onMouseover"
    @mouseleave="onMouseleave"
    @mousedown="onMouseleave"
  >
    <!-- BG layer -->
    <transition name="fade">
      <div class="full bg theme" :style="fullViewportStyle" v-if="!stacked" />
    </transition>

    <!-- Stack -->
    <div class="container" :style="style">
      <div
        class="scroller"
        ref="scroller"
        :class="{ active: zIndexAbove, 'no-snap': stacked || fingerDown }"
      >
        <div class="artwork" v-for="(n, index) in artworks" :key="n" :ref="setItemRef">
          <div class="canvas" :style="setArtworkStyle(index)"></div>
        </div>
      </div>
    </div>

    <!-- Full screen UI -->
    <div class="full" :style="fullViewportStyle" v-if="zIndexAbove">
      <transition name="slide-y-reverse" appear>
        <bottom-strip
          v-show="!stacked"
          :title="title"
          :subheading="subheading"
          :bullets="number && [`Issue ${number}`]"
          position="fixed"
        >
          <btn-display
            style="pointer-events: all"
            @click="previewAll"
            :loading="rendering"
            :disabled="onDisplay"
          />
        </bottom-strip>
      </transition>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { interpolate, interpolateNumber } from "d3-interpolate";
import hammer from "hammerjs";
import { mapMutations, mapGetters } from "vuex";

import BottomStrip from "@/components/BottomStrip";
import BtnDisplay from "@/components/BtnDisplay";

import getRandomInt from "@/funcs/getRandomInt";

import renderImageBase64 from "@/funcs/renderImageBase64";
import timeout from "@/funcs/timeout";

import Loading from "@/components/Loading";

export default {
  components: {
    BottomStrip,
    BtnDisplay,
    Loading,
  },
  props: {
    artworks: {
      type: Array,
      default() {
        const count = getRandomInt(3, 16);
        return Array(count);
      },
    },
    spread: {
      type: Number,
      default: 3.5,
    },
    maxDepth: {
      type: Number,
      default: 3,
    },
    theme: String,
    number: Number,
    magazine: String,
    title: String,
    subheading: String,
  },
  data() {
    return {
      itemRefs: [],
      itemBounds: [],
      columnGap: 0,
      offsetLeft: 0,
      stacked: true,
      tween: {
        t: 0,
      },
      target: {
        x: 0,
        y: 0,
      },
      zIndexAbove: false,
      fingerDown: false,
      shuffleZ: getRandomInt(0, this.artworks.length - 1),
      onDisplay: false,
      rendering: false,
      previewIndex: 0,
    };
  },
  methods: {
    getRandomColor() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    setContainerOffset() {
      if (!this.stacked) {
        const bounds = this.$el.getBoundingClientRect();
        this.target.x = -bounds.x;
        this.target.y = -bounds.y;
      }
    },
    setArtworkStyle(n) {
      const item = this.itemBounds[n];

      if (!item) return;

      const i = interpolate(
        { x: item.x, y: item.y, scale: item.scale },
        { x: 0, y: 0, scale: 1 }
      );
      const pos = i(this.tween.t);

      const zIndex = this.shuffleZ === n ? this.artworks.length : item.zIndex;

      let backgroundImage = this.artworks[n] ? this.artworks[n] : null;

      if (this.artworks[n] != null && this.artworks[n].constructor.name === "Object") {
        backgroundImage = this.artworks[n].image.s;
      }

      return {
        zIndex,
        transform: `translateX(${pos.x}px) translateY(${pos.y}px) scale(${pos.scale}) translateZ(0px)`,
        backgroundColor: backgroundImage ? null : item.bg,
        backgroundImage: `url(${backgroundImage})`,
      };
    },
    createGestures() {
      this.manager = new hammer.Manager(this.$el);

      this.pan = new hammer.Pan({
        preventDefault: true,
        direction: hammer.DIRECTION_VERTICAL,
      });
      this.tap = new hammer.Tap();

      this.manager.add([this.pan, this.tap]);

      // this.manager.on("tap", () => {
      //   this.animate(true);
      // });

      const panend = (e) => {
        const velocity = Math.abs(e.velocityY);

        if (velocity > 0.1 || e.deltaY > 400) {
          this.animate(true);
        } else {
          this.animate(false);
        }
        this.fingerDown = false;
      };

      this.manager.on("panstart", () => {
        this.fingerDown = true;
        this.scrollInterpolate = interpolateNumber(this.$refs.scroller.scrollLeft, 0);
      });
      this.manager.on("panend", panend);
      this.manager.on("pancancel", panend);

      this.manager.on("panmove", (e) => {
        const t = e.deltaY / 800;
        const scrollX = this.scrollInterpolate(t);

        this.$refs.scroller.scrollTo(scrollX, 0);
        this.tween.t = 1 - t;
      });
    },
    destroyGestures() {
      this.manager.destroy();
    },
    clamp(value, min, max) {
      return Math.min(Math.max(value, min), max);
    },
    onMousemove(e) {
      if (!this.stacked) return false;
      const t = event.layerY / e.srcElement.offsetHeight;
      const len = this.artworks.length - 1;
      const index = Math.round(this.clamp(t * len, 0, len));

      this.shuffleZ = index;
    },
    onMouseover(e) {
      if (this.zIndexAbove) return;
      if (this.interval) return;
      this.interval = setInterval(() => {
        if (this.shuffleZ + 1 >= this.artworks.length) {
          this.shuffleZ = 0;
        } else {
          this.shuffleZ++;
        }
      }, 200);
    },
    onMouseleave(e) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    onResize() {
      this.setContainerOffset();
      this.itemBounds = this.itemRefs.map((el, index) => {
        const bounds = el.getBoundingClientRect();

        if (index === 0) {
          this.offsetLeft = el.offsetLeft;

          const next = this.itemRefs[index + 1];
          if (next) {
            const nextBounds = next.getBoundingClientRect();
            this.columnGap = (bounds.left + bounds.width - nextBounds.left) * -1;
          }
        }

        const len = this.itemRefs.length - 1;
        const maxDepth = this.maxDepth - 1;
        const spread = (this.spread / 100) * bounds.width;

        const containerHeight = this.$el.getBoundingClientRect().height;
        const spreadHeight = (this.spread / 100) * bounds.height * maxDepth;
        const scale = (containerHeight - spreadHeight) / bounds.height;

        const x = (bounds.width + this.columnGap) * -index - this.offsetLeft;
        const y = -el.offsetTop;

        // Stack remaining in same place
        const offsetX = spread * (index % (maxDepth + 1));
        const offsetY = offsetX * (4 / 3);

        return {
          width: bounds.width,
          columnGap: this.columnGap,
          zIndex: this.itemRefs.length - index,
          x: x + offsetX,
          y: y + offsetX,
          scale,
          bg: this.getRandomColor(),
        };
      });
    },
    toggle(e) {
      //if (e.target.classList.contains("btn")) return;
      if (this.zIndexAbove) return;
      this.animate(false);
    },
    scrollBack() {
      if (this.stacked) {
        const x = this.$refs.scroller.scrollLeft;
        if (x !== 0) {
          const scroll = { x };
          if (this.scrollAnimation) this.scrollAnimation.kill();
          this.scrollAnimation = gsap.to(scroll, {
            x: 0,
            duration: 0.3,
            onUpdate: () => {
              this.$refs.scroller.scrollTo(scroll.x, 0);
            },
          });
        }
      }
    },
    animate(stacked) {
      this.stacked = stacked;
      const t = stacked ? 0 : 1;

      if (!stacked) {
        this.zIndexAbove = true;
      }

      this.setContainerOffset();
      this.scrollBack();

      if (this.animation) this.animation.kill();
      this.animation = gsap.to(this.tween, {
        t,
        ease: !stacked ? "back.inOut(.5)" : "back.out(0.5)",
        duration: !stacked ? 1 : 0.75,
        onComplete: () => {
          if (stacked) {
            this.zIndexAbove = false;
          }
        },
      });

      this.$emit("stacked", stacked);
    },
    async previewAll() {
      const src = this.artworks[this.previewIndex].image.src;
      await this.preview(src, true);
    },
    async preview(src, loop) {
      if (this.rendering) return;

      this.rendering = true;
      const response = await renderImageBase64(src, "white", this.size, this.uuid);
      const body = await response.json();
      await timeout(2500);

      this.rendering = false;
      this.onDisplay = true;

      if (loop && !this.stacked) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.previewIndex + 1 >= this.artworks.length) {
            this.previewIndex = 0;
          } else {
            this.previewIndex++;
          }
          this.previewAll();
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters(["size", "uuid"]),
    classes() {
      return {
        disabled: this.stacked,
        above: this.zIndexAbove,
        [`theme-${this.theme}`]: this.theme,
      };
    },
    interpolate() {
      return interpolate({ x: 0, y: 0 }, this.target);
    },
    style() {
      const pos = this.interpolate(this.tween.t);

      return {
        transform: `translateX(${pos.x}px) translateY(${pos.y}px) translateZ(0px)`,
      };
    },
    fullViewportStyle() {
      const pos = this.interpolate(1);
      return {
        transform: `translateX(${pos.x}px) translateY(${pos.y}px) translateZ(0px)`,
      };
    },
  },
  watch: {
    "tween.t": {
      handler() {
        if (this.tween.t === 0) {
          this.destroyGestures();
        } else {
          this.createGestures();
        }
      },
    },
    stacked() {
      if (this.stacked && this.timeout) {
        clearTimeout(this.timeout);
      }
    },
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  mounted() {
    /**
     * Set onResize observer
     */

    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$el);

    // setTimeout(() => {
    //   this.onResize();
    // }, 4000);
  },
  beforeUnmount() {
    this.observer.unobserve(this.$el);
    if (this.timeout) clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
.issue {
  aspect-ratio: 3/4;
  position: relative;
  filter: grayscale(1);
  overflow: hidden;
  transform: translateZ(0px);
  will-change: transform;
  //outline: 1px solid red;

  &.theme {
    background-color: transparent;
  }

  .container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.above {
    z-index: 2;
    overflow: visible;
    cursor: default;

    &:active {
      cursor: grab;
    }
  }

  &.disabled {
    .scroller {
      pointer-events: none;
    }
  }

  .scroller {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    row-gap: var(--spacing-gutter);
    padding: 44rem var(--spacing-gutter);
    /*     overflow: auto; */
    flex: 1;
    display: flex;
    column-gap: 200rem;
    scroll-snap-type: x;
    transform: translateZ(0px);

    &.no-snap {
      scroll-snap-type: none;
    }

    &.active {
      overflow: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .artwork {
      aspect-ratio: 3/4;
      scroll-snap-align: center;
      //scroll-snap-stop: always;
      position: relative;

      .canvas {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-origin: 0% 0%;
        background-color: rgb(var(--base-color-4));
        background-color: var(--base-color-canvas);
        background-size: cover;
        background-position: center;
        background-blend-mode: multiply;
      }
    }
  }
  .full {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;

    &.bg {
      width: 101vw;
      height: 101vw;
      top: -2px;
    }
  }
}
</style>
