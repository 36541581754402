//https://www.345tool.com/generator/random-id-generator

import { markRaw } from "vue";

import Kulachek from "@/assets/exhibitions/kulachek/Kulachek";
import DepthMap from "@/components/DepthMap";

const props = {
  depthSrc: require("@/assets/img/depth/woman-d.jpg"),
  src: require("@/assets/img/depth/woman.jpg"),
};

export default [
  {
    id: "OBjsYEMwiIDh",
    overlay: false,
    cover: require("@/assets/exhibitions/Robin-Friend.png"),
  },
  {
    id: "0aENtn7cOgJP",
    artist: "Damir Doma",
    subtitle: "Collages",
    date: "May 1 - 23",
    theme: "slate",
    artworkPosition: "top",
    background: "#666666",
    cover: require("@/assets/exhibitions/damir-doma/cover.jpg"),
  },
  {
    id: "KqEWUo1gmZKm",
    artist: "Jack Davison",
    subtitle: "Les trois danseurs",
    date: "Jun 8 - Jul 9",
    artworkPosition: "bottom",
    dateRight: true,
    invertPosition: true,
    cover: require("@/assets/exhibitions/jack-davison/cover.jpg"),
  },
  {
    id: "JBjsYEQwiiyL",
    // overlay: false,
    artworkPosition: "bleed",
    date: "Oct 7 - Nov 12",
    artist: "J Davison",
    subtitle: "Femme",
    theme: "slate",
    dateRight: true,
    bundle: true,
    bundlePosition: "bottom",
    exhibitionRight: false,
    invertPosition: false,
    code: markRaw(DepthMap),
    codeProps: props,
  },
  {
    id: "OBjsYEQwiiyH",
    // overlay: false,
    artworkPosition: "bleed",
    date: "Jul 17 - Jul 31",
    artist: "kulachek",
    subtitle: "Strelka",
    background: "#111",
    // background: "#ccc",
    // theme: "slate",
    // cover: require("@/assets/exhibitions/kulachek/cover.jpg"),
    code: markRaw(Kulachek),
  },
  {
    id: "Dz8sfhELgaSH",
    artist: "Ryan Carl",
    subtitle: "Repetition",
    date: "Aug 1 - 17",
    artworkPosition: "top",
    bundle: true,
    bundlePosition: "bottom",
    cover: require("@/assets/exhibitions/ryan-carl/cover.jpg"),
  },
  {
    id: "8SdIerDiBvjM",
    artist: "Marcus Schaefer",
    subtitle: "Pan & the Dream",
    date: "Sep 17 - Oct 31",
    artworkPosition: "bleed",
    cover: require("@/assets/exhibitions/marcus-schaefer/cover.jpg"),
  },
  {
    id: "OBjsYEMwiIyh",
    overlay: false,
    cover: require("@/assets/exhibitions/kulachek/cover.jpg"),
  },
  {
    id: "OBjsYEMwiIyh",
    artist: "Pacifico Silano",
    subtitle: "John John",
    artworkPosition: "bleed",
    theme: "slate",
    dateRight: true,
    bundle: true,
    bundlePosition: "bottom",
    exhibitionRight: false,
    invertPosition: false,
    date: "Nov 1 - 7",
    cover: require("@/assets/exhibitions/pacifico-silano/cover.jpg"),
  },
  // {
  //   id: "LBDSiEQwiiyH",
  //   artworkPosition: "bleed",
  //   date: "Jul 17 - Jul 31",
  //   artist: "kulachek",
  //   subtitle: "Strelka",
  //   background: "#111",
  //   code: markRaw(DepthMap),
  // },
];
