export function getRatio(ratio, orientation, split = ":") {
  const r = ratio.split(":");
  const isPortrait = orientation === "portrait";
  let label;
  if (r.length > 1) {
    return isPortrait ? r[0] + split + r[1] : r[1] + split + r[0];
  }
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
