<template>
  <div :class="className">
    <div class="container">
      <figure>
        <slot />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    className() {
      return this.disabled ? "proportional-box-disabled" : "proportional-box";
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin proportional-box($a: 1, $b: $a) {
  position: absolute;
  top: 0;
  left: calc(50cqw - #{$a/$b/2} * 100cqh);
  width: calc(#{$a/$b} * 100cqh);
  height: 100cqh;
  @container (max-aspect-ratio: #{$a}/#{$b}) {
    top: calc(50cqh - #{$b/$a/2} * 100cqw);
    left: 0;
    width: 100cqw;
    height: calc(#{$b/$a} * 100cqw);
  }
}

.proportional-box-disabled {
  width: 100%;
}

.proportional-box {
  height: calc(100% - var(--margin));
  position: relative;
  flex: 1;

  .container {
    position: absolute;
    width: calc(100% - 32rem);
    height: 100%;
    top: 0px;
    left: 16rem;
    container: card / size;
    container-name: card;
  }

  figure {
    @include proportional-box(3, 4);
    display: block;
    background: var(--color-bg);
  }
}
</style>
