import Matter from "matter-js";
import map from "@/funcs/map";

export default function ({ element, width = 800, height = 600 }) {
  // height = height - 50;
  var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Composites = Matter.Composites,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    Bodies = Matter.Bodies;

  // create engine
  var engine = Engine.create({
    positionIterations: 10,
    velocityIterations: 10,
  });

  var world = engine.world;

  world.gravity.x = -0.15;
  world.gravity.y = -0;

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      wireframes: false,
      background: "transparent",
      // showStats: true,
      // showPerformance: true,
    },
  });

  const large = 1400 * 1866;
  const small = 100 * 150;

  const scale = map(width * height, small, large, 0.05, 2.5);

  Render.setPixelRatio(render, "auto");

  Render.run(render);

  // create runner
  var runner = Runner.create({
    isFixed: true,
  });
  Runner.run(runner, engine);

  const getRandomColor = () => {
    const colors = [
      "#fff",
      "#eee",
      "#ddd",
      "#ccc",
      "#bbb",
      "#aaa",
      "#999",
      "#888",
      "#777",
      "#666",
      "#555",
      "#444",
      "#333",
      "#222",
      // "#111",
      "#000",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  var stack = Composites.stack(40, 40, 38, 18, 0, 0, function (x, y) {
    var sides = Math.round(Common.random(1, 8));

    switch (Math.round(Common.random(0, 1))) {
      case 0:
        if (Common.random() < 0.8) {
          return Bodies.rectangle(
            x,
            y,
            Common.random(25, 50) * scale,
            Common.random(25, 50) * scale,
            {
              render: {
                fillStyle: getRandomColor(),
              },
            }
          );
        } else {
          return Bodies.rectangle(
            x,
            y,
            Common.random(80, 320) * scale,
            Common.random(25, 300) * scale,
            {
              render: {
                fillStyle: getRandomColor(),
              },
            }
          );
        }
      case 1:
        return Bodies.polygon(x, y, sides, Common.random(5, 150) * scale, {
          render: {
            fillStyle: getRandomColor(),
          },
        });
    }
  });

  Composite.add(world, stack);

  Composite.add(world, [
    // walls

    Bodies.rectangle((width * 0.4) / 2, 0, width * 0.4, height * 0.13, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    }),

    Bodies.rectangle((width * 0.24) / 2, height, width * 0.24, height * 0.23, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    }),

    Bodies.rectangle(
      (width * 0.39) / 2,
      height - height * 0.09,
      width * 0.39,
      height * 0.05,
      {
        isStatic: true,
        render: {
          fillStyle: "transparent",
          strokeStyle: "transparent",
          lineWidth: 0,
        },
      }
    ),

    Bodies.rectangle(width / 2, 0, width, 1, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    }),
    Bodies.rectangle(width / 2, height, width, 1, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    }),
    Bodies.rectangle(width, height / 2, 1, height, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    }),
    Bodies.rectangle(0, height / 2, 1, height, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    }),
  ]);

  // add mouse control
  //   var mouse = Mouse.create(render.canvas),
  //     mouseConstraint = MouseConstraint.create(engine, {
  //       mouse: mouse,
  //       constraint: {
  //         stiffness: 0.02,
  //         render: {
  //           visible: false,
  //         },
  //       },
  //     });
  //
  //   Composite.add(world, mouseConstraint);

  // keep the mouse in sync with rendering
  // render.mouse = mouse;

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
  });

  function resize(width, height) {
    render.bounds.max.x = width;
    render.bounds.max.y = height;
    render.options.width = width;
    render.options.height = height;
    render.canvas.width = width * window.devicePixelRatio;
    render.canvas.height = height * window.devicePixelRatio;

    render.canvas.style.width = `${width}px`;
    render.canvas.style.height = `${height}px`;
  }

  // context for MatterTools.Demo
  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    resize,
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);

      Matter.World.clear(world);
      Matter.Engine.clear(engine);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
  };
}
