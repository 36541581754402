<template>
  <div class="carousel">
    <div class="scroller" ref="scroller" @scroll="onScroll">
      <!--       <div class="cell" style="aspect-ratio: 1/1; position: relative">
        <img
          :src="require('@/assets/img/hardware-dummy.jpg')"
          style="width: 100%; height: 100%"
          data-cursor="smile"
        />
        <footer class="theme theme-black">
          <span class="capitilize">ANNOUNCEMENT</span>
          Monolit introduces 2022 edition
        </footer>
      </div> -->

      <div
        class="cell"
        :class="{ [cell.type]: cell.type }"
        v-for="cell in cells"
        :key="cell.props.id"
        @click="goTo(cell)"
      >
        <!-- Artwork -->
        <template v-if="cell.type === 'artwork'">
          <artwork :image="cell.props.image.src" />
          <footer class="theme theme-black">
            <span class="capitilize"> {{ cell.props.artist }}</span>
            {{ cell.props.name }}
          </footer>
        </template>

        <!-- Exhibition -->
        <template v-if="cell.type === 'exhibition'">
          <exhibition-poster v-bind="cell.props" />
          <footer class="theme theme-black" data-cursor="plus">
            Exhibition
          </footer>
        </template>

        <!-- Issue -->
        <template v-if="cell.type === 'issue'">
          <div
            class="issue-container theme"
            :class="{ [`theme-${cell.props.theme}`]: cell.props.theme }"
          >
            <issue v-bind="cell.props" />
          </div>
          <footer class="theme theme-black">
            <span class="capitilize"> {{ cell.props.artist }}</span>
            {{ cell.props.name }}
          </footer>
        </template>

        <!-- Issue -->
        <template v-if="cell.type === 'magazine'">
          <magazine />
          <footer class="theme theme-black" data-cursor="plus">
            Issue 6 from SIXTEEN
          </footer>
        </template>
      </div>

      <!-- Last cell background -->
      <!--       <div
        class="cell theme"
        :class="{ [lastCellTheme]: lastCellTheme }"
        data-cursor="smile"
        data-cursor-scale="1.5"
      ></div> -->
    </div>

    <!-- Hover areas -->
    <div class="hover left" data-cursor="triangleLeft"></div>
    <div class="hover right" data-cursor="triangleRight"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import exhibitions from "@/assets/exhibitions";

import Artwork from "@/components/Artwork";
import ExhibitionPoster from "@/components/ExhibitionPoster";
import Issue from "@/components/Magazines/Issue";
import Magazine from "@/components/Magazine";

export default {
  components: {
    Artwork,
    ExhibitionPoster,
    Issue,
    Magazine,
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  methods: {
    getRandomArtwork() {
      return this.artworks[Math.floor(Math.random() * this.artworks.length)];
    },
    onScroll() {
      this.scrollY = this.$refs.scroller.scrollLeft;
    },
    goTo(cell) {
      let payload;

      if (cell.type === "artwork") {
        payload = { name: "StoreArtwork", params: { id: cell.props.id } };
      }

      this.$router.push(payload);
    },
  },
  computed: {
    ...mapGetters({
      artworks: "artworks/all",
      getArtwork: "artworks/getArtwork",
      getSeries: "artworks/getSeries",
    }),
    cells() {
      return [
        {
          type: "artwork",
          props: this.getArtwork("h0YmpXUqCnxi"),
        },
        {
          type: "magazine",
          props: {
            id: "lololowefl",
          },
        },
        {
          type: "artwork",
          props: this.getArtwork("4YpGWtNWSOqn"),
        },
        {
          type: "artwork",
          props: this.getArtwork("J-BQHuRLSE9W"),
        },
        {
          type: "artwork",
          props: this.getArtwork("i7aUsLsEVbV6"),
        },
        {
          type: "artwork",
          props: this.getArtwork("GGIaHQ8Hr3dP"),
        },
        {
          type: "artwork",
          props: this.getArtwork("A3DTlVuNg_wn"),
        },
        {
          type: "issue",
          props: {
            ...this.getArtwork("dG3h6-7OPPKM"),
          },
        },
        // {
        //   type: "artwork",
        //   props: this.getRandomArtwork(),
        // },
        // {
        //   type: "artwork",
        //   props: this.getRandomArtwork(),
        // },
        {
          type: "artwork",
          props: this.getArtwork("5K7laQUceuWx"),
        },
        {
          type: "artwork",
          props: this.getArtwork("CGDmBVTJnjsI"),
        },
        {
          type: "artwork",
          props: this.getArtwork("hPVtbbKLeq7M"),
        },
        {
          type: "artwork",
          props: this.getArtwork("gNEDbGgdJpYg"),
        },
        {
          type: "exhibition",
          props: exhibitions.find((e) => e.id === "OBjsYEQwiiyH"),
        },
        {
          type: "artwork",
          props: this.getArtwork("mu1GtWiIlRed"),
        },
        {
          type: "artwork",
          props: this.getArtwork("W-RNYft1e2TI"),
        },
        {
          type: "issue",
          props: {
            ...this.getArtwork("tir_Z2gpCD-l"),
          },
        },
        {
          type: "artwork",
          props: this.getArtwork("5TCtdXTaVfu_"),
        },
        {
          type: "artwork",
          props: this.getArtwork("HMeMEZ_a8bFJ"),
        },
        {
          type: "artwork",
          props: this.getArtwork("_JlSeLhOQCbi"),
        },
      ];
    },
    lastCellTheme() {
      const theme = this.cells[this.cells.length - 1].props.theme;
      return `theme-${theme}`;
    },
  },
  activated() {
    if (this.scrollY) {
      this.$refs.scroller.scrollTo(this.scrollY, 0);
    }
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  width: 100vw;
  height: 100vh;
  display: flex;

  .scroller {
    height: 100%;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 44rem 0;
    padding-top: 0rem;
    //padding-top: 0rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .cell {
      aspect-ratio: 3/4;
      height: calc(100vh - (44rem * 1));
      position: sticky;
      left: 0;

      &.exhibition {
        position: relative;
      }

      &.issue {
        aspect-ratio: 1/1;
        position: relative;

        .issue-container {
          display: grid;
          height: 100%;
          align-items: center;
          grid-template-columns: 1fr 2fr 1fr;
          grid-template-areas: ". photo .";
          //background: #999;
          //background: var(--color-bg-primary);
          > * {
            grid-area: photo;
          }
        }
      }

      &.magazine {
        aspect-ratio: 1/1;
        position: relative;
        background: #111;
      }

      /*       &:last-child {
        position: static;
        height: 100vh;
        background: black !important;
      } */

      footer {
        position: absolute;
        left: 0rem;
        bottom: -44rem;
        display: flex;
        align-items: center;
        column-gap: 10rem;
        padding: 0 var(--spacing-gutter);
        height: 44rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .hover {
    position: absolute;
    width: 88rem;
    height: 100%;
    top: 0;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}
</style>
