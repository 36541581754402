<template>
  <knobs>
    <!--Dither -->
    <knobs-group>
      <knob-header>Layout</knob-header>
      <slider
        label="Bleed"
        :modelValue="bleed"
        @update:modelValue="$emit('update:bleed', $event)"
        :events="['grid']"
      />

      <slider
        label="Width"
        :options="options.columnWidth"
        :disabled="bleed"
        :modelValue="columnWidth"
        @update:modelValue="$emit('update:columnWidth', $event)"
        :events="['grid']"
      />

      <slider
        label="Ratio"
        :options="computedRatioOptions"
        :disabled="bleed"
        :modelValue="ratio"
        @update:modelValue="$emit('update:ratio', $event)"
        :events="['grid']"
      />

      <slider
        label="Orientation"
        :options="options.orientation"
        :disabled="bleed || ratio === '1:1'"
        :modelValue="orientation"
        @update:modelValue="$emit('update:orientation', $event)"
        :events="['grid']"
      />

      <slider
        label="Align"
        :options="options.align"
        :disabled="bleed"
        :modelValue="align"
        @update:modelValue="$emit('update:align', $event)"
        :events="['grid']"
      />

      <slider
        label="Margin"
        :modelValue="margin"
        @update:modelValue="$emit('update:margin', $event)"
        :events="['grid']"
      />
    </knobs-group>

    <knobs-group>
      <knob-header>Fill</knob-header>
      <slider
        label="Color"
        :modelValue="fillColor"
        @update:modelValue="$emit('update:fillColor', $event)"
        colors
      />
    </knobs-group>

    <knobs-group>
      <knob-header>Stroke</knob-header>
      <slider
        label="Width"
        :min="0"
        :max="100"
        :step="1"
        :modelValue="strokeWidth"
        @update:modelValue="$emit('update:strokeWidth', $event)"
      />
      <slider
        label="Color"
        :modelValue="strokeColor"
        @update:modelValue="$emit('update:strokeColor', $event)"
        colors
      />
    </knobs-group>
  </knobs>
</template>

<script>
import Knobs from "@/components/Knobs/Knobs";
import KnobsGroup from "@/components/Knobs/KnobsGroup";
import KnobHeader from "@/components/Knobs/KnobHeader";
import Slider from "@/components/Knobs/Slider";

import { getRatio } from "../helpers";

export default {
  components: {
    Knobs,
    KnobsGroup,
    KnobHeader,
    Slider,
  },
  props: {
    bleed: Boolean,
    ratio: String,
    align: String,
    orientation: String,
    columnWidth: Number,
    margin: Boolean,
    fillColor: String,
    strokeColor: String,
    strokeWidth: Number,
    contentFit: String,
  },
  data() {
    return {
      options: {
        align: [
          { value: "start", label: "Top" },
          { value: "center", label: "Center" },
          { value: "end", label: "Bottom" },
        ],

        orientation: [
          { value: "portrait", label: "Portrait" },
          { value: "landscape", label: "Landscape" },
        ],
        columnWidth: [
          { value: 12, label: "12" },
          { value: 10, label: "10" },
          { value: 8, label: "8" },
          { value: 6, label: "6" },
          { value: 4, label: "4" },
          { value: 2, label: "2" },
        ],
        ratios: ["1:2", "9:16", "1:1.618", "2:3", "3:4", "5:6", "1:1"],
      },
    };
  },
  computed: {
    computedRatioOptions() {
      return this.options.ratios.map((value) => {
        let label = getRatio(value, this.orientation);

        if (!label) label = value;

        return {
          label,
          value,
        };
      });
    },
  },
};
</script>
