<template>
  <div class="slider-options">
    <div class="slider" :style="{ opacity: active ? 1 : 0 }">
      <div
        v-for="(mark, n) in computedOptions"
        :ref="setItemRef"
        class="mark"
        :class="setMarkClass(n)"
        :key="isText || isBoolean ? mark.id : mark"
      />
    </div>
    <div
      v-for="(knob, n) in knobs"
      class="thumb"
      :class="setKnobClass(knob, n)"
      :style="setKnobStyle(knob)"
      :key="n"
    />
  </div>
</template>

<script>
import hammer from "hammerjs";

import map from "../utils/map";
import clamp from "../utils/clamp";
import roundValueToStep from "../utils/roundValueToStep";

import { mapGetters } from "vuex";

export default {
  props: {
    modelValue: [Number, String, Array, Boolean],
    step: Number,
    min: Number,
    max: Number,
    options: [Boolean, Array],
    active: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      width: 0,
      knobWidth: 0,
      rangeIndex: 0,
      rangeIndexPosition: 0,
      optionRefs: [],
    };
  },
  methods: {
    setItemRef(el) {
      if (el) {
        this.optionRefs.push(el);
      }
    },
    onResize() {
      this.width = this.$el.getBoundingClientRect().width;
      this.update();
    },
    init() {
      this.onResize();
      this.$emit("t", this.t);

      this.manager = new hammer.Manager(this.$el);

      this.pan = new hammer.Pan({
        threshold: this.dragThreshold,
        //touchAction: "compute",
        direction: hammer.DIRECTION_HORIZONTAL,
        //domEvents: true,
        //direction: hammer.DIRECTION_HORIZONTAL,
      });

      this.manager.add([this.pan]);

      this.manager.on("panstart", this.onPanStart);
      this.manager.on("panend", this.onPanEnd);
      this.manager.on("pancancel", this.onPanEnd);
      this.manager.on("panmove", this.onPanMove);
    },
    update() {
      this.knobWidth = this.optionRefs[0].getBoundingClientRect().width;
    },
    formatValue(value) {
      if (this.isText || this.isBoolean) {
        return value;
      }

      if (value instanceof Array) {
        return value.map((d) => this.formatValue(d));
      }

      value = roundValueToStep(value, this.step, this.min);
      value = clamp(value, this.min, this.max);
      return value;
    },
    updateValue(value) {
      this.$emit("update:modelValue", this.formatValue(value));
    },
    onPanStart(e) {
      if (this.disabled || this.globalDisabled) return false;

      if (this.isRange) {
        /* Find range index */
        this.rangeIndex = this.findClosestValue(this.index, this.getIndexFromX(e.center.x));
        this.rangeIndexPosition = this.index.indexOf(this.rangeIndex);
      }
      this.savedX = this.x;
    },
    onPanMove(e) {
      if (this.disabled || this.globalDisabled) {
        return false;
      }
      const x = e.deltaX + this.savedX;

      let value;

      const index = this.getIndexFromX(x);

      if (this.isText || this.isBoolean) {
        value = this.computedOptions[index].value;
      } else if (this.isRange) {
        value = this.computedOptions[index];
        value = this.computedValue.map((d, n) => (n === this.rangeIndexPosition ? value : d));
      } else {
        value = map(x, 0, this.width, this.min, this.max);
      }

      this.updateValue(value);
    },
    onPanEnd(e) {
      if (this.disabled || this.globalDisabled) return false;
    },
    setKnobStyle(knob) {
      return {
        width: this.knobWidth + "px",
        transform: `translateX(${knob.x}px)`,
      };
    },
    setKnobClass(knob, n) {
      return {
        [this.type]: this.type,
        active: this.isRange ? n === this.rangeIndexPosition && this.active : this.active,
      };
    },
    setMarkClass(n) {
      return {
        [this.type]: this.type,
        active: this.index === n,
      };
    },
    getIndexFromX(x) {
      return clamp(
        Math.round(this.computedOptions.length * (x / this.width)),
        0,
        this.computedOptions.length - 1
      );
    },
    findClosestValue(arr, val) {
      return Math.max.apply(
        null,
        arr.filter((v) => v <= val)
      );
    },
  },
  computed: {
    ...mapGetters({
      dragThreshold: "knobs/dragThreshold",
      globalDisabled: "knobs/disabled",
    }),
    computedOptions() {
      if (this.isText) return this.options;

      if (this.isBoolean) {
        return [
          { value: false, id: "FALSE" },
          { value: true, id: "TRUE" },
        ];
      }

      const options = [];
      for (let i = this.min; i <= this.max; i = i + this.step) {
        options.push(i);
      }
      return options;
    },
    computedValue() {
      return this.formatValue(this.modelValue);
    },
    index() {
      let value = this.computedValue;

      if (this.isText || this.isBoolean) {
        value = this.computedOptions.find((mark) => mark.value === value);
      }

      if (this.isRange) {
        return value.map((d) => this.computedOptions.indexOf(d));
      }

      return this.computedOptions.indexOf(value);
    },
    x() {
      return (this.isRange ? this.rangeIndex : this.index) * this.knobWidth;
    },
    t() {
      if (this.isRange) {
        return this.computedValue[this.rangeIndexPosition] / this.max;
      }
      return this.index / (this.computedOptions.length - 1);
    },
    isBoolean() {
      return typeof this.modelValue === "boolean";
    },
    isText() {
      return this.options instanceof Array;
    },
    isRange() {
      return this.modelValue instanceof Array;
    },
    type() {
      if (this.isText) return "text";
      if (this.isBoolean) return "boolean";
      if (!this.isText) return "number";
      return false;
    },
    knobs() {
      const arr = this.isRange ? [...this.index] : [this.index];

      return arr.map((index) => {
        return {
          index,
          x: index * this.knobWidth,
        };
      });
    },
  },
  watch: {
    computedOptions: {
      handler: function (val, oldVal) {
        this.$nextTick(this.update);
      },
      deep: true,
    },
    t() {
      this.$emit("t", this.t);
    },
  },
  beforeUpdate() {
    this.optionRefs = [];
  },
  mounted() {
    setTimeout(this.init, 1);
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$el);
  },
  beforeUnmount() {
    this.observer.unobserve(this.$el);
    this.manager.destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "../style";
.slider-options {
  @extend .slider;
  touch-action: pan-y !important;

  .slider {
    display: flex;

    .mark {
      flex: 1;
      position: relative;

      &.text {
        display: flex;
        display: grid;
        place-items: center;

        &::after {
          content: "";
          width: 8rem;
          height: 8rem;
          border-radius: 50%;
          background: #bbb;
          position: relative;
        }
      }

      &.number {
        display: grid;
        place-items: center;

        &::after {
          content: "";
          width: 2rem;
          height: 100%;
          background: var(--color-fill-secondary);
        }
      }
    }
  }
  .thumb {
    @extend .thumb;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: grid;
    place-items: center;

    &.text {
      &.active::after {
        content: "";
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: currentcolor;
        position: relative;
      }
    }

    &.number {
      background: transparent;
      border-radius: 0;
      backdrop-filter: none;
      border: none;

      &.active {
        &::after {
          background: currentcolor;
        }
      }

      &::after {
        content: "";
        width: 2rem;
        height: 100%;
        background: var(--color-fill-secondary);
      }
    }
  }
}
</style>
