/**
 * Generated output: from /Users/jakobhenner/Prototypes/_samples/dithermark/js_generated/app/algorithm-model.js.php
 */

import ColorDither from "../../app/webgl-color-dither.js";

const colorDitherGroups = [
  { title: "Threshold", start: 0, length: 1 },
  { title: "Random", start: 1, length: 1 },
  { title: "Arithmetic", start: 2, length: 6 },
  { title: "Diffusion", start: 8, length: 9 },
  { title: "Bayer 2\u00d72 ", start: 17, length: 7 },
  { title: "Bayer 4\u00d74 ", start: 24, length: 5 },
  { title: "Bayer 8\u00d78 ", start: 29, length: 7 },
  { title: "Bayer 16\u00d716 ", start: 36, length: 6 },
  { title: "Hatch Horizontal ", start: 42, length: 6 },
  { title: "Hatch Vertical ", start: 48, length: 6 },
  { title: "Hatch Right ", start: 54, length: 7 },
  { title: "Hatch Left ", start: 61, length: 7 },
  { title: "Cross Hatch Horizontal ", start: 68, length: 7 },
  { title: "Cross Hatch Vertical ", start: 75, length: 7 },
  { title: "Cross Hatch Right ", start: 82, length: 7 },
  { title: "Cross Hatch Left ", start: 89, length: 7 },
  { title: "Zigzag Horizontal 4\u00d74 ", start: 96, length: 7 },
  { title: "Zigzag Vertical 4\u00d74 ", start: 103, length: 7 },
  { title: "Zigzag Horizontal 8\u00d78 ", start: 110, length: 7 },
  { title: "Zigzag Vertical 8\u00d78 ", start: 117, length: 7 },
  { title: "Zigzag Horizontal 16\u00d716 ", start: 124, length: 6 },
  { title: "Zigzag Vertical 16\u00d716 ", start: 130, length: 6 },
  { title: "Checkerboard ", start: 136, length: 7 },
  { title: "Cluster ", start: 143, length: 7 },
  { title: "Fishnet ", start: 150, length: 7 },
  { title: "Dot 4\u00d74 ", start: 157, length: 7 },
  { title: "Dot 8\u00d78 ", start: 164, length: 7 },
  { title: "Halftone ", start: 171, length: 7 },
  { title: "Square 2\u00d72 ", start: 178, length: 5 },
  { title: "Square 4\u00d74 ", start: 183, length: 5 },
  { title: "Square 8\u00d78 ", start: 188, length: 5 },
  { title: "Square 16\u00d716 ", start: 193, length: 5 },
];

const knobs = [
  { value: "threshold", label: "Threshold" },
  { value: "random", label: "Random" },
  { value: "arithmetic", label: "Arithmetic" },
  { value: "diffusion", label: "Diffusion" },
  {
    value: "bayer",
    label: "Bayer",
    props: [
      {
        label: "Size",
        value: 2,
        options: [
          { value: 2, label: "2\u00d72" },
          { value: 4, label: "4\u00d74" },
          { value: 8, label: "8\u00d78" },
          { value: 16, label: "16\u00d716" },
        ],
      },
    ],
  },
  {
    value: "hatch",
    label: "Hatch",
    props: [
      {
        label: "Direction",
        value: "x",
        options: [
          { value: "x", label: "Horisontal" },
          { value: "y", label: "Vertical" },
          { value: "left", label: "Left" },
          { value: "right", label: "Right" },
        ],
      },
    ],
  },
  {
    value: "cross-hatch",
    label: "Cross Hatch",
    props: [
      {
        label: "Direction",
        value: "x",
        options: [
          { value: "x", label: "Horisontal" },
          { value: "y", label: "Vertical" },
          { value: "left", label: "Left" },
          { value: "right", label: "Right" },
        ],
      },
    ],
  },
  {
    value: "zig-zag",
    label: "Zigzag",
    props: [
      {
        label: "Direction",
        value: "x",
        options: [
          { value: "x", label: "Horisontal" },
          { value: "y", label: "Vertical" },
        ],
      },
      {
        label: "Size",
        value: 4,
        options: [
          { value: 4, label: "4\u00d74" },
          { value: 8, label: "8\u00d78" },
          { value: 16, label: "16\u00d716" },
        ],
      },
    ],
  },
  { value: "checkerboard", label: "Checkerboard" },
  { value: "cluster", label: "Cluster" },
  { value: "fishnet", label: "Fishnet" },
  {
    value: "dot",
    label: "Dot",
    props: [
      {
        label: "Size",
        value: 4,
        options: [
          { value: 4, label: "4\u00d74" },
          { value: 8, label: "8\u00d78" },
        ],
      },
    ],
  },
  { value: "halftone", label: "Halftone" },
  {
    value: "square",
    label: "Square",
    props: [
      {
        label: "Size",
        value: 2,
        options: [
          { value: 2, label: "2\u00d72" },
          { value: 4, label: "4\u00d74" },
          { value: 8, label: "8\u00d78" },
          { value: 16, label: "16\u00d716" },
        ],
      },
    ],
  },
];

const colorDitherAlgorithms = [
  {
    title: "Closest Color",
    id: 74,
    webGlFunc: ColorDither.closestColor,
    group: "threshold",
  },
  {
    title: "Random",
    id: 75,
    webGlFunc: ColorDither.randomClosestColor,
    group: "random",
  },
  {
    title: "XOR (High)",
    id: 76,
    webGlFunc: ColorDither.aDitherXor1,
    requiresHighPrecisionInt: true,
    group: "arithmetic",
  },
  {
    title: "XOR (Medium)",
    id: 77,
    webGlFunc: ColorDither.aDitherXor3,
    requiresHighPrecisionInt: true,
    group: "arithmetic",
  },
  {
    title: "XOR (Low)",
    id: 78,
    webGlFunc: ColorDither.aDitherXor2,
    requiresHighPrecisionInt: true,
    group: "arithmetic",
  },
  {
    title: "ADD (High)",
    id: 79,
    webGlFunc: ColorDither.aDitherAdd1,
    requiresHighPrecisionInt: true,
    group: "arithmetic",
  },
  {
    title: "ADD (Medium)",
    id: 80,
    webGlFunc: ColorDither.aDitherAdd3,
    requiresHighPrecisionInt: true,
    group: "arithmetic",
  },
  {
    title: "ADD (Low)",
    id: 81,
    webGlFunc: ColorDither.aDitherAdd2,
    requiresHighPrecisionInt: true,
    group: "arithmetic",
  },
  {
    title: "Floyd-Steinberg",
    id: 82,
    group: "diffusion",
  },
  {
    title: "Javis-Judice-Ninke",
    id: 83,
    group: "diffusion",
  },
  {
    title: "Stucki",
    id: 84,
    group: "diffusion",
  },
  {
    title: "Burkes",
    id: 85,
    group: "diffusion",
  },
  {
    title: "Sierra 3",
    id: 86,
    group: "diffusion",
  },
  {
    title: "Sierra 2",
    id: 87,
    group: "diffusion",
  },
  {
    title: "Sierra 1",
    id: 88,
    group: "diffusion",
  },
  {
    title: "Atkinson",
    id: 89,
    group: "diffusion",
  },
  {
    title: "Reduced Atkinson",
    id: 90,
    group: "diffusion",
  },
  {
    title: "Bayer 2×2 ",
    id: 91,
    webGlFunc: ColorDither.createBayerColorDither(2),
    group: "bayer",
  },
  {
    title: "Bayer 2×2  (R)",
    id: 92,
    webGlFunc: ColorDither.createBayerColorDither(2, true),
    group: "bayer",
  },
  {
    title: "Stark 2×2",
    id: 93,
    webGlFunc: ColorDither.createStarkOrderedDither(2, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 2×2",
    id: 94,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(2, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 2×2 (R)",
    id: 95,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(2, "bayer", true),
    group: "bayer",
  },
  {
    title: "Yliluoma 1 2×2",
    id: 96,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(2, "bayer"),
    group: "bayer",
  },
  {
    title: "Yliluoma 2 2×2",
    id: 97,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(2, "bayer"),
    group: "bayer",
  },
  {
    title: "Bayer 4×4 ",
    id: 98,
    webGlFunc: ColorDither.createBayerColorDither(4),
    group: "bayer",
  },
  {
    title: "Bayer 4×4  (R)",
    id: 99,
    webGlFunc: ColorDither.createBayerColorDither(4, true),
    group: "bayer",
  },
  {
    title: "Stark 4×4",
    id: 100,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 4×4",
    id: 101,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 4×4 (R)",
    id: 102,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "bayer", true),
    group: "bayer",
  },
  {
    title: "Bayer 8×8 ",
    id: 103,
    webGlFunc: ColorDither.createBayerColorDither(8),
    group: "bayer",
  },
  {
    title: "Bayer 8×8  (R)",
    id: 104,
    webGlFunc: ColorDither.createBayerColorDither(8, true),
    group: "bayer",
  },
  {
    title: "Stark 8×8",
    id: 105,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 8×8",
    id: 106,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 8×8 (R)",
    id: 107,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "bayer", true),
    group: "bayer",
  },
  {
    title: "Yliluoma 1 8×8",
    id: 108,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(8, "bayer"),
    group: "bayer",
  },
  {
    title: "Yliluoma 2 8×8",
    id: 109,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(8, "bayer"),
    group: "bayer",
  },
  {
    title: "Bayer 16×16 ",
    id: 110,
    webGlFunc: ColorDither.createBayerColorDither(16),
    group: "bayer",
  },
  {
    title: "Bayer 16×16  (R)",
    id: 111,
    webGlFunc: ColorDither.createBayerColorDither(16, true),
    group: "bayer",
  },
  {
    title: "Stark 16×16",
    id: 112,
    webGlFunc: ColorDither.createStarkOrderedDither(16, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 16×16",
    id: 113,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(16, "bayer"),
    group: "bayer",
  },
  {
    title: "Hue-Lightness 16×16 (R)",
    id: 114,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(16, "bayer", true),
    group: "bayer",
  },
  {
    title: "Yliluoma 2 16×16",
    id: 115,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(16, "bayer"),
    group: "bayer",
  },
  {
    title: "Hatch Horizontal ",
    id: 116,
    webGlFunc: ColorDither.createHatchHorizontalColorDither(4),
    group: "hatch",
  },
  {
    title: "Hatch Horizontal  (R)",
    id: 117,
    webGlFunc: ColorDither.createHatchHorizontalColorDither(4, true),
    group: "hatch",
  },
  {
    title: "Stark Hatch Horizontal",
    id: 118,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "hatchHorizontal"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Horizontal",
    id: 119,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "hatchHorizontal"
    ),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Horizontal (R)",
    id: 120,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "hatchHorizontal",
      true
    ),
    group: "hatch",
  },
  {
    title: "Yliluoma 1 Hatch Horizontal",
    id: 121,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "hatchHorizontal"),
    group: "hatch",
  },
  {
    title: "Hatch Vertical ",
    id: 122,
    webGlFunc: ColorDither.createHatchVerticalColorDither(4),
    group: "hatch",
  },
  {
    title: "Hatch Vertical  (R)",
    id: 123,
    webGlFunc: ColorDither.createHatchVerticalColorDither(4, true),
    group: "hatch",
  },
  {
    title: "Stark Hatch Vertical",
    id: 124,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "hatchVertical"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Vertical",
    id: 125,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "hatchVertical"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Vertical (R)",
    id: 126,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "hatchVertical",
      true
    ),
    group: "hatch",
  },
  {
    title: "Yliluoma 1 Hatch Vertical",
    id: 127,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "hatchVertical"),
    group: "hatch",
  },
  {
    title: "Hatch Right ",
    id: 128,
    webGlFunc: ColorDither.createHatchRightColorDither(4),
    group: "hatch",
  },
  {
    title: "Hatch Right  (R)",
    id: 129,
    webGlFunc: ColorDither.createHatchRightColorDither(4, true),
    group: "hatch",
  },
  {
    title: "Stark Hatch Right",
    id: 130,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "hatchRight"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Right",
    id: 131,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "hatchRight"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Right (R)",
    id: 132,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "hatchRight",
      true
    ),
    group: "hatch",
  },
  {
    title: "Yliluoma 1 Hatch Right",
    id: 133,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "hatchRight"),
    group: "hatch",
  },
  {
    title: "Yliluoma 2 Hatch Right",
    id: 134,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "hatchRight"),
    group: "hatch",
  },
  {
    title: "Hatch Left ",
    id: 135,
    webGlFunc: ColorDither.createHatchLeftColorDither(4),
    group: "hatch",
  },
  {
    title: "Hatch Left  (R)",
    id: 136,
    webGlFunc: ColorDither.createHatchLeftColorDither(4, true),
    group: "hatch",
  },
  {
    title: "Stark Hatch Left",
    id: 137,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "hatchLeft"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Left",
    id: 138,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "hatchLeft"),
    group: "hatch",
  },
  {
    title: "Hue-Lightness Hatch Left (R)",
    id: 139,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "hatchLeft",
      true
    ),
    group: "hatch",
  },
  {
    title: "Yliluoma 1 Hatch Left",
    id: 140,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "hatchLeft"),
    group: "hatch",
  },
  {
    title: "Yliluoma 2 Hatch Left",
    id: 141,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "hatchLeft"),
    group: "hatch",
  },
  {
    title: "Cross Hatch Horizontal ",
    id: 142,
    webGlFunc: ColorDither.createCrossHatchHorizontalColorDither(4),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Horizontal  (R)",
    id: 143,
    webGlFunc: ColorDither.createCrossHatchHorizontalColorDither(4, true),
    group: "cross-hatch",
  },
  {
    title: "Stark Cross Hatch Horizontal",
    id: 144,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "crossHatchHorizontal"),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Horizontal",
    id: 145,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchHorizontal"
    ),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Horizontal (R)",
    id: 146,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchHorizontal",
      true
    ),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 1 Cross Hatch Horizontal",
    id: 147,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(
      4,
      "crossHatchHorizontal"
    ),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 2 Cross Hatch Horizontal",
    id: 148,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(
      4,
      "crossHatchHorizontal"
    ),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Vertical ",
    id: 149,
    webGlFunc: ColorDither.createCrossHatchVerticalColorDither(4),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Vertical  (R)",
    id: 150,
    webGlFunc: ColorDither.createCrossHatchVerticalColorDither(4, true),
    group: "cross-hatch",
  },
  {
    title: "Stark Cross Hatch Vertical",
    id: 151,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "crossHatchVertical"),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Vertical",
    id: 152,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchVertical"
    ),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Vertical (R)",
    id: 153,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchVertical",
      true
    ),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 1 Cross Hatch Vertical",
    id: 154,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(
      4,
      "crossHatchVertical"
    ),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 2 Cross Hatch Vertical",
    id: 155,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(
      4,
      "crossHatchVertical"
    ),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Right ",
    id: 156,
    webGlFunc: ColorDither.createCrossHatchRightColorDither(4),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Right  (R)",
    id: 157,
    webGlFunc: ColorDither.createCrossHatchRightColorDither(4, true),
    group: "cross-hatch",
  },
  {
    title: "Stark Cross Hatch Right",
    id: 158,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "crossHatchRight"),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Right",
    id: 159,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchRight"
    ),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Right (R)",
    id: 160,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchRight",
      true
    ),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 1 Cross Hatch Right",
    id: 161,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "crossHatchRight"),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 2 Cross Hatch Right",
    id: 162,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "crossHatchRight"),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Left ",
    id: 163,
    webGlFunc: ColorDither.createCrossHatchLeftColorDither(4),
    group: "cross-hatch",
  },
  {
    title: "Cross Hatch Left  (R)",
    id: 164,
    webGlFunc: ColorDither.createCrossHatchLeftColorDither(4, true),
    group: "cross-hatch",
  },
  {
    title: "Stark Cross Hatch Left",
    id: 165,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "crossHatchLeft"),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Left",
    id: 166,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "crossHatchLeft"),
    group: "cross-hatch",
  },
  {
    title: "Hue-Lightness Cross Hatch Left (R)",
    id: 167,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "crossHatchLeft",
      true
    ),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 1 Cross Hatch Left",
    id: 168,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "crossHatchLeft"),
    group: "cross-hatch",
  },
  {
    title: "Yliluoma 2 Cross Hatch Left",
    id: 169,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "crossHatchLeft"),
    group: "cross-hatch",
  },
  {
    title: "Zigzag Horizontal 4×4 ",
    id: 170,
    webGlFunc: ColorDither.createZigzagHorizontalColorDither(4),
    group: "zig-zag",
  },
  {
    title: "Zigzag Horizontal 4×4  (R)",
    id: 171,
    webGlFunc: ColorDither.createZigzagHorizontalColorDither(4, true),
    group: "zig-zag",
  },
  {
    title: "Stark Zigzag Horizontal 4×4",
    id: 172,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Horizontal 4×4",
    id: 173,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "zigzagHorizontal"
    ),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Horizontal 4×4 (R)",
    id: 174,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "zigzagHorizontal",
      true
    ),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 1 Zigzag Horizontal 4×4",
    id: 175,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 2 Zigzag Horizontal 4×4",
    id: 176,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Zigzag Vertical 4×4 ",
    id: 177,
    webGlFunc: ColorDither.createZigzagVerticalColorDither(4),
    group: "zig-zag",
  },
  {
    title: "Zigzag Vertical 4×4  (R)",
    id: 178,
    webGlFunc: ColorDither.createZigzagVerticalColorDither(4, true),
    group: "zig-zag",
  },
  {
    title: "Stark Zigzag Vertical 4×4",
    id: 179,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Vertical 4×4",
    id: 180,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Vertical 4×4 (R)",
    id: 181,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      4,
      "zigzagVertical",
      true
    ),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 1 Zigzag Vertical 4×4",
    id: 182,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 2 Zigzag Vertical 4×4",
    id: 183,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Zigzag Horizontal 8×8 ",
    id: 184,
    webGlFunc: ColorDither.createZigzagHorizontalColorDither(8),
    group: "zig-zag",
  },
  {
    title: "Zigzag Horizontal 8×8  (R)",
    id: 185,
    webGlFunc: ColorDither.createZigzagHorizontalColorDither(8, true),
    group: "zig-zag",
  },
  {
    title: "Stark Zigzag Horizontal 8×8",
    id: 186,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Horizontal 8×8",
    id: 187,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      8,
      "zigzagHorizontal"
    ),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Horizontal 8×8 (R)",
    id: 188,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      8,
      "zigzagHorizontal",
      true
    ),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 1 Zigzag Horizontal 8×8",
    id: 189,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(8, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 2 Zigzag Horizontal 8×8",
    id: 190,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(8, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Zigzag Vertical 8×8 ",
    id: 191,
    webGlFunc: ColorDither.createZigzagVerticalColorDither(8),
    group: "zig-zag",
  },
  {
    title: "Zigzag Vertical 8×8  (R)",
    id: 192,
    webGlFunc: ColorDither.createZigzagVerticalColorDither(8, true),
    group: "zig-zag",
  },
  {
    title: "Stark Zigzag Vertical 8×8",
    id: 193,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Vertical 8×8",
    id: 194,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Vertical 8×8 (R)",
    id: 195,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      8,
      "zigzagVertical",
      true
    ),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 1 Zigzag Vertical 8×8",
    id: 196,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(8, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 2 Zigzag Vertical 8×8",
    id: 197,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(8, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Zigzag Horizontal 16×16 ",
    id: 198,
    webGlFunc: ColorDither.createZigzagHorizontalColorDither(16),
    group: "zig-zag",
  },
  {
    title: "Zigzag Horizontal 16×16  (R)",
    id: 199,
    webGlFunc: ColorDither.createZigzagHorizontalColorDither(16, true),
    group: "zig-zag",
  },
  {
    title: "Stark Zigzag Horizontal 16×16",
    id: 200,
    webGlFunc: ColorDither.createStarkOrderedDither(16, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Horizontal 16×16",
    id: 201,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      16,
      "zigzagHorizontal"
    ),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Horizontal 16×16 (R)",
    id: 202,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      16,
      "zigzagHorizontal",
      true
    ),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 2 Zigzag Horizontal 16×16",
    id: 203,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(16, "zigzagHorizontal"),
    group: "zig-zag",
  },
  {
    title: "Zigzag Vertical 16×16 ",
    id: 204,
    webGlFunc: ColorDither.createZigzagVerticalColorDither(16),
    group: "zig-zag",
  },
  {
    title: "Zigzag Vertical 16×16  (R)",
    id: 205,
    webGlFunc: ColorDither.createZigzagVerticalColorDither(16, true),
    group: "zig-zag",
  },
  {
    title: "Stark Zigzag Vertical 16×16",
    id: 206,
    webGlFunc: ColorDither.createStarkOrderedDither(16, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Vertical 16×16",
    id: 207,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      16,
      "zigzagVertical"
    ),
    group: "zig-zag",
  },
  {
    title: "Hue-Lightness Zigzag Vertical 16×16 (R)",
    id: 208,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      16,
      "zigzagVertical",
      true
    ),
    group: "zig-zag",
  },
  {
    title: "Yliluoma 2 Zigzag Vertical 16×16",
    id: 209,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(16, "zigzagVertical"),
    group: "zig-zag",
  },
  {
    title: "Checkerboard ",
    id: 210,
    webGlFunc: ColorDither.createCheckerboardColorDither(2),
    group: "checkerboard",
  },
  {
    title: "Checkerboard  (R)",
    id: 211,
    webGlFunc: ColorDither.createCheckerboardColorDither(2, true),
    group: "checkerboard",
  },
  {
    title: "Stark Checkerboard",
    id: 212,
    webGlFunc: ColorDither.createStarkOrderedDither(2, "checkerboard"),
    group: "checkerboard",
  },
  {
    title: "Hue-Lightness Checkerboard",
    id: 213,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(2, "checkerboard"),
    group: "checkerboard",
  },
  {
    title: "Hue-Lightness Checkerboard (R)",
    id: 214,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(
      2,
      "checkerboard",
      true
    ),
    group: "checkerboard",
  },
  {
    title: "Yliluoma 1 Checkerboard",
    id: 215,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(2, "checkerboard"),
    group: "checkerboard",
  },
  {
    title: "Yliluoma 2 Checkerboard",
    id: 216,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(2, "checkerboard"),
    group: "checkerboard",
  },
  {
    title: "Cluster ",
    id: 217,
    webGlFunc: ColorDither.createClusterColorDither(4),
    group: "cluster",
  },
  {
    title: "Cluster  (R)",
    id: 218,
    webGlFunc: ColorDither.createClusterColorDither(4, true),
    group: "cluster",
  },
  {
    title: "Stark Cluster",
    id: 219,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "cluster"),
    group: "cluster",
  },
  {
    title: "Hue-Lightness Cluster",
    id: 220,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "cluster"),
    group: "cluster",
  },
  {
    title: "Hue-Lightness Cluster (R)",
    id: 221,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "cluster", true),
    group: "cluster",
  },
  {
    title: "Yliluoma 1 Cluster",
    id: 222,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "cluster"),
    group: "cluster",
  },
  {
    title: "Yliluoma 2 Cluster",
    id: 223,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "cluster"),
    group: "cluster",
  },
  {
    title: "Fishnet ",
    id: 224,
    webGlFunc: ColorDither.createFishnetColorDither(8),
    group: "fishnet",
  },
  {
    title: "Fishnet  (R)",
    id: 225,
    webGlFunc: ColorDither.createFishnetColorDither(8, true),
    group: "fishnet",
  },
  {
    title: "Stark Fishnet",
    id: 226,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "fishnet"),
    group: "fishnet",
  },
  {
    title: "Hue-Lightness Fishnet",
    id: 227,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "fishnet"),
    group: "fishnet",
  },
  {
    title: "Hue-Lightness Fishnet (R)",
    id: 228,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "fishnet", true),
    group: "fishnet",
  },
  {
    title: "Yliluoma 1 Fishnet",
    id: 229,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(8, "fishnet"),
    group: "fishnet",
  },
  {
    title: "Yliluoma 2 Fishnet",
    id: 230,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(8, "fishnet"),
    group: "fishnet",
  },
  {
    title: "Dot 4×4 ",
    id: 231,
    webGlFunc: ColorDither.createDotColorDither(4),
    group: "dot",
  },
  {
    title: "Dot 4×4  (R)",
    id: 232,
    webGlFunc: ColorDither.createDotColorDither(4, true),
    group: "dot",
  },
  {
    title: "Stark Dot 4×4",
    id: 233,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "dot"),
    group: "dot",
  },
  {
    title: "Hue-Lightness Dot 4×4",
    id: 234,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "dot"),
    group: "dot",
  },
  {
    title: "Hue-Lightness Dot 4×4 (R)",
    id: 235,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "dot", true),
    group: "dot",
  },
  {
    title: "Yliluoma 1 Dot 4×4",
    id: 236,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(4, "dot"),
    group: "dot",
  },
  {
    title: "Yliluoma 2 Dot 4×4",
    id: 237,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(4, "dot"),
    group: "dot",
  },
  {
    title: "Dot 8×8 ",
    id: 238,
    webGlFunc: ColorDither.createDotColorDither(8),
    group: "dot",
  },
  {
    title: "Dot 8×8  (R)",
    id: 239,
    webGlFunc: ColorDither.createDotColorDither(8, true),
    group: "dot",
  },
  {
    title: "Stark Dot 8×8",
    id: 240,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "dot"),
    group: "dot",
  },
  {
    title: "Hue-Lightness Dot 8×8",
    id: 241,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "dot"),
    group: "dot",
  },
  {
    title: "Hue-Lightness Dot 8×8 (R)",
    id: 242,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "dot", true),
    group: "dot",
  },
  {
    title: "Yliluoma 1 Dot 8×8",
    id: 243,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(8, "dot"),
    group: "dot",
  },
  {
    title: "Yliluoma 2 Dot 8×8",
    id: 244,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(8, "dot"),
    group: "dot",
  },
  {
    title: "Halftone ",
    id: 245,
    webGlFunc: ColorDither.createHalftoneColorDither(8),
    group: "halftone",
  },
  {
    title: "Halftone  (R)",
    id: 246,
    webGlFunc: ColorDither.createHalftoneColorDither(8, true),
    group: "halftone",
  },
  {
    title: "Stark Halftone",
    id: 247,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "halftone"),
    group: "halftone",
  },
  {
    title: "Hue-Lightness Halftone",
    id: 248,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "halftone"),
    group: "halftone",
  },
  {
    title: "Hue-Lightness Halftone (R)",
    id: 249,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "halftone", true),
    group: "halftone",
  },
  {
    title: "Yliluoma 1 Halftone",
    id: 250,
    webGlFunc: ColorDither.createYliluoma1OrderedDither(8, "halftone"),
    group: "halftone",
  },
  {
    title: "Yliluoma 2 Halftone",
    id: 251,
    webGlFunc: ColorDither.createYliluoma2OrderedDither(8, "halftone"),
    group: "halftone",
  },
  {
    title: "Square 2×2 ",
    id: 252,
    webGlFunc: ColorDither.createSquareColorDither(2),
    group: "square",
  },
  {
    title: "Square 2×2  (R)",
    id: 253,
    webGlFunc: ColorDither.createSquareColorDither(2, true),
    group: "square",
  },
  {
    title: "Stark Square 2×2",
    id: 254,
    webGlFunc: ColorDither.createStarkOrderedDither(2, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 2×2",
    id: 255,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(2, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 2×2 (R)",
    id: 256,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(2, "square", true),
    group: "square",
  },
  {
    title: "Square 4×4 ",
    id: 257,
    webGlFunc: ColorDither.createSquareColorDither(4),
    group: "square",
  },
  {
    title: "Square 4×4  (R)",
    id: 258,
    webGlFunc: ColorDither.createSquareColorDither(4, true),
    group: "square",
  },
  {
    title: "Stark Square 4×4",
    id: 259,
    webGlFunc: ColorDither.createStarkOrderedDither(4, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 4×4",
    id: 260,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 4×4 (R)",
    id: 261,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(4, "square", true),
    group: "square",
  },
  {
    title: "Square 8×8 ",
    id: 262,
    webGlFunc: ColorDither.createSquareColorDither(8),
    group: "square",
  },
  {
    title: "Square 8×8  (R)",
    id: 263,
    webGlFunc: ColorDither.createSquareColorDither(8, true),
    group: "square",
  },
  {
    title: "Stark Square 8×8",
    id: 264,
    webGlFunc: ColorDither.createStarkOrderedDither(8, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 8×8",
    id: 265,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 8×8 (R)",
    id: 266,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(8, "square", true),
    group: "square",
  },
  {
    title: "Square 16×16 ",
    id: 267,
    webGlFunc: ColorDither.createSquareColorDither(16),
    group: "square",
  },
  {
    title: "Square 16×16  (R)",
    id: 268,
    webGlFunc: ColorDither.createSquareColorDither(16, true),
    group: "square",
  },
  {
    title: "Stark Square 16×16",
    id: 269,
    webGlFunc: ColorDither.createStarkOrderedDither(16, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 16×16",
    id: 270,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(16, "square"),
    group: "square",
  },
  {
    title: "Hue-Lightness Square 16×16 (R)",
    id: 271,
    webGlFunc: ColorDither.createHueLightnessOrderedDither(16, "square", true),
    group: "square",
  },
];

export default {
  colorDitherGroups,
  colorDitherAlgorithms,
  knobs,
};
