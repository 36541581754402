async function convertImage(src, bg = "white", size = null) {
  const img = new Image();
  img.src = src;

  // MONOLIT 32
  let resolution = {
    width: 1440,
    height: 2560,
  };
  console.log("size", size);
  if (size === "SMALL") {
    resolution = {
      width: 1200,
      height: 1600,
    };
  }
  await img.decode();

  const canvas = document.createElement("canvas");

  canvas.width = resolution.width;
  canvas.height = resolution.height;

  var ctx = canvas.getContext("2d");
  var ratio = img.width / img.height;

  var newWidth = canvas.width;
  var newHeight = newWidth / ratio;
  if (newHeight > canvas.height) {
    newHeight = canvas.height;
    newWidth = newHeight * ratio;
  }
  var xOffset = newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
  var yOffset = newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;

  ctx.fillStyle = bg;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
  //document.body.append(canvas);

  return canvas.toDataURL("image/jpeg", 0.7);
}
export default async function (src, bg, size, uuid) {
  const base64 = await convertImage(src, bg, size);
  // img is ready to use
  console.log(`done converting`);

  return await fetch("https://api.monol.it/test", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ src, base64, uuid }),
  });
}
