<template>
  <div class="context-btn" :style="{ opacity: inIsolation ? 0 : 1 }">
    <main>
      <span v-if="label">{{ label }}</span>
      <span class="description" v-if="description">{{ description }}</span>
    </main>
    <aside v-if="icon">
      <icon :name="icon" />
    </aside>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "@/components/Icon";
export default {
  components: {
    Icon,
  },
  props: {
    label: String,
    description: String,
    icon: String,
  },
  computed: {
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
    }),
  },
};
</script>

<style lang="scss" scoped>
.context-btn {
  padding: 0 var(--spacing-gutter);
  height: 44rem;
  display: grid;
  grid-template-columns: 1fr 44rem;

  main,
  aside {
    display: flex;
    align-items: center;
    height: inherit;
  }
  main {
    flex: 1;
    justify-content: space-between;
    column-gap: 16rem;
    white-space: nowrap;
    overflow: hidden;

    span:first-child {
      flex: 1;
    }
    span.description {
      color: var(--color-fg-secondary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  aside {
    width: 44rem;
    justify-content: flex-end;
  }
}
</style>
