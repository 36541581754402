<template>
  <div class="sketch"></div>
</template>

<script>
import sketch from "./sketch";

import { interpolateNumber } from "d3-interpolate";

import { debounce } from "lodash";

export default {
  props: {
    pos: {
      type: Object,
      default() {
        return {
          x: 0,
          y: 0,
        };
      },
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gravityX: 0,
      gravityY: 0,
    };
  },
  methods: {
    init() {
      this.sketch = sketch({
        element: this.$el,
        width: this.width,
        height: this.height,
      });

      this.draw();
    },
    stop() {
      cancelAnimationFrame(this.raf);
      this.sketch.stop();
    },
    draw() {
      this.sketch.engine.world.gravity.x = this.gravityX;
      this.sketch.engine.world.gravity.y = this.gravityY;
      this.raf = requestAnimationFrame(this.draw);
    },
    move(e) {
      const gravity = interpolateNumber(-1, 1);

      this.gravityX = gravity(this.pos.x);
      this.gravityY = gravity(this.pos.y);
    },
    onResize: debounce(function () {
      this.stop();
      this.$nextTick(this.init);
    }, 0),
  },
  mounted() {
    /**
     * Set onResize observer
     */
    this.init();
  },
  beforeUnmount() {
    this.stop();
  },
  watch: {
    pos: {
      deep: true,
      handler: function () {
        this.move();
      },
    },
    height() {
      this.onResize();
    },
  },
};
</script>

<style scoped>
.sketch {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>
