<template>
  <knobs>
    <knobs-group>
      <knob-header>Presets</knob-header>
    </knobs-group>
    <knobs-group>
      <knob-header>Parameters</knob-header>
      <slider
        v-for="knob in knobs"
        :key="knob.hash"
        v-model="knob.value"
        v-bind="knob"
        :instant="false"
      />
    </knobs-group>
  </knobs>
</template>

<script>
import Knobs from "@/components/Knobs/Knobs";
import KnobsGroup from "@/components/Knobs/KnobsGroup";
import KnobHeader from "@/components/Knobs/KnobHeader";
import Slider from "@/components/Knobs/Slider";

export default {
  components: {
    Knobs,
    KnobsGroup,
    KnobHeader,
    Slider,
  },
  props: {
    schema: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      knobs: [],
    };
  },
  methods: {
    reset() {
      for (let i = 0; i < this.knobs.length; i++) {
        const key = this.knobs[i].key;
        this.knobs[i].hash = key + Math.random();
        this.knobs[i].value = this.defaults[key];
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    for (const key in this.schema) {
      const knob = this.schema[key];

      this.knobs.push({
        key,
        hash: key + Math.random(),
        label: key,
        value:
          typeof this.data[key] !== "undefined" ? this.data[key] : knob.default,
        colors: knob.type === "color",
        ...knob,
      });
    }
  },
  computed: {
    defaults() {
      const obj = {};
      for (const key in this.schema) {
        obj[key] = this.schema[key].default;
      }
      return obj;
    },
    payload() {
      const obj = {};

      this.knobs.forEach((knob) => {
        obj[knob.key] = knob.value;
      });

      return obj;
    },
  },
  watch: {
    payload() {
      this.$emit("changed", this.payload);
    },
  },
};
</script>

<style scoped></style>
