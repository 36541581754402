<template>
  <div class="depth-map">
    <canvas ref="canvas" :style="style" />
  </div>
</template>

<script>
import * as PIXI from "pixi.js";
import gsap from "gsap";

export default {
  props: {
    offset: {
      type: [Number, String],
      default: 4,
    },
    src: {
      type: String,
      default: require("@/assets/img/depth/test.jpg"),
    },
    depthSrc: {
      type: String,
      default: require("@/assets/img/depth/test_depth.jpg"),
    },
    scale: {
      type: Number,
      default: window.devicePixelRatio,
    },
    pos: {
      type: Object,
      default() {
        return { x: 0, y: 0 };
      },
    },
    y: {
      type: Number,
      default: 0,
    },
    playing: Boolean,
  },
  data() {
    return {
      width: 0,
      height: 0,
      mouseX: 0,
      mouseY: 0,
      id: `ìd-${Math.floor(Math.random() * 200)}`,
      tween: {
        x: 0,
        y: 0,
      },
      fg: this.generateId("fg"),
      depth: this.generateId("depth"),
    };
  },
  methods: {
    init() {
      this.onResize();
      this.renderer = new PIXI.autoDetectRenderer({
        width: this.width * this.scale,
        height: this.height * this.scale,
        view: this.$refs.canvas,
      });

      this.stage = new PIXI.Container();
      this.container = new PIXI.Container();
      this.foreground = new PIXI.Container();
      this.stage.addChild(this.container);
      this.stage.addChild(this.foreground);

      this.loader = new PIXI.Loader();

      this.loader.add(this.fg, this.src);
      this.loader.add(this.depth, this.depthSrc);

      this.loader.load(this.onLoaded);
    },
    onResize() {
      this.width = this.$el.offsetWidth;
      this.height = this.$el.offsetHeight;

      if (this.renderer) {
        this.renderer.resize(this.width * this.scale, this.height * this.scale);
      }

      if (this.foregroundSprite) {
        // Fill entire canvas
        this.setSize("cover");
      }
    },
    onLoaded(loader, resources) {
      this.foregroundSprite = new PIXI.Sprite(resources[this.fg].texture);

      this.foreground.addChild(this.foregroundSprite);

      this.depthSprite = new PIXI.Sprite(resources[this.depth].texture);
      this.displacementFilter = new PIXI.filters.DisplacementFilter(this.depthSprite, 0);

      this.foregroundSprite.filters = [this.displacementFilter];

      // Fill entire canvas
      this.setSize("cover");
      this.draw();
      this.$emit("loaded");
    },
    draw() {
      const x = this.tween.x * this.width;
      const y = this.tween.y * this.height;

      if (this.displacementFilter) {
        this.displacementFilter.scale.x = (this.width / 2 - x) / this.offset;
        this.displacementFilter.scale.y = (this.height / 2 - y) / this.offset;
      }

      if (this.depthSprite) {
        this.foregroundSprite.addChild(this.depthSprite);
        this.depthSprite.renderable = false;
      }

      this.renderer.render(this.stage);
    },
    setSize(type) {
      const width = this.foregroundSprite.width;
      const height = this.foregroundSprite.height;

      var winratio = this.width / this.height;
      var spratio = width / height;
      var scale = 1;
      var pos = new PIXI.Point(0, 0);

      if (type == "cover" ? winratio > spratio : winratio < spratio) {
        // Photo is wider than background
        scale = this.width / width + 0.05;
        pos.y = -(height * scale - this.height) / 2;
      } else {
        // Photo is taller than background
        scale = this.height / height + 0.05;
        pos.y = -10;
        pos.x = -(width * scale * this.scale - this.width * this.scale) / 2;
      }

      this.stage.scale = new PIXI.Point(scale * this.scale, scale * this.scale);
      this.stage.position = pos;
    },
    generateId(prefix) {
      return prefix + "-" + Math.random().toString(36).substring(7);
    },
  },
  watch: {
    pos: {
      deep: true,
      handler() {
        gsap.to(this.tween, 1.5, {
          ease: "back.out(1.7)",
          x: this.pos.x,
          y: this.pos.y,
          onUpdate: this.draw,
        });
      },
    },
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  mounted() {
    this.$nextTick(this.init);
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    if (this.animation) this.animation.kill();
    window.removeEventListener("resize", this.onResize);

    this.foregroundSprite.destroy();
    this.depthSprite.destroy();
    //this.displacementFilter.destroy()

    this.foreground.destroy();
    this.container.destroy();
    this.stage.destroy();

    this.renderer.destroy();
  },
};
</script>

<style scoped lang="scss">
.depth-map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
</style>
