<template>
  <ul class="tabs">
    <slot />
  </ul>
</template>

<script>
export default {};
</script>

<style scoped>
.tabs {
  display: flex;
  //flex-direction: column;
  column-gap: 4rem;
}
</style>
