import { createRouter, createWebHistory } from "vue-router";

/* 
Main views
*/
import Library from "@/views/Library";

/* Store */
import Store from "@/views/Store";
import Magazines from "@/components/Magazines/Magazines";
import Exhibitions from "@/views/Exhibitions";

import Artwork from "@/views/Artwork";
import Settings from "@/views/Settings";

import Tool from "@/views/Tool/Tool";

import RenderView from "@/views/RenderView";
import RenderFile from "@/views/RenderFile";

const DitherStudio = () => import("@/components/DitherStudio");

/* 
Playground 
*/
import Glass from "@/components/Glass/Glass";
import Depth from "@/views/_Playground/Depth";

const routes = [
  {
    path: "/",
    name: "Library",
    component: Library,
    meta: {},
  },
  {
    path: "/gallery",
    name: "Store",
    component: Store,
    meta: {
      view: "Store",
      hideNavigationBar: true,
      subview: true,
    },
  },
  {
    path: "/magazines/:id?",
    name: "Magazines",
    component: Magazines,
    meta: {
      view: "Store",
      hideNavigationBar: true,
      subview: true,
    },
  },
  {
    path: "/exhibitions",
    name: "Exhibitions",
    component: Exhibitions,
    meta: {
      view: "Store",
      hideNavigationBar: true,
      subview: true,
    },
  },
  {
    path: "/:id",
    name: "Artwork",
    component: Artwork,
    meta: {
      hideNavigationBar: true,
      subview: true,
    },
  },
  {
    path: "/store/:id",
    name: "StoreArtwork",
    component: Artwork,
    meta: {
      hideNavigationBar: true,
      subview: true,
    },
  },
  {
    path: "/render/:id",
    name: "RenderView",
    component: RenderView,
    meta: {
      native: true,
    },
  },
  {
    path: "/rf/:id",
    name: "RenderFile",
    component: RenderFile,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/glass",
    name: "Glass",
    component: Glass,
  },
  {
    path: "/depth",
    name: "Depth",
    component: Depth,
  },
  {
    path: "/add",
    name: "Tool",
    component: Tool,
  },
  {
    path: "/edit/:id",
    name: "ToolEdit",
    component: Tool,
  },
  {
    path: "/dither",
    name: "DitherStudio",
    component: DitherStudio,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});

export default router;
