export default [
  {
    id: "dG3h6-7OPPKC",
    artistId: "DsJHcIjjD_bo",
    type: "code",
    name: "Hedgehog",
    code: require("./ukendt-kunstner/hedgehog").default,
  },
  {
    id: "dG3h6-7OPPKW",
    artistId: "DsJHcIjjD_bo",
    type: "code",
    name: "Weave 02",
    code: require("./ukendt-kunstner/weave2").default,
  },
  {
    id: "dG3h6-7OPP1W",
    artistId: "DsJHcIjjD_bo",
    type: "code",
    name: "Weave 01",
    code: require("./ukendt-kunstner/weave").default,
  },
  {
    id: "dG3h8f7OPP1W",
    artistId: "DsJHcIjjD_bo",
    name: "Heaven is a Prison",
    src: "mark-mc-knight/01",
    theme: "gray",
  },
  {
    id: "WncLryaqb16z",
    artistId: "NSpvOEKGRvSn",
    type: "series",
    name: "Surrealist portraits",
  },
  {
    id: "dnopf1UeKaJL",
    artistId: "iYFyN_5yuwE_",
    name: "Blueprint",
    dominantColor: [240, 240, 240],
    type: "series",
  },
  {
    id: "VJccCBIFvNsP",
    artistId: "iYFyN_5yuwE_",
    name: "Feelings",
    dominantColor: [240, 240, 240],
    type: "series",
  },
  {
    id: "tir_Z2gpCD-l",
    artistId: "iYFyN_5yuwE_",
    name: "Modular",
    dominantColor: [240, 240, 240],
    type: "series",
  },
  {
    id: "OQoZLuoudSbe",
    artistId: "eI8VDj7n3Dg6",
    type: "series",
    name: "Portraits",
    dominantColor: [17, 17, 17],
    theme: "darkgray",
  },
  {
    id: "dG3h6-7OPPKM",
    artistId: "OljjB3W2-UmU",
    type: "series",
    name: "Aerials",
    dominantColor: [131, 131, 131],
    theme: "gray",
  },
  {
    id: "QDeLSdlYALmG",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/01",
    dominantColor: [175, 175, 175],
    palette: [
      [170, 170, 170],
      [20, 20, 20],
      [76, 76, 76],
      [84, 84, 84],
    ],
  },
  {
    id: "xRuEPm9kwyOn",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/02",
    dominantColor: [16, 16, 16],
    palette: [
      [213, 213, 213],
      [16, 16, 16],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "jUjEHsqDirfd",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/03",
    dominantColor: [193, 193, 193],
    palette: [
      [189, 189, 189],
      [37, 37, 37],
      [92, 92, 92],
      [100, 100, 100],
    ],
  },
  {
    id: "UXSOKxWXCrQf",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/04",
    dominantColor: [48, 48, 48],
    palette: [
      [59, 59, 59],
      [204, 204, 204],
      [168, 168, 168],
      [156, 156, 156],
    ],
  },
  {
    id: "0YIpnjJaAHEI",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/05",
    dominantColor: [39, 39, 39],
    palette: [
      [54, 54, 54],
      [211, 211, 211],
      [159, 159, 159],
      [148, 148, 148],
    ],
  },
  {
    id: "4YpGWtNWSOqn",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/06",
    theme: "black",
    position: "center",
    dominantColor: [183, 183, 183],
    palette: [
      [173, 173, 173],
      [40, 40, 40],
      [80, 80, 80],
      [92, 92, 92],
    ],
  },
  {
    id: "FFouNI6JhBVF",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/07",
    dominantColor: [59, 59, 59],
    palette: [
      [74, 74, 74],
      [221, 221, 221],
      [175, 175, 175],
      [164, 164, 164],
    ],
  },
  {
    id: "DYWMtBoHc-7L",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/08",
    dominantColor: [194, 194, 194],
    palette: [
      [27, 27, 27],
      [194, 194, 194],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "3n4BlfYGUyhS",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/09",
    dominantColor: [200, 200, 200],
    palette: [
      [190, 190, 190],
      [31, 31, 31],
      [87, 87, 87],
      [100, 100, 100],
    ],
  },
  {
    id: "bDEJfEn6oxZY",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/10",
    dominantColor: [52, 52, 52],
    palette: [
      [61, 61, 61],
      [216, 216, 216],
      [164, 164, 164],
      [156, 156, 156],
    ],
  },
  {
    id: "FGL4LLEKFEux",
    artistId: "eI8VDj7n3Dg6",
    src: "bruce-gilden/11",
    dominantColor: [153, 153, 153],
    palette: [
      [162, 162, 162],
      [36, 36, 36],
      [76, 76, 76],
      [84, 84, 84],
    ],
  },
  {
    id: "IU7NL-rWusoo",
    seriesId: "OQoZLuoudSbe",
    src: "bruce-gilden/portraits/01",
    dominantColor: [14, 14, 14],
    palette: [
      [190, 190, 190],
      [14, 14, 14],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "FgISPgTpi4y9",
    seriesId: "OQoZLuoudSbe",
    src: "bruce-gilden/portraits/02",
    dominantColor: [15, 15, 15],
    palette: [
      [163, 163, 163],
      [15, 15, 15],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "P-DMTky7eIr1",
    seriesId: "OQoZLuoudSbe",
    src: "bruce-gilden/portraits/03",
    dominantColor: [16, 16, 16],
    palette: [
      [157, 157, 157],
      [16, 16, 16],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "v0MLa5v0UvdT",
    seriesId: "OQoZLuoudSbe",
    src: "bruce-gilden/portraits/04",
    dominantColor: [16, 16, 16],
    palette: [
      [194, 194, 194],
      [16, 16, 16],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "yNWvis7bnQzj",
    artistId: "Jc5mry_mbXGp",
    src: "chadwick-tyler/01",
    dominantColor: [59, 59, 59],
    palette: [
      [76, 76, 76],
      [216, 216, 216],
      [172, 172, 172],
      [164, 164, 164],
    ],
  },
  {
    id: "OmkKLwb1OgXJ",
    artistId: "Jc5mry_mbXGp",
    src: "chadwick-tyler/02",
    dominantColor: [106, 106, 106],
    palette: [
      [90, 90, 90],
      [191, 191, 191],
      [172, 172, 172],
      [164, 164, 164],
    ],
  },
  {
    id: "y2qzL16OV51T",
    artistId: "Jc5mry_mbXGp",
    src: "chadwick-tyler/03",
    dominantColor: [194, 194, 194],
    palette: [
      [185, 185, 185],
      [36, 36, 36],
      [92, 92, 92],
      [100, 100, 100],
    ],
  },
  {
    id: "ulSyWhdg4HIa",
    artistId: "Jc5mry_mbXGp",
    src: "chadwick-tyler/04",
    dominantColor: [189, 189, 189],
    palette: [
      [178, 178, 178],
      [31, 31, 31],
      [71, 71, 71],
      [84, 84, 84],
    ],
  },
  {
    id: "5K7laQUceuWx",
    artistId: "GS5x_NWUWe7e",
    src: "drew-vickers/01",
    theme: "gray",
    dominantColor: [218, 218, 218],
    palette: [
      [46, 46, 46],
      [218, 218, 218],
      [98, 98, 98],
      [116, 116, 116],
    ],
  },
  {
    id: "CGDmBVTJnjsI",
    artistId: "GS5x_NWUWe7e",
    src: "drew-vickers/02",
    dominantColor: [136, 136, 136],
    palette: [
      [175, 175, 175],
      [26, 26, 26],
      [68, 68, 68],
      [76, 76, 76],
    ],
  },
  {
    id: "vU0sGfIjZu3W",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/01",
    dominantColor: [36, 36, 36],
    palette: [
      [50, 50, 50],
      [212, 212, 212],
      [159, 159, 159],
      [148, 148, 148],
    ],
  },
  {
    id: "-nGtQo938-VX",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/02",
    dominantColor: [240, 240, 240],
    palette: [
      [19, 19, 19],
      [240, 240, 240],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "XTKN03ebSgkV",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/03",
    dominantColor: [214, 214, 214],
    palette: [
      [42, 42, 42],
      [214, 214, 214],
      [156, 156, 156],
      [140, 140, 140],
    ],
  },
  {
    id: "1JZA5lT_YT03",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/04",
    dominantColor: [15, 15, 15],
    palette: [
      [16, 16, 16],
      [203, 203, 203],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "wToIFDZc9o9F",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/05",
    dominantColor: [200, 200, 200],
    palette: [
      [41, 41, 41],
      [200, 200, 200],
      [148, 148, 148],
      [140, 140, 140],
    ],
  },
  {
    id: "6CEA-ngWEOdw",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/06",
    dominantColor: [4, 4, 4],
    palette: [
      [5, 5, 5],
      [199, 199, 199],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "3lTPvIzliDPp",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/07",
    dominantColor: [19, 19, 19],
    palette: [
      [189, 189, 189],
      [19, 19, 19],
      [116, 116, 116],
      [124, 124, 124],
    ],
  },
  {
    id: "SlgW9AJ-QWVI",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/08",
    dominantColor: [4, 4, 4],
    palette: [
      [5, 5, 5],
      [189, 189, 189],
      [116, 116, 116],
      [124, 124, 124],
    ],
  },
  {
    id: "eB7sRW7iefQB",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/09",
    dominantColor: [213, 213, 213],
    palette: [
      [19, 19, 19],
      [213, 213, 213],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "Fehq4E-VuDVE",
    artistId: "oYT8f_pQkR5Q",
    src: "fabian-fohrer/10",
    dominantColor: [4, 4, 4],
    palette: [
      [5, 5, 5],
      [201, 201, 201],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "Ow0OZO1blRos",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/01",
    name: "Multiple tension, 1964",
    dominantColor: [35, 35, 35],
    palette: [
      [224, 224, 224],
      [35, 35, 35],
      [156, 156, 156],
      [140, 140, 140],
    ],
  },
  {
    id: "TWNh37BLWFN7",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/02",
    name: "Curves inserted in the verticals, 1948",
    dominantColor: [215, 215, 215],
    palette: [
      [199, 199, 199],
      [65, 65, 65],
      [96, 96, 96],
      [108, 108, 108],
    ],
  },
  {
    id: "GRlfb30Sc08m",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/03",
    name: "Man-staircase, 1950",
    theme: "black",
    dominantColor: [4, 4, 4],
    palette: [
      [5, 5, 5],
      [226, 226, 226],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "pc4VSPnp10ua",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/04",
    name: "Photogram, 50s",
    dominantColor: [218, 218, 218],
    palette: [
      [216, 216, 216],
      [15, 15, 15],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "wLfM70YcRnpj",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/05",
    name: "blackness, 1953",
    dominantColor: [196, 196, 196],
    palette: [
      [30, 30, 30],
      [196, 196, 196],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "Z09rmhxQP8jy",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/06",
    name: "Dance, 1965",
    dominantColor: [221, 221, 221],
    palette: [
      [221, 221, 221],
      [15, 15, 15],
      [108, 108, 108],
      [116, 116, 116],
    ],
  },
  {
    id: "8AasV4lJw54S",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/07",
    name: "Order and unconscious, 1950",
    dominantColor: [225, 225, 225],
    palette: [
      [223, 223, 223],
      [65, 65, 65],
      [124, 124, 124],
      [132, 132, 132],
    ],
  },
  {
    id: "cWyy4jQZXL9E",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/08",
    name: "Vibrating interference, 1963",
    dominantColor: [225, 225, 225],
    palette: [
      [24, 24, 24],
      [225, 225, 225],
      [95, 95, 95],
      [116, 116, 116],
    ],
  },
  {
    id: "PkRovCeHFsR9",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/09",
    name: "Figure, circa 1965",
    dominantColor: [38, 38, 38],
    palette: [
      [238, 238, 238],
      [38, 38, 38],
      [124, 124, 124],
      [132, 132, 132],
    ],
  },
  {
    id: "6pKu0ObQtvNp",
    artistId: "sbCTgXkrJvsu",
    src: "franco-grignani/10",
    name: "Camouflage, 1953",
    dominantColor: [12, 12, 12],
    palette: [
      [219, 219, 219],
      [12, 12, 12],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "f-BFsYXSDWo5",
    artistId: "BNxFg9LuOkg0",
    src: "gareth-mcconnell/01",
    dominantColor: [194, 194, 194],
    palette: [
      [29, 29, 29],
      [194, 194, 194],
      [135, 135, 135],
      [124, 124, 124],
    ],
  },
  {
    id: "eBjMyDpGC1me",
    artistId: "BNxFg9LuOkg0",
    src: "gareth-mcconnell/02",
    dominantColor: [196, 196, 196],
    palette: [
      [56, 56, 56],
      [196, 196, 196],
      [156, 156, 156],
      [148, 148, 148],
    ],
  },
  {
    id: "o5q-V7I0ybpI",
    artistId: "BNxFg9LuOkg0",
    src: "gareth-mcconnell/03",
    dominantColor: [209, 209, 209],
    palette: [
      [197, 197, 197],
      [55, 55, 55],
      [81, 81, 81],
      [100, 100, 100],
    ],
  },
  {
    id: "QUNKTdoJoa4X",
    artistId: "BNxFg9LuOkg0",
    src: "gareth-mcconnell/04",
    dominantColor: [32, 32, 32],
    palette: [
      [226, 226, 226],
      [32, 32, 32],
      [140, 140, 140],
      [132, 132, 132],
    ],
  },
  {
    id: "IqqMzGyTHdh6",
    artistId: "BNxFg9LuOkg0",
    src: "gareth-mcconnell/05",
    dominantColor: [175, 175, 175],
    palette: [
      [179, 179, 179],
      [87, 87, 87],
      [116, 116, 116],
      [108, 108, 108],
    ],
  },
  {
    id: "Yg5c8kU3wdKT",
    artistId: "BNxFg9LuOkg0",
    src: "gareth-mcconnell/06",
    dominantColor: [8, 8, 8],
    palette: [
      [10, 10, 10],
      [190, 190, 190],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "sbpAumaDFLsq",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/01",
    theme: "darkgray",
    dominantColor: [40, 40, 40],
    palette: [
      [207, 207, 207],
      [40, 40, 40],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "h0YmpXUqCnxi",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/02",
    theme: "gray",
    dominantColor: [164, 164, 164],
    palette: [
      [29, 29, 29],
      [164, 164, 164],
      [128, 128, 128],
      [116, 116, 116],
    ],
  },
  {
    id: "2ZLeAxW2SrNi",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/03",
    dominantColor: [20, 20, 20],
    palette: [
      [32, 32, 32],
      [141, 141, 141],
      [157, 157, 157],
      [181, 181, 181],
    ],
  },
  {
    id: "H3KE45ylaFSu",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/04",
    theme: "black",
    position: "start",
    dominantColor: [19, 19, 19],
    palette: [
      [188, 188, 188],
      [19, 19, 19],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "BQwMLBfS22jW",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/05",
    dominantColor: [213, 213, 213],
    palette: [
      [204, 204, 204],
      [30, 30, 30],
      [92, 92, 92],
      [108, 108, 108],
    ],
  },
  {
    id: "ljDR3c1w7Isi",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/06",
    dominantColor: [179, 179, 179],
    palette: [
      [17, 17, 17],
      [179, 179, 179],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "Sjicoe-c4og0",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/07",
    dominantColor: [5, 5, 5],
    palette: [
      [171, 171, 171],
      [5, 5, 5],
      [108, 108, 108],
      [100, 100, 100],
    ],
  },
  {
    id: "apGof8Ht3ttg",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/08",
    dominantColor: [21, 21, 21],
    palette: [
      [195, 195, 195],
      [21, 21, 21],
      [79, 79, 79],
      [100, 100, 100],
    ],
  },
  {
    id: "jQkGqcxnWNNl",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/09",
    dominantColor: [28, 28, 28],
    palette: [
      [201, 201, 201],
      [28, 28, 28],
      [92, 92, 92],
      [100, 100, 100],
    ],
  },
  {
    id: "IvsEhNnsQh4s",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/10",
    dominantColor: [46, 46, 46],
    palette: [
      [60, 60, 60],
      [179, 179, 179],
      [156, 156, 156],
      [148, 148, 148],
    ],
  },
  {
    id: "1Ve4CiW_2vfD",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/11",
    dominantColor: [225, 225, 225],
    palette: [
      [220, 220, 220],
      [60, 60, 60],
      [116, 116, 116],
      [124, 124, 124],
    ],
  },
  {
    id: "GGIaHQ8Hr3dP",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/12",
    theme: "gray",
    dominantColor: [200, 200, 200],
    palette: [
      [21, 21, 21],
      [200, 200, 200],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "zdnF-CrNlASM",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/13",
    dominantColor: [42, 42, 42],
    palette: [
      [67, 67, 67],
      [225, 225, 225],
      [175, 175, 175],
      [164, 164, 164],
    ],
  },
  {
    id: "DoZ5zy7UP1Uq",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/14",
    dominantColor: [15, 15, 15],
    palette: [
      [17, 17, 17],
      [183, 183, 183],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "B9W93YlX4k4k",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/15",
    dominantColor: [225, 225, 225],
    palette: [
      [224, 224, 224],
      [39, 39, 39],
      [116, 116, 116],
      [124, 124, 124],
    ],
  },
  {
    id: "JyNlCQp1Ulnl",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/16",
    dominantColor: [160, 160, 160],
    palette: [
      [13, 13, 13],
      [160, 160, 160],
      [116, 116, 116],
      [108, 108, 108],
    ],
  },
  {
    id: "NHDSXwpw2iER",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/17",
    dominantColor: [11, 11, 11],
    palette: [
      [14, 14, 14],
      [130, 130, 130],
      [116, 116, 116],
      [108, 108, 108],
    ],
  },
  {
    id: "9oFvZuyGBZpN",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/18",
    dominantColor: [29, 29, 29],
    palette: [
      [193, 193, 193],
      [29, 29, 29],
      [140, 140, 140],
      [132, 132, 132],
    ],
  },
  {
    id: "LVtQlxxm9TqF",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/19",
    theme: "gray",
    dominantColor: [6, 6, 6],
    palette: [
      [167, 167, 167],
      [6, 6, 6],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "3IgNLcnCEypS",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/20",
    dominantColor: [214, 214, 214],
    palette: [
      [40, 40, 40],
      [214, 214, 214],
      [140, 140, 140],
      [132, 132, 132],
    ],
  },
  {
    id: "Dn4tzmTjOG8j",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/21",
    dominantColor: [27, 27, 27],
    palette: [
      [217, 217, 217],
      [27, 27, 27],
      [108, 108, 108],
      [116, 116, 116],
    ],
  },
  {
    id: "Ecb6q_wszLrH",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/22",
    dominantColor: [11, 11, 11],
    palette: [
      [16, 16, 16],
      [181, 181, 181],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "UhEqXpywAye8",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/23",
    dominantColor: [116, 116, 116],
    palette: [
      [95, 95, 95],
      [205, 205, 205],
      [172, 172, 172],
      [180, 180, 180],
    ],
  },
  {
    id: "1Kna2gWcq2ty",
    artistId: "NSpvOEKGRvSn",
    src: "jack-davison/24",
    dominantColor: [167, 167, 167],
    palette: [
      [12, 12, 12],
      [167, 167, 167],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "WncLryaqb1z1",
    seriesId: "WncLryaqb16z",
    src: "jack-davison/surrealist-portraits/01",
    dominantColor: [27, 27, 27],
    palette: [
      [187, 187, 187],
      [27, 27, 27],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "WncLryaqb1z2",
    seriesId: "WncLryaqb16z",
    src: "jack-davison/surrealist-portraits/02",
    dominantColor: [32, 32, 32],
    palette: [
      [217, 217, 217],
      [32, 32, 32],
      [157, 157, 157],
      [132, 132, 132],
    ],
  },
  {
    id: "WncLryaqb1z3",
    seriesId: "WncLryaqb16z",
    src: "jack-davison/surrealist-portraits/03",
    dominantColor: [26, 26, 26],
    palette: [
      [226, 226, 226],
      [26, 26, 26],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "WncLryaqb1z4",
    seriesId: "WncLryaqb16z",
    src: "jack-davison/surrealist-portraits/04",
    dominantColor: [21, 21, 21],
    palette: [
      [213, 213, 213],
      [21, 21, 21],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "WncLryaqb1z5",
    seriesId: "WncLryaqb16z",
    src: "jack-davison/surrealist-portraits/05",
    dominantColor: [27, 27, 27],
    palette: [
      [180, 180, 180],
      [27, 27, 27],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "WncLryaqb1z6",
    seriesId: "WncLryaqb16z",
    src: "jack-davison/surrealist-portraits/06",
    dominantColor: [27, 27, 27],
    palette: [
      [199, 199, 199],
      [27, 27, 27],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "A3DTlVuNg_wn",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/01",
    dominantColor: [188, 188, 188],
    palette: [
      [181, 181, 181],
      [46, 46, 46],
      [92, 92, 92],
      [100, 100, 100],
    ],
  },
  {
    id: "Ghc1rV6x2wro",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/02",
    dominantColor: [220, 220, 220],
    palette: [
      [36, 36, 36],
      [220, 220, 220],
      [148, 148, 148],
      [140, 140, 140],
    ],
  },
  {
    id: "bLta9fe16-bj",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/03",
    theme: "gray",
    dominantColor: [170, 170, 170],
    palette: [
      [8, 8, 8],
      [170, 170, 170],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "PugkTZ9k7rd5",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/04",
    dominantColor: [29, 29, 29],
    palette: [
      [33, 33, 33],
      [188, 188, 188],
      [140, 140, 140],
      [132, 132, 132],
    ],
  },
  {
    id: "sLBETyjvsLyv",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/05",
    dominantColor: [193, 193, 193],
    palette: [
      [188, 188, 188],
      [48, 48, 48],
      [100, 100, 100],
      [92, 92, 92],
    ],
  },
  {
    id: "Z1zMKQFw0Wr1",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/06",
    dominantColor: [44, 44, 44],
    palette: [
      [54, 54, 54],
      [178, 178, 178],
      [148, 148, 148],
      [140, 140, 140],
    ],
  },
  {
    id: "S71svFQaHHSC",
    artistId: "OljjB3W2-UmU",
    src: "jakob-henner/07",
    theme: "gray",
    dominantColor: [30, 31, 34],
    palette: [
      [38, 39, 42],
      [201, 201, 201],
      [140, 140, 148],
      [140, 148, 147],
    ],
  },
  {
    id: "mVlaVPQBOiE3",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/01",
    dominantColor: [61, 61, 61],
    palette: [
      [74, 74, 74],
      [192, 192, 192],
      [164, 164, 164],
      [156, 156, 156],
    ],
  },
  {
    id: "iyRWkgMmJC2n",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/02",
    dominantColor: [215, 215, 215],
    palette: [
      [210, 210, 210],
      [71, 71, 71],
      [108, 108, 108],
      [116, 116, 116],
    ],
  },
  {
    id: "blYMGYt6f3kZ",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/03",
    dominantColor: [26, 26, 26],
    palette: [
      [194, 194, 194],
      [26, 26, 26],
      [139, 139, 139],
      [124, 124, 124],
    ],
  },
  {
    id: "2_-3qyOm6d5t",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/04",
    dominantColor: [80, 80, 80],
    palette: [
      [82, 82, 82],
      [213, 213, 213],
      [156, 156, 156],
      [164, 164, 164],
    ],
  },
  {
    id: "TyO8NAhNbUl5",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/05",
    dominantColor: [197, 197, 197],
    palette: [
      [183, 183, 183],
      [40, 40, 40],
      [80, 80, 80],
      [92, 92, 92],
    ],
  },
  {
    id: "jZC8Rgj2Ag04",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/06",
    dominantColor: [172, 172, 172],
    palette: [
      [35, 35, 35],
      [172, 172, 172],
      [135, 135, 135],
      [124, 124, 124],
    ],
  },
  {
    id: "zwheMfcWRYAf",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/07",
    dominantColor: [222, 222, 222],
    palette: [
      [212, 212, 212],
      [84, 84, 84],
      [108, 108, 108],
      [116, 116, 116],
    ],
  },
  {
    id: "mwheMfcWRMJF",
    seriesId: "dG3h6-7OPPKM",
    src: "jakob-henner/tekstur/08",
    dominantColor: [222, 222, 222],
    palette: [
      [212, 212, 212],
      [84, 84, 84],
      [108, 108, 108],
      [116, 116, 116],
    ],
  },
  {
    id: "Oezs1KYx2UUI",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/01",
    dominantColor: [211, 211, 211],
    palette: [
      [191, 191, 191],
      [43, 43, 43],
      [74, 74, 74],
      [92, 92, 92],
    ],
  },
  {
    id: "0ANuPElnwvmg",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/02",
    dominantColor: [195, 195, 195],
    palette: [
      [190, 190, 190],
      [60, 60, 60],
      [100, 100, 100],
      [108, 108, 108],
    ],
  },
  {
    id: "t-5ovYHjy3N1",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/03",
    dominantColor: [221, 221, 221],
    palette: [
      [217, 217, 217],
      [36, 36, 36],
      [108, 108, 108],
      [116, 116, 116],
    ],
  },
  {
    id: "5FXMQ_i4r_8N",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/04",
    dominantColor: [190, 190, 190],
    palette: [
      [179, 179, 179],
      [51, 51, 51],
      [84, 84, 84],
      [92, 92, 92],
    ],
  },
  {
    id: "mmIh3I3ZzX4J",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/05",
    dominantColor: [46, 46, 46],
    palette: [
      [57, 57, 57],
      [169, 169, 169],
      [140, 140, 140],
      [132, 132, 132],
    ],
  },
  {
    id: "JzUhZNQ6-Qlf",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/06",
    dominantColor: [202, 202, 202],
    palette: [
      [189, 189, 189],
      [44, 44, 44],
      [73, 73, 73],
      [86, 86, 86],
    ],
  },
  {
    id: "IBSZGrMl_wDT",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/07",
    dominantColor: [22, 22, 22],
    palette: [
      [26, 26, 26],
      [208, 208, 208],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "h1NwoDVPdalt",
    artistId: "b7c10uSdpak_",
    src: "julia-johnson/08",
    dominantColor: [185, 185, 185],
    palette: [
      [168, 168, 168],
      [48, 48, 48],
      [76, 76, 76],
      [84, 84, 84],
    ],
  },
  {
    id: "hPVtbbKLeq7M",
    artistId: "HFSrgKoZVfZJ",
    src: "kalen-hollomon/01",
    theme: "gray",
    dominantColor: [25, 25, 25],
    palette: [
      [205, 205, 205],
      [25, 25, 25],
      [139, 139, 139],
      [124, 124, 124],
    ],
  },
  {
    id: "w00FEyLoigQZ",
    artistId: "HFSrgKoZVfZJ",
    src: "kalen-hollomon/02",
    theme: "gray",
    dominantColor: [195, 195, 195],
    palette: [
      [189, 189, 189],
      [12, 12, 12],
      [88, 88, 88],
      [100, 100, 100],
    ],
  },
  {
    id: "gNEDbGgdJpYg",
    artistId: "HFSrgKoZVfZJ",
    src: "kalen-hollomon/03",
    theme: "darkgray",
    dominantColor: [229, 229, 229],
    palette: [
      [217, 217, 217],
      [63, 63, 63],
      [116, 116, 116],
      [124, 124, 124],
    ],
  },
  {
    id: "U6Lwd2vaJC9v",
    artistId: "TFNAad6Qb6lP",
    src: "kobayashi-ikki/01",
    dominantColor: [4, 4, 4],
    palette: [
      [5, 5, 5],
      [198, 198, 198],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "0lhOhyYo7Nm3",
    artistId: "TFNAad6Qb6lP",
    src: "kobayashi-ikki/02",
    dominantColor: [184, 184, 184],
    palette: [
      [183, 183, 183],
      [5, 5, 5],
      [83, 83, 83],
      [100, 100, 100],
    ],
  },
  {
    id: "IZo1Vu_eIdqc",
    artistId: "TFNAad6Qb6lP",
    src: "kobayashi-ikki/03",
    dominantColor: [172, 172, 172],
    palette: [
      [161, 161, 161],
      [30, 30, 30],
      [76, 76, 76],
      [84, 84, 84],
    ],
  },
  {
    id: "xdyvyfgUDsOB",
    artistId: "TFNAad6Qb6lP",
    src: "kobayashi-ikki/04",
    dominantColor: [166, 166, 166],
    palette: [
      [165, 165, 165],
      [4, 4, 4],
      [84, 84, 84],
      [76, 76, 76],
    ],
  },
  {
    id: "bOyvyfLAKsOB",
    artistId: "TFNAad6Qb6lP",
    src: "kobayashi-ikki/05",
    dominantColor: [29, 29, 29],
    palette: [
      [215, 215, 215],
      [29, 29, 29],
      [148, 148, 148],
      [132, 132, 132],
    ],
  },
  {
    id: "h0JSlYueF1QF",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/01",
    dominantColor: [173, 173, 173],
    palette: [
      [11, 11, 11],
      [173, 173, 173],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "SYhGdBci71WA",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/02",
    dominantColor: [174, 174, 174],
    palette: [
      [13, 13, 13],
      [174, 174, 174],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "fRJQnHuVDmu0",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/03",
    dominantColor: [16, 16, 16],
    palette: [
      [170, 170, 170],
      [16, 16, 16],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "L6QJXugV7h6R",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/04",
    dominantColor: [20, 20, 20],
    palette: [
      [178, 178, 178],
      [20, 20, 20],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "Uetzol9G5VyM",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/05",
    dominantColor: [179, 179, 179],
    palette: [
      [167, 167, 167],
      [14, 14, 14],
      [68, 68, 68],
      [76, 76, 76],
    ],
  },
  {
    id: "X4A8wOXf_38Q",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/06",
    dominantColor: [211, 211, 211],
    palette: [
      [33, 33, 33],
      [211, 211, 211],
      [148, 148, 148],
      [132, 132, 132],
    ],
  },
  {
    id: "9eTHw9Oo7SFP",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/07",
    dominantColor: [189, 189, 189],
    palette: [
      [24, 24, 24],
      [189, 189, 189],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "cxP09OJGz-A3",
    artistId: "KtY1vk_kzQ6Q",
    src: "loackme/08",
    dominantColor: [26, 26, 26],
    palette: [
      [189, 189, 189],
      [26, 26, 26],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "6b5Rbt2Scgr2",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/01",
    dominantColor: [201, 201, 201],
    palette: [
      [50, 50, 50],
      [201, 201, 201],
      [162, 162, 162],
      [148, 148, 148],
    ],
  },
  {
    id: "e1zQZaTimYdp",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/02",
    dominantColor: [9, 9, 9],
    palette: [
      [11, 11, 11],
      [218, 218, 218],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "9kw5U0PaNdkB",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/03",
    dominantColor: [201, 201, 201],
    palette: [
      [24, 24, 24],
      [201, 201, 201],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "7zYnldpq88pj",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/04",
    dominantColor: [216, 216, 216],
    palette: [
      [20, 20, 20],
      [216, 216, 216],
      [132, 132, 132],
      [124, 124, 124],
    ],
  },
  {
    id: "ZG02apY_QpYi",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/05",
    dominantColor: [115, 115, 115],
    palette: [
      [117, 117, 117],
      [20, 20, 20],
      [52, 52, 52],
      [60, 60, 60],
    ],
  },
  {
    id: "F4mkvFRH4Mdh",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/06",
    dominantColor: [162, 162, 162],
    palette: [
      [16, 16, 16],
      [162, 162, 162],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "iiJTWOunv",
    artistId: "UGPIDSAEtkOI",
    src: "marcus-schaefer/07",
    dominantColor: [109, 109, 109],
    palette: [
      [110, 110, 110],
      [22, 22, 22],
      [60, 60, 60],
      [52, 52, 52],
    ],
  },
  {
    id: "J-BQHuRLSE9W",
    artistId: "dsH2SrUaeIjf",
    src: "matt-des-lauriers/01",
    theme: "gray",
    position: "end",
    dominantColor: [11, 11, 11],
    palette: [
      [177, 177, 177],
      [11, 11, 11],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "sN7LXQ8bHqJ0",
    artistId: "dsH2SrUaeIjf",
    src: "matt-des-lauriers/02",
    dominantColor: [40, 40, 40],
    palette: [
      [45, 45, 45],
      [197, 197, 197],
      [156, 156, 156],
      [148, 148, 148],
    ],
  },
  {
    id: "uQnXPxlzlZKG",
    artistId: "dsH2SrUaeIjf",
    src: "matt-des-lauriers/03",
    dominantColor: [62, 62, 62],
    palette: [
      [78, 78, 78],
      [222, 222, 222],
      [184, 184, 184],
      [172, 172, 172],
    ],
  },
  {
    id: "o78yzkXpF0FI",
    artistId: "dsH2SrUaeIjf",
    src: "matt-des-lauriers/04",
    dominantColor: [201, 201, 201],
    palette: [
      [10, 10, 10],
      [201, 201, 201],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "IgqvNKzitlSq",
    artistId: "dsH2SrUaeIjf",
    src: "matt-des-lauriers/05",
    dominantColor: [219, 219, 219],
    palette: [
      [216, 216, 216],
      [172, 172, 172],
      [154, 154, 154],
      [180, 180, 180],
    ],
  },
  {
    id: "JPfQNdDcB-cN",
    artistId: "dsH2SrUaeIjf",
    src: "matt-des-lauriers/06",
    dominantColor: [213, 213, 213],
    palette: [
      [38, 38, 38],
      [213, 213, 213],
      [152, 152, 152],
      [140, 140, 140],
    ],
  },
  {
    id: "dljJJGK7fRhL",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/01",
    dominantColor: [24, 24, 24],
    palette: [
      [237, 237, 237],
      [24, 24, 24],
      [116, 116, 116],
      [124, 124, 124],
    ],
  },
  {
    id: "WObvzcWXw170",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/02",
    dominantColor: [216, 216, 216],
    palette: [
      [62, 62, 62],
      [216, 216, 216],
      [159, 159, 159],
      [148, 148, 148],
    ],
  },
  {
    id: "QDZMUzz9aV9D",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/03",
    dominantColor: [249, 249, 249],
    palette: [
      [45, 45, 45],
      [249, 249, 249],
      [124, 124, 124],
      [132, 132, 132],
    ],
  },
  {
    id: "Zevq8F3EwdmI",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/04",
    theme: "gray",
    dominantColor: [194, 194, 194],
    palette: [
      [9, 9, 9],
      [194, 194, 194],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "W-RNYft1e2TI",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/05",
    dominantColor: [226, 226, 226],
    palette: [
      [223, 223, 223],
      [28, 28, 28],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "AQ1pyZKMovzw",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/06",
    dominantColor: [36, 36, 36],
    palette: [
      [211, 211, 211],
      [36, 36, 36],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "4dZAlS8GDuuu",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/07",
    dominantColor: [18, 18, 18],
    palette: [
      [247, 247, 247],
      [18, 18, 18],
      [155, 155, 155],
      [132, 132, 132],
    ],
  },
  {
    id: "mu1GtWiIlRed",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/08",
    dominantColor: [182, 182, 182],
    palette: [
      [171, 171, 171],
      [29, 29, 29],
      [84, 84, 84],
      [76, 76, 76],
    ],
  },
  {
    id: "5TCtdXTaVfu_",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/09",
    theme: "gray",
    position: "start",
    dominantColor: [32, 32, 32],
    palette: [
      [226, 226, 226],
      [32, 32, 32],
      [146, 146, 146],
      [132, 132, 132],
    ],
  },
  {
    id: "BoKVXjhjtXq5",
    artistId: "iYFyN_5yuwE_",
    src: "ryan-carl/10",
    dominantColor: [201, 201, 201],
    palette: [
      [188, 188, 188],
      [53, 53, 53],
      [80, 80, 80],
      [92, 92, 92],
    ],
  },
  {
    id: "us4PuysQUjeZ",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/01",
    dominantColor: [214, 214, 214],
    palette: [
      [180, 180, 180],
      [59, 59, 59],
      [76, 76, 76],
      [84, 84, 84],
    ],
  },
  {
    id: "XhXxbwq4TZvZ",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/02",
    dominantColor: [206, 206, 206],
    palette: [
      [175, 175, 175],
      [51, 51, 51],
      [68, 68, 68],
      [76, 76, 76],
    ],
  },
  {
    id: "qh0i6srt4wgZ",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/03",
    dominantColor: [205, 205, 205],
    palette: [
      [188, 188, 188],
      [56, 56, 56],
      [80, 80, 80],
      [92, 92, 92],
    ],
  },
  {
    id: "ZazJlBlsFYgG",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/04",
    dominantColor: [204, 204, 204],
    palette: [
      [189, 189, 189],
      [56, 56, 56],
      [79, 79, 79],
      [92, 92, 92],
    ],
  },
  {
    id: "jYweAnsKsJu_",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/05",
    dominantColor: [203, 203, 203],
    palette: [
      [187, 187, 187],
      [57, 57, 57],
      [79, 79, 79],
      [92, 92, 92],
    ],
  },
  {
    id: "sOFBGOo_ZLB6",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/06",
    dominantColor: [205, 205, 205],
    palette: [
      [183, 183, 183],
      [51, 51, 51],
      [71, 71, 71],
      [84, 84, 84],
    ],
  },
  {
    id: "CyrPEMO5oz0D",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/07",
    dominantColor: [200, 200, 200],
    palette: [
      [182, 182, 182],
      [49, 49, 49],
      [71, 71, 71],
      [84, 84, 84],
    ],
  },
  {
    id: "rqL7pUoSzKDI",
    seriesId: "dnopf1UeKaJL",
    src: "ryan-carl/blueprint/08",
    dominantColor: [59, 59, 59],
    palette: [
      [66, 66, 66],
      [188, 188, 188],
      [156, 156, 156],
      [148, 148, 148],
    ],
  },
  {
    id: "1I8T6UK7341x",
    seriesId: "VJccCBIFvNsP",
    src: "ryan-carl/feelings/01",
    dominantColor: [220, 220, 220],
    palette: [
      [41, 41, 41],
      [220, 220, 220],
      [160, 160, 160],
      [140, 140, 140],
    ],
  },
  {
    id: "Rz6WmWLFl1gp",
    seriesId: "VJccCBIFvNsP",
    src: "ryan-carl/feelings/02",
    dominantColor: [223, 223, 223],
    palette: [
      [47, 47, 47],
      [223, 223, 223],
      [167, 167, 167],
      [148, 148, 148],
    ],
  },
  {
    id: "d_O1WoFko48f",
    seriesId: "VJccCBIFvNsP",
    src: "ryan-carl/feelings/03",
    dominantColor: [221, 221, 221],
    palette: [
      [40, 40, 40],
      [221, 221, 221],
      [160, 160, 160],
      [140, 140, 140],
    ],
  },
  {
    id: "RVjNxtth9Ejk",
    seriesId: "tir_Z2gpCD-l",
    src: "ryan-carl/modular/01",
    dominantColor: [214, 214, 214],
    palette: [
      [42, 42, 42],
      [214, 214, 214],
      [155, 155, 155],
      [140, 140, 140],
    ],
  },
  {
    id: "FiN5c9w-6EMI",
    seriesId: "tir_Z2gpCD-l",
    src: "ryan-carl/modular/02",
    dominantColor: [30, 30, 30],
    palette: [
      [217, 217, 217],
      [30, 30, 30],
      [148, 148, 148],
      [132, 132, 132],
    ],
  },
  {
    id: "0j3At-bJp38m",
    seriesId: "tir_Z2gpCD-l",
    src: "ryan-carl/modular/03",
    dominantColor: [33, 33, 33],
    palette: [
      [212, 212, 212],
      [33, 33, 33],
      [148, 148, 148],
      [132, 132, 132],
    ],
  },
  {
    id: "btMhNR5LwTKs",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/01",
    theme: "black",
    dominantColor: [13, 13, 13],
    palette: [
      [216, 216, 216],
      [13, 13, 13],
      [124, 124, 124],
      [116, 116, 124],
    ],
  },
  {
    id: "uIx60IyYUBFT",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/02",
    dominantColor: [213, 213, 213],
    palette: [
      [43, 43, 43],
      [213, 213, 213],
      [156, 156, 156],
      [140, 140, 140],
    ],
  },
  {
    id: "FC8hvxg25NSN",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/03",
    dominantColor: [196, 196, 196],
    palette: [
      [37, 37, 37],
      [196, 196, 196],
      [143, 143, 143],
      [132, 132, 132],
    ],
  },
  {
    id: "4xdlqvr8t7Hs",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/04",
    dominantColor: [195, 195, 195],
    palette: [
      [48, 48, 48],
      [195, 195, 195],
      [148, 148, 148],
      [140, 140, 140],
    ],
  },
  {
    id: "FJfaRNZ0o6fp",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/05",
    theme: "black",
    dominantColor: [26, 26, 26],
    palette: [
      [29, 29, 29],
      [197, 197, 197],
      [140, 140, 140],
      [132, 132, 132],
    ],
  },
  {
    id: "-xFku2K6fndN",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/06",
    dominantColor: [57, 57, 57],
    palette: [
      [74, 74, 74],
      [207, 207, 207],
      [172, 172, 172],
      [164, 164, 164],
    ],
  },
  {
    id: "DevxczkOQ25z",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/07",
    dominantColor: [13, 13, 13],
    palette: [
      [187, 187, 187],
      [13, 13, 13],
      [124, 124, 124],
      [116, 116, 116],
    ],
  },
  {
    id: "KdEth5aFbE9i",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/08",
    dominantColor: [182, 182, 182],
    palette: [
      [39, 39, 39],
      [182, 182, 182],
      [148, 148, 148],
      [140, 140, 140],
    ],
  },
  {
    id: "LVv2gs-KOFk0",
    artistId: "CAYD-CV3nUCm",
    src: "tatsuo-suzuki/09",
    dominantColor: [143, 143, 143],
    palette: [
      [155, 155, 155],
      [30, 30, 30],
      [68, 68, 68],
      [76, 76, 76],
    ],
  },
  {
    id: "HMeMEZ_a8bFJ",
    artistId: "GvMHxhiJ_skj",
    src: "werner-bischof/01",
    dominantColor: [177, 177, 177],
    palette: [
      [170, 170, 170],
      [22, 22, 22],
      [71, 71, 71],
      [84, 84, 84],
    ],
  },
  {
    id: "_JlSeLhOQCbi",
    artistId: "GvMHxhiJ_skj",
    src: "werner-bischof/02",
    dominantColor: [190, 190, 190],
    palette: [
      [180, 180, 180],
      [43, 43, 43],
      [84, 84, 84],
      [92, 92, 92],
    ],
  },
  {
    id: "i7aUsLsEVbV6",
    artistId: "GvMHxhiJ_skj",
    src: "werner-bischof/03",
    dominantColor: [192, 192, 192],
    palette: [
      [21, 21, 21],
      [192, 192, 192],
      [132, 132, 132],
      [140, 140, 140],
    ],
  },
];
