<template>
  <div class="slider-color">
    <div class="colors">
      <div
        class="color"
        v-for="c in colors"
        :class="{
          active: c.value === modelValue,
          isolation: inIsolation,
          transparent: c.label === 'Transparent',
        }"
        :key="c.value"
        :style="{ background: c.label !== 'Transparent' ? c.value : null }"
      ></div>
    </div>
    <div class="thumb" :style="style" />
  </div>
</template>

<script>
import * as d3 from "d3";
import hammer from "hammerjs";
import clamp from "../utils/clamp";
import map from "../utils/map";
import roundValueToStep from "../utils/roundValueToStep";

import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    modelValue: String,
    scheme: {
      type: String,
      default: "Greys",
    },
    disabled: Boolean,
  },
  data() {
    return {
      width: null,
      savedX: null,
    };
  },
  computed: {
    ...mapGetters({
      inIsolation: "knobs/inIsolation",
      dragThreshold: "knobs/dragThreshold",
    }),
    computedValue() {
      return this.formatValue(this.modelValue);
    },
    scale() {
      return 16;
    },
    colorRangeExists() {
      return false;
      // return (
      //   d3[`scheme${this.scheme}`] && d3[`scheme${this.scheme}`][this.scale]
      // );
    },
    colors() {
      if (this.colorRangeExists) {
        return d3[`scheme${this.scheme}`][this.scale];
      } else {
        const interpolate = d3[`interpolate${this.scheme}`];
        const colors = [];

        for (let i = 0; i < this.scale; ++i) {
          let label = `G${i}`;

          if (i === 0) label = "Transparent";
          if (i === this.scale - 1) label = "Black";
          colors.push({
            value: d3.rgb(interpolate(i / (this.scale - 1))).hex(),
            label,
          });
        }
        return colors;
      }
    },
    style() {
      const x = this.getX(this.computedValue);
      return {
        transform: `translateX(${x}px)`,
      };
    },
    color() {
      //const n = roundValueToStep(this.computedValue * this.scale, 1, 0);
      //return this.colors[n];
      return this.modelValue;
    },
  },
  methods: {
    formatValue(value) {
      //value = clamp(value, 0, 1);
      return value;
    },
    updateValue(value, x) {
      //value = this.formatValue(value);
      const t = map(x, 0, this.width, 0, 1);
      this.$emit("update:modelValue", value);
      this.$emit("t", t);

      return value;
    },
    getValue(x) {
      const range = map(x, 0, this.width, 0, this.colors.length - 1);
      const n = roundValueToStep(range, 1, 0);
      return this.colors[n].value;
    },
    getX(value) {
      const n = this.colors.findIndex((color) => color.value === value);
      return map(n, 0, this.colors.length - 1, 0, this.width);
    },
    /* Component logic
     */
    init() {
      this.width = this.$el.getBoundingClientRect().width;

      this.updateValue(this.computedValue);

      this.manager = new hammer.Manager(this.$el);

      this.pan = new hammer.Pan({
        threshold: this.dragThreshold,
        touchAction: "compute",
        direction: hammer.DIRECTION_HORIZONTAL,
      });

      this.manager.add([this.pan]);

      this.manager.on("panstart", this.onPanStart);
      this.manager.on("panend", this.onPanEnd);
      this.manager.on("pancancel", this.onPanEnd);
      this.manager.on("panleft panright", this.onPanMove);
      this.$emit("colors", this.colors);
    },
    onPanStart(e) {
      if (this.disabled) return false;
      this.savedX = this.getX(this.computedValue);
    },
    onPanMove(e) {
      if (this.disabled) return false;
      const x = this.savedX + e.deltaX;
      const value = this.getValue(x);

      this.updateValue(value, x);
    },
    onPanEnd(e) {
      if (this.disabled) return false;
    },
  },
  mounted() {
    setTimeout(this.init, 1);
  },
};
</script>

<style lang="scss" scoped>
@import "../style";
.slider-color {
  @extend .slider;
  overflow: visible;
  touch-action: pan-y !important;

  canvas {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  .colors {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    .color {
      flex: 1;
      height: 100%;
      opacity: 0;

      &.transparent {
        $size: 4px;
        background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
          linear-gradient(-45deg, #808080 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, #808080 75%),
          linear-gradient(-45deg, transparent 75%, #808080 75%);
        background-size: $size $size;
        background-position: 0 0, 0 ($size/2), ($size/2) (-$size/2), (-$size/2) 0px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        outline: 2rem solid currentcolor;
        transform: scale(0.5);
        transform-origin: 0% 0%;
      }

      &:not(.active)::after {
        display: none;
      }

      &.active {
        outline: 2rem solid;
        position: relative;
        opacity: 1;
      }

      &.isolation {
        opacity: 1;

        &.active {
          //outline: 2px solid;
        }
      }
    }
  }
  .thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--theme-accent);
    width: 4px;
    border-radius: 8px;
    @extend .thumb;
    display: none;
  }
}
</style>
