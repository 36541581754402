/**
 * Generated output from: /Users/jakobhenner/Prototypes/_samples/dithermark/js_generated/app/constants.js.php
 */

export default {
  appName: "Dithermark",
  colorDitherMaxColors: 16,
  histogramHeight: 44,
  histogramBwWidth: 256,
  histogramColorWidth: 360,
  unsplashReferralAppName: "dithermark",
  unsplashApiUrl: "/api/unsplash.json",
  unsplashApiPhotoIdQueryKey: "photo_id",
  unsplashDownloadUrl: "/api/unsplash-download.php",
};
